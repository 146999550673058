import React, { ReactElement, ChangeEvent, useEffect } from 'react';

import {
  TextField,
  FormControl,
  FormControlProps as MuiFormControlProps,
  Tooltip,
} from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

import { useField, useFormikContext } from 'formik';

import { baseProps, BaseFormProps } from 'Components/Shared/Forms/BaseForm/BaseForm';
import classes from 'Components/Shared/Forms/Styles/ValidatedStyles.module.scss';
import { validate } from 'Components/Shared/Forms/utils/FormUtils';



interface ValidatedTextfieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  FormControlProps?: MuiFormControlProps;
  tooltip?: string;
}

type componentProps = ValidatedTextfieldProps & TextFieldProps & BaseFormProps;

const ValidatedTextfield = (
  props: componentProps & baseProps & { variant?: 'standard' | 'outlined' | 'filled' }
): ReactElement => {
  const { validators, FormControlProps, ...rest } = props;
  const [field, meta, helpers] = useField({
    name: props.name,
    validate: (val: React.ReactText): string | undefined => {
      const result = validate(val, validators, props.required);
      return result && result[0];
    },
  });

  const formContext = useFormikContext();

  useEffect((): void => {
    if (field.value !== props.value && props.value) {
      helpers.setValue(props.value);
    }
  }, [field.value, helpers, props.value]);

  return (
    <FormControl
      fullWidth={props.fullWidth}
      error={!!meta.error}
      {...FormControlProps}
      variant={props.variant}
    >
      <Tooltip title={props.tooltip ?? ''}>
        <TextField
          {...field}
          {...rest}
          value={props.value || ''}
          error={
            props.error ||
            ((meta.error?.length ?? -1) > 0 && (meta.touched || formContext.submitCount > 0))
          }
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            const val = e.target.value;
            helpers.setValue(val);
            props.onChange(e);
          }}
          inputProps={{
            ...props.inputProps,
            classes: { root: classes.whiteBg, error: classes.error },
          }}
          FormHelperTextProps={{
            classes: { root: classes.errorText },
          }}
          helperText={
            props.helperText ||
            (meta.touched || formContext.submitCount > 0 ? meta.error || ' ' : ' ')
          }
          InputLabelProps={{ variant: props.variant }}
          margin={props.margin ?? 'dense'}
        />
      </Tooltip>
    </FormControl>
  );
};

export default ValidatedTextfield;
