import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { CREDIT_NOTE_TO } from 'Constants/shared.constants';

interface Props {
  creditNoteNumber: string | undefined;
}

const ViewCreditNoteButton = ({ creditNoteNumber }: Props): ReactElement => {
  if (!creditNoteNumber) {
    return <></>;
  }

  return (
    <LoadingButton
      component={Link}
      sx={{ textTransform: 'none', borderRadius: 0 }}
      to={`${CREDIT_NOTE_TO}?creditNoteNumber=${creditNoteNumber}`}
      variant="contained"
      startIcon={getIconByIconName('creditNote')}
    >
      VIEW CREDIT NOTE
    </LoadingButton>
  );
};

export default ViewCreditNoteButton;
