import { useState } from 'react';

import { LoadingButton } from '@mui/lab';

import CreditNoteCreationModal from 'Claim/CreditNote/creditNoteCreation';

interface Props {
  claimId: number;
}

const CreateCreditNoteFromClaim = ({ claimId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <LoadingButton
        color="primary"
        variant="contained"
        sx={{ textTransform: 'none', borderRadius: 0 }}
        onClick={handleOpenModal}
      >
        Create Credit Note
      </LoadingButton>

      <CreditNoteCreationModal open={isModalOpen} onClose={handleCloseModal} claimId={claimId} />
    </>
  );
};

export default CreateCreditNoteFromClaim;
