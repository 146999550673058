import { ReactElement } from 'react';

import { BestoneErrorDetail } from 'Services/ext.service/interfaces/BestoneErrorDetail';

import classes from './PrintError.module.scss';
interface Props {
  errors: BestoneErrorDetail[];
}

const InlinePrintError = (props: Props): ReactElement => {
  if (props.errors.length < 1) {
    return <></>;
  }
  return (
    <div className={classes.container}>
      {props.errors.map(
        (x, i): ReactElement => (
          <div key={`sticker-print-error-${i}`} className={classes.errorLine}>
            <div className={classes.message}>{`${x.errorMessage}${
              x.errorIds.length > 0 ? ':' : ''
            }`}</div>
            <div className={classes.errorDetails}>{x.errorIds.join(', ')}</div>
          </div>
        )
      )}
    </div>
  );
};

export default InlinePrintError;
