import { OrderAttachmentType } from 'Models/Documents/_types_';
import { ShippingInfoGroup } from 'Models/Documents/_types_/ShippingInfoGroup';
import { SelectionInterface } from 'Models/Documents/OrderAttachments/SelectPackingNotes/_interfaces_/SelectionInterface';
import { BaseAction } from 'Store/actions/_types_/BaseAction';

export const SET_SELECTED_ORDERS = 'SET_SELECTED_ORDERS_FOR_DOCUMENTS';
export const SET_SELECTED_DOCUMENT_TYPE = 'SET_SELECTED_DOCUMENT_TYPE';
export const CLEAR_DOCUMENTS_STATE = 'CLEAR_DOCUMENTS_STATE';
export const INIT_UPLOAD_STATE = 'INIT_UPLOAD_STATE';
export const TOGGLE_UPLOAD_PN_STATE = 'TOGGLE_UPLOAD_PN_STATE';
export const TOGGLE_ALL_PN = 'CHECK_ALL_PN';
export const SET_SAVING = 'SET_SAVING';

export interface InitUploadStateAction extends BaseAction {
  elements: SelectionInterface[];
}

export interface ToggleUploadPnAction extends BaseAction {
  orderNumber: string;
  packingNoteId: number;
  checked: boolean;
}

export interface ToggleAllPnAction extends BaseAction {
  orderNumber: string;
  packingNotesIds: number[];
  checked: boolean;
}

export interface SetSaving extends BaseAction {
  saving: boolean;
}

export const initUploadState = (elements: SelectionInterface[]): InitUploadStateAction => ({
  type: INIT_UPLOAD_STATE,
  elements,
});

export const toggleUploadPn = (
  orderNumber: string,
  packingNoteId: number,
  checked: boolean
): ToggleUploadPnAction => ({
  type: TOGGLE_UPLOAD_PN_STATE,
  orderNumber,
  packingNoteId,
  checked,
});

export const toggleAllPn = (
  orderNumber: string,
  packingNotesIds: number[],
  checked: boolean
): ToggleAllPnAction => ({
  type: TOGGLE_ALL_PN,
  orderNumber,
  packingNotesIds,
  checked,
});

export const doSetSaving = (saving: boolean): SetSaving => ({
  type: SET_SAVING,
  saving,
});

export interface SetSelectedOrdersAction extends BaseAction {
  selectedOrders: string[];
  shippingDocGroup: ShippingInfoGroup | undefined;
}

export interface SetSelectedDocumentTypeAction extends BaseAction {
  selectedDocumentType: OrderAttachmentType;
}

export type ClearDocumentsStateAction = BaseAction;

export const doSetSelectedOrders = (
  selectedOrders: string[],
  shippingDocGroup: ShippingInfoGroup | undefined
): SetSelectedOrdersAction => ({
  type: SET_SELECTED_ORDERS,
  shippingDocGroup,
  selectedOrders,
});

export const doSetSelectedDocumentType = (
  selectedDocumentType: OrderAttachmentType
): SetSelectedDocumentTypeAction => ({
  type: SET_SELECTED_DOCUMENT_TYPE,
  selectedDocumentType,
});

export const doClearDocumentsState = (): ClearDocumentsStateAction => ({
  type: CLEAR_DOCUMENTS_STATE,
});

export type DocumentsActions =
  | SetSelectedOrdersAction
  | SetSelectedDocumentTypeAction
  | ClearDocumentsStateAction
  | InitUploadStateAction
  | ToggleUploadPnAction
  | ToggleAllPnAction
  | SetSaving;
