import { useReducer } from 'react';


import _ from 'lodash';

import { calculateGroupId } from 'Invoice/SampleProforma/_helpers/calculateGroupId';
import { uniqueLineId } from 'Invoice/SampleProforma/_helpers/mapLineInfoToLineDataArray';
import HeaderEntity from 'Invoice/SampleProforma/_models/Entities/HeaderEntity';
import { SELECT_TYPE } from 'Invoice/SampleProforma/CreateSampleProforma/BasedOnModal/_types';
import {
  addNewLines,
  fixProformaLineNumber,
} from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/addNewLines';
import { fixupLineNumber } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/fixupLineNumbers';
import { removeSampleProformaLine } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/removeSampleProformaLine';
import { updateBrand } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/updateBrand';
import { updateDetailsCollection } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/updateDetailsCollection';
import { updateDetailsColor } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/updateDetailsColor';
import { updateDetailsComment } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/updateDetailsComment';
import { updateDetailsQuantity } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/updateDetailsQuantity';
import { updateDetailsQuantityPricePrPcs } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/updateDetailsQuantityPricePrPcs';
import { updateDetailsSize } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/updateDetailsSize';
import { updateDetailsVariant } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/updateDetailsVariant';
import {
  ADD_SAMPLE_PROFORMA_HEADER_LINE_INFO,
  RESET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE,
  SampleProformaHeaderActions,
  SET_SAMPLE_PROFORMA_HEADER_BRAND,
  SET_SAMPLE_PROFORMA_HEADER_BUYER,
  SET_SAMPLE_PROFORMA_HEADER_CURRENCY,
  SET_SAMPLE_PROFORMA_HEADER_FACTORY,
  SET_SAMPLE_PROFORMA_HEADER_INCOTERMS,
  SET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE,
  SET_SAMPLE_PROFORMA_HEADER_INVOICE_NUMBER,
  SET_SAMPLE_PROFORMA_HEADER_LINE_INFO,
  SET_SAMPLE_PROFORMA_HEADER_CARRIER,
  SET_SAMPLE_PROFORMA_HEADER_ORIGIN_CRITERION,
  SET_SAMPLE_PROFORMA_HEADER_PLACE_OF_DELIVERY,
  SET_SAMPLE_PROFORMA_HEADER_SUPPLIER,
  UpdateSampleProformaHeaderLineInfo,
  UPDATE_SAMPLE_PROFORMA_HEADER_LINE_INFO,
  REMOVE_SAMPLE_PROFORMA_LINE,
  SET_SAMPLE_PROFORMA_FOOTER_TOTAL_QUANTITY,
  SET_SAMPLE_PROFORMA_FOOTER_NET_WEIGHT,
  SET_SAMPLE_PROFORMA_FOOTER_GROSS_WEIGHT,
  SET_SAMPLE_PROFORMA_HEADER_ALL_FIELDS,
  ADD_SAMPLE_PROFORMA_HEADER_LINE_DETAIL_INFO,
  UPDATE_DETAILS_PRICE_PR_PCS,
  UPDATE_DETAILS_QUANTITY,
  UPDATE_DETAILS_SIZE,
  UPDATE_DETAILS_COLOR,
  UPDATE_DETAILS_COLLECTION,
  UPDATE_DETAILS_VARIANT,
  SET_DETAILS_COMMENT,
  SET_SAMPLE_PROFORMA_HEADER_META_REBALANCE,
} from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderActions';
import { SampleProformaHeaderDispatch } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderDispatch';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';
import { LineInfoGroup } from 'Invoice/SampleProforma/CreateSampleProforma/SampleProformaCreationLine/_types/LineInfo';

const initialState: SampleProformaHeaderState = {
  groupName: undefined,
  dataType: undefined,
  headerInfo: new HeaderEntity(),
  lineInfoGroup: [],
  footer: {
    grossWeight: undefined,
    netWeight: undefined,
    totalQuantity: undefined,
  },
  meta: {
    rebalanced: false,
  },
};

const resetStyleType = (line: LineInfoGroup): LineInfoGroup => {
  return {
    ...line,
    styleType: undefined,
  };
};

const resetBrand = (state: SampleProformaHeaderState): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }
  state.headerInfo.setBrand(undefined);
  return state;
};

const resetStyleTypeIfNoMasterCategory = (line: LineInfoGroup): LineInfoGroup => {
  return {
    ...line,
    styleType: line.masterCategory?.id === undefined ? undefined : line.styleType,
  };
};

const updateLinePrices = (
  updatedLineInfo: LineInfoGroup,
  action: UpdateSampleProformaHeaderLineInfo<'pricePrPcs'>
): LineInfoGroup => {
  return {
    ...updatedLineInfo,
    pricePrPcs: {
      editable: updatedLineInfo.pricePrPcs?.editable ?? true,
      value: action.value?.value,
      mixed: false,
      hasError: action.value?.hasError ?? false,
    },
    lines: updatedLineInfo.lines.map((line) => {
      return {
        ...line,
        pricePrPcs: {
          editable: line.pricePrPcs?.editable ?? true,
          value: action.value?.value,
          mixed: false,
          hasError: action.value?.hasError ?? false,
        },
      };
    }),
  };
};

const updateQuantity = (
  updatedLineInfo: LineInfoGroup,
  action: UpdateSampleProformaHeaderLineInfo<'quantity'>
): LineInfoGroup => {
  return {
    ...updatedLineInfo,
    quantity: {
      value: action.value?.value,
      mixed: updatedLineInfo.quantity?.mixed ?? false,
      hasError: action.value?.hasError ?? false,
      maxValue: updatedLineInfo.quantity?.maxValue,
    },
    lines: action.value?.hasError
      ? updatedLineInfo.lines
      : updatedLineInfo.lines.map((line) => {
          return {
            ...line,
            quantity: {
              value: action.value?.value,
              mixed: line.quantity?.mixed ?? false,
              hasError: action.value?.hasError ?? false,
              maxValue: line.quantity?.maxValue,
            },
          };
        }),
  };
};

function handleLineInfoRules<K extends keyof LineInfoGroup>(
  lineInfo: LineInfoGroup,
  action: UpdateSampleProformaHeaderLineInfo<K>
): { line: LineInfoGroup; rebalanced: boolean } {
  if (lineInfo === undefined) {
    return lineInfo;
  }

  const { groupId: updatedGroupId, rebalanced } = calculateGroupId(lineInfo);

  const handledLineInfo: LineInfoGroup = {
    ...lineInfo,
    groupId: !lineInfo.hasBeenRebalanced && rebalanced ? updatedGroupId : lineInfo.groupId,
    hasBeenRebalanced: rebalanced,
  };
  switch (action.key) {
    case 'masterCategory':
      return { line: resetStyleType(handledLineInfo), rebalanced };
    case 'styleType':
      return { line: resetStyleTypeIfNoMasterCategory(handledLineInfo), rebalanced };
    case 'pricePrPcs':
      return {
        line: updateLinePrices(
          handledLineInfo,
          action as UpdateSampleProformaHeaderLineInfo<'pricePrPcs'>
        ),
        rebalanced: false,
      };
    case 'quantity':
      return {
        line: updateQuantity(
          handledLineInfo,
          action as UpdateSampleProformaHeaderLineInfo<'quantity'>
        ),
        rebalanced: false,
      };
    case 'composition': {
      return { line: handledLineInfo, rebalanced };
    }
    case 'gender':
    case 'manufacturer':
    default:
      return { line: handledLineInfo, rebalanced };
  }
}

function rebalanceGroups(groups: LineInfoGroup[]): { lines: LineInfoGroup[]; rebalanced: boolean } {
  const manualLines = groups.filter((x) => x.detailTypeId === SELECT_TYPE.MANUAL);
  if (manualLines.length < 2) {
    return { lines: groups, rebalanced: false };
  }
  let hasBeenRebalanced = false;
  const lineInfoMap: Map<string, LineInfoGroup> = groups.reduce((map, line) => {
    const updatedLine = line;

    const oldLine = map.get(updatedLine.groupId);

    if (oldLine === undefined) {
      map.set(updatedLine.groupId, updatedLine);
    } else {
      hasBeenRebalanced = true;
      oldLine.lines.push(...updatedLine.lines);
    }

    return map;
  }, new Map<string, LineInfoGroup>());

  const newLines: LineInfoGroup[] = Array.from(lineInfoMap.values());

  return { lines: newLines, rebalanced: hasBeenRebalanced };
}

function updateValue<K extends keyof LineInfoGroup>(
  updatedState: SampleProformaHeaderState,
  action: UpdateSampleProformaHeaderLineInfo<K>
): { lines: LineInfoGroup[]; rebalanced: boolean } {
  if (updatedState.headerInfo === undefined && updatedState.lineInfoGroup === undefined) {
    return { lines: [], rebalanced: false };
  }
  let doRebalance = false;

  const lineInfo = updatedState.lineInfoGroup.map((line, _i) => {
    if (line.lineId === action.lineId) {
      const result: LineInfoGroup = {
        ...line,
        [action.key]: action.value,
      };

      const updated = handleLineInfoRules(result, action);
      doRebalance = updated.rebalanced;
      return { ...updated.line };
    }

    return line;
  });
  if (doRebalance) {
    return rebalanceGroups(lineInfo);
  }
  return { lines: lineInfo, rebalanced: false };
}

const resetFactory = (state: SampleProformaHeaderState): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }
  state.headerInfo.setFactory(undefined);
  return state;
};

const resetBuyer = (state: SampleProformaHeaderState): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }
  state.headerInfo.setBuyerContact(undefined, undefined, undefined);
  return state;
};

const resetPlaceOfDelivery = (state: SampleProformaHeaderState): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }
  state.headerInfo.setPlaceOfDelivery(undefined);
  return state;
};

const resetCarrier = (state: SampleProformaHeaderState): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }
  state.headerInfo.setCarrier(undefined);
  return state;
};
const resetOriginCriterion = (state: SampleProformaHeaderState): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }
  state.headerInfo.setOriginCriterion(undefined);
  return state;
};

const switchToManual = (state: SampleProformaHeaderState): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }
  return {
    ...state,
    dataType: SELECT_TYPE.MANUAL,
  };
};

const removeOrderLinesFromHeader = (
  state: SampleProformaHeaderState
): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }

  return {
    ...state,
    lineInfoGroup: state.lineInfoGroup.filter((x) => !x.fromHeader),
  };
};

const resetFooter = (state: SampleProformaHeaderState): SampleProformaHeaderState => {
  if (state.headerInfo === undefined) {
    return state;
  }

  return {
    ...state,
    footer: {
      grossWeight: undefined,
      netWeight: undefined,
      totalQuantity: undefined,
    },
  };
};

const handleRules = <K extends keyof LineInfoGroup>(
  state: SampleProformaHeaderState,
  action: SampleProformaHeaderActions<K>
): SampleProformaHeaderState => {
  let updatedState = { ...state };

  switch (action.type) {
    case SET_SAMPLE_PROFORMA_HEADER_SUPPLIER:
    case SET_SAMPLE_PROFORMA_HEADER_BRAND: {
      updatedState = removeOrderLinesFromHeader(switchToManual(updatedState));
      if (action.type === SET_SAMPLE_PROFORMA_HEADER_BRAND) {
        updatedState = resetCarrier(resetBuyer(resetPlaceOfDelivery(updatedState)));
      }
      if (action.type === SET_SAMPLE_PROFORMA_HEADER_SUPPLIER) {
        updatedState = resetOriginCriterion(resetBrand(updatedState));
      }
      updatedState = resetFactory(updatedState);
      break;
    }
    case SET_SAMPLE_PROFORMA_HEADER_FACTORY:
      if (updatedState.dataType === SELECT_TYPE.ORDER) {
        updatedState = switchToManual(removeOrderLinesFromHeader(updatedState));
      }
      updatedState = resetOriginCriterion(resetOriginCriterion(updatedState));

      break;
    case SET_SAMPLE_PROFORMA_HEADER_PLACE_OF_DELIVERY:
      if (updatedState.dataType === SELECT_TYPE.ORDER) {
        updatedState = removeOrderLinesFromHeader(updatedState);
      }
      break;
    case REMOVE_SAMPLE_PROFORMA_LINE:
    case ADD_SAMPLE_PROFORMA_HEADER_LINE_INFO:
      return resetFooter(updatedState);

    default:
      break;
  }
  return updatedState;
};

const reducer = <K extends keyof LineInfoGroup>(
  state: SampleProformaHeaderState,
  action: SampleProformaHeaderActions<K>
): SampleProformaHeaderState => {
  if (state.headerInfo === undefined && action.type !== SET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE) {
    return state;
  }

  if (state.headerInfo === undefined && action.type === SET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE) {
    return {
      groupName: undefined,
      dataType: action.dataType,
      headerInfo: action.initialState,
      lineInfoGroup: [],
      footer: {
        grossWeight: undefined,
        netWeight: undefined,
        totalQuantity: undefined,
      },
      meta: {
        rebalanced: false,
      },
    };
  }

  const updatedState = handleRules(state, action);
  if (updatedState.headerInfo === undefined) {
    return updatedState;
  }

  switch (action.type) {
    case RESET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE: {
      return initialState;
    }
    case SET_SAMPLE_PROFORMA_HEADER_SUPPLIER: {
      const updatedHeader = updatedState.headerInfo?.setSupplier(action.supplier);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }
    case SET_SAMPLE_PROFORMA_HEADER_INVOICE_NUMBER: {
      const updatedHeader = updatedState.headerInfo?.setInvoiceNumber(action.value);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }
    case SET_SAMPLE_PROFORMA_HEADER_FACTORY: {
      const updatedHeader = updatedState.headerInfo?.setFactory(action.value);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }
    case SET_SAMPLE_PROFORMA_HEADER_BRAND: {
      return updateBrand(updatedState, action);
    }
    case SET_SAMPLE_PROFORMA_HEADER_BUYER: {
      const updatedHeader = updatedState.headerInfo?.updateBuyerContact(action.value);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }
    case SET_SAMPLE_PROFORMA_HEADER_PLACE_OF_DELIVERY: {
      const updatedHeader = updatedState.headerInfo?.setPlaceOfDelivery(action.value);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }
    case SET_SAMPLE_PROFORMA_HEADER_CURRENCY: {
      const updatedHeader = updatedState.headerInfo?.setCurrency(action.value);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }
    case SET_SAMPLE_PROFORMA_HEADER_INCOTERMS: {
      const updatedHeader = updatedState.headerInfo?.setIncoterms(action.value);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }
    case SET_SAMPLE_PROFORMA_HEADER_ORIGIN_CRITERION: {
      const updatedHeader = updatedState.headerInfo?.setOriginCriterion(action.value);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }
    case SET_SAMPLE_PROFORMA_HEADER_LINE_INFO: {
      const fixedGroups = fixupLineNumber(
        addNewLines(updatedState.lineInfoGroup, action.lineInfoGroup)
      );

      const updatedHeader = _.cloneDeep(updatedState.headerInfo).update(action.headerInfo);

      return {
        ...updatedState,
        dataType: action.dataType,
        groupName: action.groupName,
        lineInfoGroup: fixedGroups,
        headerInfo: updatedHeader,
      };
    }
    case ADD_SAMPLE_PROFORMA_HEADER_LINE_INFO: {
      return {
        ...updatedState,
        lineInfoGroup: fixupLineNumber(
          addNewLines(updatedState.lineInfoGroup, action.lineInfoGroup)
        ),
        footer: resetFooter(updatedState).footer,
      };
    }
    case ADD_SAMPLE_PROFORMA_HEADER_LINE_DETAIL_INFO: {
      const updatedLines = [...updatedState.lineInfoGroup].map((x) => {
        if (x.groupId === action.groupId) {
          x.lines = fixProformaLineNumber([
            ...x.lines,
            {
              from: action.from,
              proformaLineNumber: '' + (x.lines.length + 1),
              collection: undefined,
              color: undefined,
              comment: undefined,
              lineId: uniqueLineId(undefined, undefined),
              lineNumber: undefined,
              pricePrPcs: {
                editable: true,
                value: x.pricePrPcs?.value,
                mixed: false,
                hasError: false,
              },
              quantity: x.quantity ?? undefined,
              size: undefined,
              totalAmount: undefined,
              variant: undefined,
              orderDetailId: undefined,
            },
          ]);
        }
        return x;
      });

      return {
        ...updatedState,
        lineInfoGroup: fixupLineNumber(updatedLines),
        footer: resetFooter(updatedState).footer,
      };
    }
    case UPDATE_SAMPLE_PROFORMA_HEADER_LINE_INFO: {
      const updatedLineInfo = updateValue<K>(updatedState, action);
      const returns: SampleProformaHeaderState = {
        ...updatedState,
        lineInfoGroup: updatedLineInfo.lines,
        meta: { ...updatedState.meta, rebalanced: updatedLineInfo.rebalanced },
      };
      return returns;
    }
    case SET_SAMPLE_PROFORMA_HEADER_CARRIER: {
      const updatedHeader = updatedState.headerInfo?.setCarrier(action.value);
      return { ...updatedState, headerInfo: _.cloneDeep(updatedHeader) };
    }

    case REMOVE_SAMPLE_PROFORMA_LINE: {
      return removeSampleProformaLine(updatedState, action);
    }

    case SET_SAMPLE_PROFORMA_FOOTER_TOTAL_QUANTITY: {
      return {
        ...updatedState,
        footer: {
          ...updatedState.footer,
          totalQuantity: action.value,
        },
      };
    }

    case SET_SAMPLE_PROFORMA_FOOTER_NET_WEIGHT: {
      return {
        ...updatedState,
        footer: {
          ...updatedState.footer,
          netWeight: action.value,
        },
      };
    }

    case SET_SAMPLE_PROFORMA_FOOTER_GROSS_WEIGHT: {
      return {
        ...updatedState,
        footer: {
          ...updatedState.footer,
          grossWeight: action.value,
        },
      };
    }

    case SET_SAMPLE_PROFORMA_HEADER_ALL_FIELDS: {
      const stateHeaderIsUndefined =
        !updatedState.headerInfo ||
        !Object.values(updatedState.headerInfo).some((value) => value !== undefined);

      if (!stateHeaderIsUndefined) {
        return updatedState;
      }

      return {
        ...updatedState,
        headerInfo: action.headerInfo,
      };
    }

    case UPDATE_DETAILS_COLLECTION: {
      return updateDetailsCollection(updatedState, action);
    }
    case UPDATE_DETAILS_COLOR: {
      return updateDetailsColor(updatedState, action);
    }

    case UPDATE_DETAILS_SIZE: {
      return updateDetailsSize(updatedState, action);
    }

    case UPDATE_DETAILS_VARIANT: {
      return updateDetailsVariant(updatedState, action);
    }

    case UPDATE_DETAILS_PRICE_PR_PCS: {
      return updateDetailsQuantityPricePrPcs(updatedState, action);
    }

    case UPDATE_DETAILS_QUANTITY: {
      return updateDetailsQuantity(updatedState, action);
    }
    case SET_DETAILS_COMMENT: {
      return updateDetailsComment(updatedState, action);
    }
    case SET_SAMPLE_PROFORMA_HEADER_META_REBALANCE: {
      return { ...updatedState, meta: { ...updatedState.meta, rebalanced: action.value } };
    }

    default:
      return updatedState;
  }
};

export const useSampleProformaHeaderReducer = (): [
  SampleProformaHeaderState,
  SampleProformaHeaderDispatch
] => {
  return useReducer(reducer, initialState);
};
