import { ReactElement, PropsWithChildren, useState, Ref, forwardRef } from 'react';
import { useHistory } from 'react-router';
import { Link as NavLink } from 'react-router-dom';

import { ChevronRight } from '@mui/icons-material';
import { IconButton, ListItemText, MenuItem, ListItemSecondaryAction } from '@mui/material';

import navClasses from 'Components/UI/Navigation/Navigation.module.scss';
import { NavigationMenu } from 'Components/UI/Navigation/NavigationMenu';
import { NestedRouteConfig } from 'Views/routes';


interface NavigationMenuItemProps {
  childrenRoutes: { [ChildRouteKey: string]: NestedRouteConfig };
  routeId: string;
  index: number;
  handleClose: () => void;
}

export const NavigationItemNoRef = (
  { childrenRoutes, routeId, index, handleClose }: NavigationMenuItemProps,
  _ref: Ref<HTMLAnchorElement>
): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const route = childrenRoutes[routeId];
  if (
    route.children &&
    Object.keys(route.children ?? {}).some((r) => route.children?.[r].display !== false)
  ) {
    const handleCloseChild = (): void => setAnchorEl(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
      setAnchorEl(event.currentTarget);

    return [
      <MenuItem
        key={`navigation-menu-item-with-menu-${index}-${routeId}`}
        onClick={handleClose}
        {...(route.to && {
          component: NavLink,
          to: route.to,
        })}
        className={navClasses.listItem}
      >
        <ListItemText primary={route.label} />
        <ListItemSecondaryAction className={navClasses.listItemSecondaryAction}>
          <IconButton
            edge="end"
            onMouseOver={handleClick}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size="large"
          >
            <ChevronRight fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </MenuItem>,
      <NavigationMenu
        key={`navigation-menu-${index}-${routeId}`}
        anchorEl={anchorEl}
        handleClose={handleCloseChild}
        open={Boolean(anchorEl)}
        childrenRoutes={route.children}
      />,
    ] as unknown as ReactElement;
  }
  return (
    <MenuItem
      key={`navigation-menu-item-${index}-${routeId}`}
      onClick={handleClose}
      {...(!!route.to && {
        button: true,
        onClick: () => history.push(route.to as string),
      })}
      className={navClasses.listItem}
    >
      {route.label}
    </MenuItem>
  );
};

export const NavigationItem =
  forwardRef<HTMLAnchorElement, Omit<PropsWithChildren<NavigationMenuItemProps>, 'innerRef'>>(
    NavigationItemNoRef
  );
