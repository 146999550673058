import React, {
  ReactElement,
  useState,
  useMemo,
  useRef,
  useCallback,
  SyntheticEvent,
  useEffect,
} from 'react';

import { Search } from '@mui/icons-material';
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
  Autocomplete,
  InputProps,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';

import { startCase } from 'lodash';

import { SearchValues, defaultSearchValue } from 'Components/Shared/SearchBar/_types';
import {
  tagMatch,
  getSearchValue,
  addChipElement,
  getMatch,
  generateLabel,
  getLabel,
} from 'Components/Shared/SearchBar/SearchBarHelpers';
import { convertArrayIntoStringWithComasAndSpaces } from 'Helpers/convertStringWithComasIntoArray';

import { ClearButton } from './ClearButton';
import classes from './SearchBar.module.scss';
import { SearchChip, SearchChipProps } from './SearchChip';

interface Props<T extends string> {
  searchFields: T[];
  searchValues: SearchValues<T>;
  defaultSearchField: defaultSearchValue<T>;
  defaultSearch?: string;
  icon?: boolean;
  onSearch?: () => void;
}

export const SearchBar = <S extends string>({
  searchFields,
  searchValues,
  defaultSearchField,
  defaultSearch,
  icon = true,
  onSearch,
}: Props<S>): ReactElement => {
  const keys = useMemo(() => searchFields, [searchFields]);
  const didHandleSearch = useRef<boolean>(false);
  const didMount = useRef<boolean>(false);

  const [previousCursorPosition, setPreviousCursorPosition] = useState<number | undefined>();
  const [searchValue, setSearchValue] = useState<string>(
    Array.isArray(defaultSearchField.value)
      ? convertArrayIntoStringWithComasAndSpaces(defaultSearchField.value)
      : defaultSearchField.value ?? ''
  );

  const [chipArray, setChipArray] = useState<Array<Partial<SearchChipProps<S>>>>([]);

  const optionsArray = useMemo(() => {
    return (searchFields ?? []).filter((option) => {
      const key = Object.keys(searchValues).find((r) => r === option) as S;
      if ((key && searchValues[key].value) || tagMatch(searchValue)) {
        return false;
      }
      return true;
    });
  }, [searchFields, searchValue, searchValues]);

  const handleSearch = useCallback(
    (search: string = searchValue) => {
      if (search.length === 0) {
        defaultSearchField?.handler(null);
      } else {
        defaultSearchField?.handler(getSearchValue(search).trim());
      }
    },
    [defaultSearchField, searchValue]
  );

  const callHandlerForChipType = useCallback(
    (chipType: string | undefined, value: string | undefined) => {
      const key = keys.find((k) => k === chipType);
      if (key && searchValues[key]) {
        searchValues[key].handler(value ? value.trim() : null);
        return;
      }
      const defaultKey = keys.find((k) => k === defaultSearch);
      if (defaultKey && searchValues[defaultKey]) {
        searchValues[defaultKey].handler(value ? value.trim() : null);
      }
    },
    [keys, searchValues, defaultSearch]
  );

  const addChip = useCallback(
    (label: string | undefined, newValue: string, chipType?: S) => {
      setChipArray((prevVal) => {
        return addChipElement(
          prevVal,
          chipType,
          getLabel(label, chipType, defaultSearchField),
          newValue
        );
      });
      setSearchValue('');
      callHandlerForChipType(chipType, newValue);
      onSearch?.();
    },
    [callHandlerForChipType, onSearch, defaultSearchField]
  );

  const removeChip = useCallback(
    (chipIndex: number, chipType?: S) => {
      setChipArray((prevVal) => {
        return prevVal.filter((_, i) => i !== chipIndex);
      });
      callHandlerForChipType(chipType, undefined);
    },
    [callHandlerForChipType]
  );

  const handleChange = useCallback(
    (
      _event: SyntheticEvent<Element, Event> | undefined,
      value: string | null,
      _reason?: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string>
    ) => {
      if (searchValue) {
        const cleanValue = tagMatch(searchValue) ? getSearchValue(searchValue) : searchValue;

        const key = keys.find((k) => k === value);
        if (key && searchValues[key] && value) {
          addChip(searchValues[key].label, cleanValue, value as S);
          return;
        }

        return;
      }

      if ((value ?? '').trim().length > 0) {
        setSearchValue(`${value}:`);
      }
    },
    [addChip, keys, searchValue, searchValues]
  );

  const handleInputChange = (
    _event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input' && typeof value === 'string' && value !== searchValue) {
      setSearchValue(value);
    }
  };

  const handleFocus = (ref: React.RefObject<HTMLInputElement>) => {
    const cursorPosition = ref.current?.selectionStart;
    if (typeof cursorPosition === 'number') {
      setPreviousCursorPosition(cursorPosition);
    }
  };

  const handleMatch = useCallback(
    (ref: React.RefObject<HTMLInputElement>): boolean => {
      const defaultSearchForMatch = searchFields.find((x) => x === (defaultSearch as S));
      const match =
        getMatch(searchValue) ?? getMatch(generateLabel(defaultSearchForMatch, searchValue));
      if (match) {
        handleChange(undefined, match);
        onSearch?.();
        ref.current?.blur();
        setSearchValue('');
        return true;
      }
      ref.current?.blur();

      if (searchValue !== (defaultSearchField.value ?? '')) {
        addChip(defaultSearchField.label, searchValue, defaultSearchForMatch);

        onSearch?.();
      }

      return false;
    },
    [
      addChip,
      defaultSearch,
      defaultSearchField.label,
      defaultSearchField.value,
      handleChange,
      onSearch,
      searchFields,
      searchValue,
    ]
  );

  const handleKeyPress = useCallback(
    (key: string, ref: React.RefObject<HTMLInputElement>) => {
      if (key === 'Enter') {
        handleMatch(ref);
      } else if (
        key === 'Backspace' &&
        previousCursorPosition === 0 &&
        ref.current?.selectionStart === 0
      ) {
        const index = chipArray.length - 1;
        const { type } = { ...chipArray[index] };
        const searchTerm = keys.find((k) => k === type);
        if (searchTerm && searchValues[searchTerm]) {
          setSearchValue(generateLabel(type, `${searchValues[searchTerm].value}${searchValue}`));
        }
        removeChip(index, type);
        onSearch?.();
      }

      const cursorPosition = ref.current?.selectionStart;
      if (typeof cursorPosition === 'number') {
        setPreviousCursorPosition(cursorPosition);
      }
    },
    [
      chipArray,
      handleMatch,
      keys,
      onSearch,
      previousCursorPosition,
      removeChip,
      searchValue,
      searchValues,
    ]
  );

  const handleClear = useCallback(() => {
    setSearchValue('');
    keys.forEach((key) => callHandlerForChipType(key, undefined));
    //Also clear the default search field
    defaultSearchField.handler(null);
    setChipArray([]);
    onSearch?.();
  }, [callHandlerForChipType, defaultSearchField, keys, onSearch]);

  useEffect(() => {
    if (!didHandleSearch.current) {
      handleSearch(searchValue);
      didHandleSearch.current = true;
    }
  }, [handleSearch, searchValue]);

  useEffect(() => {
    if (!didMount.current) {
      // NOTE: MOUNT ONLY
      keys.forEach((key) => {
        const value = searchValues[key].value;
        if (value && !chipArray.find((chip) => chip.type === 'key')) {
          const valueToUse = Array.isArray(value)
            ? convertArrayIntoStringWithComasAndSpaces(value)
            : value;
          addChip(searchValues[key].label, valueToUse, key);
        }
      });

      didMount.current = true;
    }
  }, [addChip, chipArray, keys, searchValue, searchValues]);

  const handleBlur = useCallback(
    (ref: React.RefObject<HTMLInputElement>) => {
      if (searchValue.trim().length === 0) {
        return;
      }

      if (handleMatch(ref)) {
        return;
      }
      handleSearch(searchValue);
    },
    [handleMatch, handleSearch, searchValue]
  );

  return (
    <>
      <Autocomplete
        id="search-bar"
        className={classes.autocomplete}
        fullWidth={false}
        freeSolo
        value={searchValue}
        inputValue={searchValue}
        options={optionsArray}
        groupBy={(_option) => 'Specify your search:'}
        filterOptions={() => optionsArray}
        renderOption={(renderProps, option) => (
          <Box component="li" {...renderProps}>
            {searchValues[option as S].label ?? startCase(option)}
            <span>:&nbsp;</span>
            <em>{searchValue}</em>
          </Box>
        )}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={({ InputProps: renderInputProps, ...otherParams }) => {
          const { ref } = otherParams.inputProps as InputProps;
          return (
            <TextField
              variant="standard"
              placeholder="Search..."
              {...otherParams}
              value={searchValue}
              margin="normal"
              size="medium"
              InputProps={{
                ...renderInputProps,
                className: classes.input,
                disableUnderline: true,
                margin: undefined,
                size: 'medium',
                startAdornment: (
                  <>
                    {icon && (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )}
                    {chipArray.map(({ label, type }: Partial<SearchChipProps<S>>, i: number) => {
                      if (!label) {
                        return <></>;
                      }
                      const [property, value] = label.split(':');
                      return (
                        <SearchChip
                          key={`searchChip-${i}`}
                          label={`${startCase(property)}: ${value}` || ''}
                          isLast={i === chipArray.length - 1}
                          onDelete={() => removeChip(i, type)}
                        />
                      );
                    })}
                  </>
                ),
                endAdornment: (
                  <ClearButton show={!!searchValue || !!chipArray.length} onClick={handleClear} />
                ),
              }}
              onFocus={() => handleFocus(ref as React.RefObject<HTMLInputElement>)}
              onBlur={() => handleBlur(ref as React.RefObject<HTMLInputElement>)}
              onKeyUp={({ key }) => handleKeyPress(key, ref as React.RefObject<HTMLInputElement>)}
            />
          );
        }}
      />
    </>
  );
};
