import { claimBaseUrl, GetClaimConfig } from 'Claim/_services/claimService';
import { ClaimStatusResponse } from 'Claim/_types/claims';
import { useBestApi, UseBestApiTuple } from 'Components/Hooks/useBestApi';

export const useGetClaimStatus = (): UseBestApiTuple<ClaimStatusResponse[], GetClaimConfig> => {
  return useBestApi<ClaimStatusResponse[], GetClaimConfig>(claimBaseUrl('claims/statuses'), {
    headers: {
      'CLAIMS-API-VERSION': '1.0',
    },
    method: 'GET',
  });
};
