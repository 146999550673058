import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

export const useQuery = (): URLSearchParams => {
  const searchString = useLocation().search;

  const [query, setQuery] = useState<URLSearchParams>(new URLSearchParams(searchString));

  useEffect(() => {
    setQuery(new URLSearchParams(searchString));
  }, [searchString]);

  return query;
};
