import {
  deleteResourceRequestConfig,
  getResourceRequestConfig,
  modifyResourceRequestConfig,
} from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { properties } from 'Helpers/properties';

export const claimBaseUrl = (url: string): string => {
  const branchNumber = sessionStorage.getItem('claimsBranch')
    ? sessionStorage.getItem('claimsBranch') + '/claims/'
    : 'claims/';

  return properties.serviceApiURL + branchNumber + url;
};

export type GetClaimConfig = getResourceRequestConfig & {
  method: 'GET';
};

type PutReasonClaimConfig = {
  value: string | undefined;
};

export type PutStateClaimConfig = modifyResourceRequestConfig<PutReasonClaimConfig> & {
  method: 'PUT';
};

export type PostClaimConfig = modifyResourceRequestConfig & {
  method: 'POST';
};

export type DeleteClaimConfig = deleteResourceRequestConfig & {
  method: 'DELETE';
};
