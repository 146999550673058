import { ReactElement, ElementType, useCallback } from 'react';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { SOURCING_SITE_URL, CLOSET_URL } from 'Constants/shared.constants';
import { featureFlags, useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import lazyImport from 'Helpers/lazyImport';
import { useCLAIM_ROUTES } from 'Views/routes/claim';
import { useINVOICE_ROUTES } from 'Views/routes/invoice';

import { useDASHBOARD_ALTERNATE_ROUTES, useDASHBOARD_ROUTES } from './dashBoard';
import { FAST_CONFIRM_ROUTES } from './fastConfirm';
import { NEWS_ROUTES } from './news';
import { ORDERS_ROUTES } from './orders';
import { usePACKING_NOTE_ROUTES } from './packingNotes';
import { useSTYLES_ROUTES } from './styles';

const SetVendorMdm = lazyImport(() => import('Views/Login/SetVendorMdm/SetVendorMdm'));
const ManageAccounts = lazyImport(() => import('Views/Login/ManageAccounts'));

export interface NestedRouteConfig {
  /** Label of the menu item */
  label: string;
  /** Optional keywords to enable searching */
  keywords?: string[];
  /** Path of the route. This will be forwarded into `react-router`'s `<Route />` component*/
  to?: string;
  /** If the `to` should be exact. This will be forwarded into `react-router`'s `<Route />` component*/
  exact?: boolean;
  /** If the option should be displayed in the navigation drawer */
  display?: boolean;
  /** Optional permission. If the user does not have this permission, the `fallbackComponent` component will be shown */
  permission?: number | number[];
  /** Optional featureflag. If the given featureFlag is not enabled, the route will exist for the user */
  featureFlag?: featureFlags;
  /** Component for the route. This will be forwarded into `react-router`'s `<Route />` component*/
  component?: ElementType;
  /** Optional fallback component if the permission required is not given to the user */
  fallbackComponent?: ElementType;
  /** An object containing more configs for nested components. */
  children?: {
    [ChildRouteKey: string]: NestedRouteConfig;
  };
  /** If the option is enabled, opens the link in `to` in a new tab */
  openInNewTab?: boolean;
}

export interface RouteConfig extends NestedRouteConfig {
  /** Icon for the <NavigationDrawer /> component */
  icon: ReactElement;
  /** Optional colour for the icon */
  color?: string;
  to: string;
  component: ElementType;
}
export interface Routes {
  [RouteKey: string]: RouteConfig;
}

export const useRoutes = (): Routes => {
  const getFeatureFlag = useGetFeatureFlag();
  const PACKING_NOTE_ROUTES = usePACKING_NOTE_ROUTES();
  const INVOICE_ROUTES = useINVOICE_ROUTES();
  const DASHBOARD_ROUTES = useDASHBOARD_ROUTES();
  const DASHBOARD_ALTERNATE_ROUTES = useDASHBOARD_ALTERNATE_ROUTES();
  const STYLE_ROUTES = useSTYLES_ROUTES();
  const CLAIMS_ROUTES = useCLAIM_ROUTES();

  return {
    Dashboard: DASHBOARD_ROUTES,
    DashboardAlternate: DASHBOARD_ALTERNATE_ROUTES,
    Styles: STYLE_ROUTES,
    Orders: ORDERS_ROUTES,
    FastConfirm: FAST_CONFIRM_ROUTES,
    PackingNotes: PACKING_NOTE_ROUTES,
    News: NEWS_ROUTES,
    Invoice: INVOICE_ROUTES,
    Claims: CLAIMS_ROUTES,
    SetVendor: {
      label: 'Set Vendor',
      to: '/setvendor',
      display: false,
      exact: true,
      icon: getIconByIconName('dashboard'),
      color: '#009459',
      component: SetVendorMdm,
    },
    ManageAccounts: {
      label: 'Manage Accounts',
      to: '/manageaccounts',
      display: false,
      exact: true,
      icon: getIconByIconName('dashboard'),
      color: '#009459',
      component: ManageAccounts,
    },

    Sourcing: {
      label: 'Sourcing Site',
      to: SOURCING_SITE_URL,
      icon: getIconByIconName('sourcing'),
      component: SetVendorMdm,
      featureFlag: getFeatureFlag('vp-show-sourcing-site-link'),
    },

    Closet: {
      label: '3D Design',
      to: CLOSET_URL,
      icon: getIconByIconName('3d'),
      openInNewTab: true,
      component: SetVendorMdm,
      featureFlag: getFeatureFlag('vp-closet-link'),
    },
    GlobalSourcingSite: {
      label: 'Supplier Site',
      to: 'https://mybestseller.sharepoint.com/teams/GlobalSourcing-Suppliers',
      icon: getIconByIconName('GlobalSourcingSite'),
      openInNewTab: true,
      component: () => null,
      featureFlag: getFeatureFlag('link-to-sharepoint'),
    },
  };
};

/**
 * Gets an array of paths defined in the Routes' `to` fields.
 *
 * @example
 * ```
 *  [
 *    "/dashboard",
 *    "/",
 *    "/fastconfirm",
 *    "/packingnote",
 *    "/packingnote/detail/:ids",
 *    "/packingnote/create/:orderNumber",
 *    "/packingnote/:orderNumber",
 *    ...
 *  ]
 * ```
 * */

/**
 * Gets an array of paths defined in the Routes' `to` fields.
 *
 * @example
 * ```
 *  [
 *    "/dashboard",
 *    "/",
 *    "/fastconfirm",
 *    "/packingnote",
 *    "/packingnote/detail/:ids",
 *    "/packingnote/create/:orderNumber",
 *    "/packingnote/:orderNumber",
 *    ...
 *  ]
 * ```
 * */
export const useGetRoutePaths = (): (() => string[]) => {
  const routes = useRoutes();
  return useCallback(() => {
    const routePaths: string[] = [];

    /** Gets an array of paths used by UI/Navigation/NavigationBreadCrumbs, and then pushes them to the `routePaths` array */
    const mapRoutePaths = (_routes: { [RouteKey: string]: NestedRouteConfig }) => {
      Object.keys(_routes).forEach((r) => {
        const route = _routes[r];
        if (route.to) {
          routePaths.push(route.to);
        }

        if (route.children) {
          mapRoutePaths(route.children);
        }
      });
    };

    mapRoutePaths(routes);

    return routePaths;
  }, [routes]);
};
