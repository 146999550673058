import { ReactElement } from 'react';

const NotAllowedActionReason = (props: { action: string; reasons?: string[] }): ReactElement => {
  const { action, reasons = [] } = props;
  const localReasons = reasons.length > 0 ? reasons : ['Unable to get reason'];

  return (
    <div>
      <span>{`Unable to ${action} due to the following `}</span>
      <span>{`reason${localReasons.length > 1 ? 's' : ''}`}</span>
      <span>:</span>
      <ul style={{ paddingRight: 12 }}>
        {localReasons.map((x, i) => (
          <li key={`${action}-reason-${i}`}>{x}</li>
        ))}
      </ul>
    </div>
  );
};

export default NotAllowedActionReason;
