import { ReactElement, useEffect, useState } from 'react';

import { Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useGetPapskiltQuery, usePapskiltChangedSubscription } from 'generated/papskilt_graphql';

const PAPSKILT_PROJECT_ID = '80e9e02e-a42d-4a88-b300-0e71fd14b650';

const useStyles = makeStyles({
  container: {
    margin: '8px 0',
  },
});
const Papskilt = (): ReactElement | null => {
  const [message, setMessage] = useState<string | undefined>();
  const classes = useStyles();

  const { data } = useGetPapskiltQuery({
    fetchPolicy: 'no-cache',
    variables: {
      projectId: PAPSKILT_PROJECT_ID,
    },
  });

  const { data: subData } = usePapskiltChangedSubscription({
    variables: {
      projectId: PAPSKILT_PROJECT_ID,
    },
  });

  useEffect((): void => {
    setMessage(subData?.papskiltChanged.payload.message ?? undefined);
  }, [subData]);

  useEffect((): void => {
    setMessage(data?.papskilt.message ?? undefined);
  }, [data?.papskilt.message]);

  if (!message) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Alert severity="warning">{message}</Alert>
    </div>
  );
};

export default Papskilt;
