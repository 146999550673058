import { ReactElement } from 'react';
import { useLocation } from 'react-router';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { MenuElement } from 'Components/UI/HeaderPage/_types_/MenuElement';
import { useMenus } from 'Constants/shared.constants';


import classes from './NoAccess.module.scss';

const NoAccess = (): ReactElement => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const path = params.get('path');
  const flags = useFlags();
  const menus = useMenus();
  const link = menus(flags).find((me: MenuElement): boolean => me.link === path);

  const message =
    path !== '/'
      ? `You do not have permission to view this page ${
          link
            ? ` (${link.name.toLocaleLowerCase().replace(/^.{1}/g, link.name[0].toUpperCase())}).`
            : '.'
        }`
      : `You don't have any permissions in the vendor portal`;

  return (
    <div className={classes.container}>
      <div>
        <h1>Insufficient Rights</h1>
      </div>
      <div>{message}</div>
      <div>Please contact your manager if you think this is a mistake.</div>
    </div>
  );
};

export default NoAccess;
