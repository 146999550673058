import { ReactElement } from 'react';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import { INFORMATION_TO } from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import NewsList from 'Views/News/NewsList';

import { RouteConfig } from '.';

const NewsEditor = lazyImport(() => import('Views/News/NewsEditor/NewsEditor'));

export const NEWS_ROUTES: RouteConfig = {
  label: 'News',
  to: INFORMATION_TO,
  icon: getIconByIconName('news'),
  permission: PERMISSION.VP_VIEW_NEWS,
  color: '#D1A667',
  component: NewsList as (props?: unknown) => ReactElement,
  children: {
    NewsEditor: {
      display: false,
      label: 'Edit',
      to: '/newseditor/:id?',
      component: NewsEditor,
      permission: PERMISSION.VP_CREATE_NEWS,
    },
  },
};
