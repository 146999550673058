import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

enum VpFeatureFlags {
  'vp-closet-link',
  'vp-show-sourcing-site-link',
  'invoice-inbound',
  'invoice-outbound',
  'draggable-columns',
  'invoice-outbound-search',
  'disable-invoice-generate',
  'carton-delete-v2',
  'packing-note-multi-split',
  'show-campaign',
  'carbone-order-print',
  'bom-print-order',
  'excel-overview-sup-52',
  'sample-proforma-invoice',
  'sample-commercial-invoice',
  'multiple-ad-users',
  'link-to-sharepoint',
  'claims',
  'pn-new-details-endpoint',
  'pn-handover-type',
  'credit-note',
  'commercial-SAP',
}

const getAllFeatureFlags = (): (keyof typeof VpFeatureFlags)[] => {
  const data = Object.keys(VpFeatureFlags)
    .filter((x): boolean => {
      const isValueProperty = parseInt(x, 10) >= 0;
      return isValueProperty;
    })
    .map((x) => VpFeatureFlags[parseInt(x, 10)] as keyof typeof VpFeatureFlags);
  return data;
};

export const useGetFeatureFlag = (): ((key: featureFlags) => featureFlags) => {
  const devSettings = useSelector((root: RootReducerState) => root.devSettingsState);
  return useCallback(
    (key: featureFlags) => {
      if (devSettings.disableAllFF.value) {
        return 'NONE';
      }
      return key;
    },
    [devSettings.disableAllFF.value]
  );
};

export type featureFlags = keyof typeof VpFeatureFlags | 'NONE';
export const vpFeatureFlags = {
  getAllFeatureFlags,
};
