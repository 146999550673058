import { ReactElement, useState, ReactNode, useCallback, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { LinearProgress, Box } from '@mui/material';

import { DialogButtonProps, Dialog } from '@bestseller-bit/frontend-community.components.dialog';
import { useFetchClaimPrint } from 'Claim/_services/hooks/claims/useFetchClaimPrint';
import InlinePrintError from 'Components/Shared/Print/PrintError/InlinePrintError';
import WithPermission from 'Components/Shared/Security/WithPermission/WithPermission';
import { PERMISSION } from 'Constants/permissions.constants';
import { openBlobInNewWindow } from 'Helpers/util';
import fileResMapper from 'Services/ext.service/helpers/fileResMapper';
import { BestoneErrorDetail } from 'Services/ext.service/interfaces/BestoneErrorDetail';
import { LocalBestoneFileResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';

interface Props {
  claimId: number;
}

const PrintClaim = ({ claimId }: Props): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [printableData, setPrintableData] = useState<LocalBestoneFileResponse>({
    data: undefined,
    contentType: undefined,
    error: [],
  });
  const [fetchPrint, { data, loading: fetchLoading, error: fetchError }] =
    useFetchClaimPrint(claimId);

  useEffect(() => {
    if (data) {
      setPrintableData({ ...fileResMapper.mapResToBlob(['' + claimId], 'PDF', data) });
    }
  }, [data, claimId]);

  const handleClick = useCallback(() => {
    setShowDialog(true);
    setError(null);
    setLoading(true);
    fetchPrint();
  }, [fetchPrint]);

  useEffect(() => {
    if (fetchError) {
      const bestoneErrors: BestoneErrorDetail[] = [
        {
          errorMessage: fetchError.message,
          errorIds: [],
        },
      ];
      setError(<InlinePrintError errors={bestoneErrors} />);
      setLoading(false);
    }
  }, [fetchError, printableData]);

  useEffect(() => {
    if (!printableData.data) {
      setLoading(false);
    }
    if ((printableData.error ?? []).length > 0) {
      setError(<InlinePrintError errors={printableData.error} />);
    } else {
      setShowDialog(false);
      openBlobInNewWindow(printableData.data);
      setLoading(false);
    }
  }, [printableData]);

  const buttonsForDialog: DialogButtonProps[] = [
    {
      label: 'Close',

      onClick: () => setShowDialog(false),
      disabled: error || fetchError ? true : false,
    },
  ];

  const handleCloseDialog = () => {
    (!loading || !fetchLoading) && setShowDialog(false);
  };

  return (
    <>
      <Dialog
        title={`${claimId}: Generating claim...`}
        open={showDialog}
        onClose={handleCloseDialog}
        buttons={buttonsForDialog}
        width="sm"
        dividers
        sx={{
          '& .MuiDialogTitle-root': {
            fontSize: '16px',
            fontWeight: 'bold',
          },
        }}
      >
        <Box>
          {!error && <LinearProgress />}
          {error}
        </Box>
      </Dialog>
      <WithPermission permission={PERMISSION.VP_CLAIM_PRINT}>
        <LoadingButton
          loading={loading || fetchLoading}
          sx={{ textTransform: 'none', paddingX: 4, borderRadius: 0 }}
          color="primary"
          variant="contained"
          onClick={handleClick}
          disabled={loading || fetchLoading}
        >
          Print
        </LoadingButton>
      </WithPermission>
    </>
  );
};

export default PrintClaim;
