import { ReactElement } from 'react';

import { MenuOpen } from '@mui/icons-material';
import { AppBar, Toolbar, Hidden, IconButton } from '@mui/material';

import clsx from 'clsx';

import Logo from 'Components/UI/Navigation/Logo';
import { BreadCrumbs } from 'Components/UI/Navigation/NavigationBreadCrumbs';

import { useStyles } from './styles';

type NavigationBarProps = {
  Actions: React.ComponentType;
} & (
  | {
      noSidebar: true;
      open?: never;
      handleDrawerOpen?: never;
    }
  | {
      noSidebar?: false;
      open: boolean;
      handleDrawerOpen: () => void;
    }
);

const NavigationBar = ({
  Actions,
  open,
  handleDrawerOpen,
  noSidebar,
}: NavigationBarProps): ReactElement => {
  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      elevation={0}
    >
      <Toolbar>
        {!noSidebar && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            size="large"
          >
            <MenuOpen className={classes.menuIcon} />
          </IconButton>
        )}
        <div className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <Logo />
            <Hidden mdDown>
              <BreadCrumbs className={classes.breadCrumbs} />
            </Hidden>
          </div>
          <Actions />
        </div>
      </Toolbar>
      {/* SET ERROR MESSAGE HERE */}
      {/* <div
        style={{
          fontSize: 18,
          justifyContent: 'center',
          display: 'flex',
          zIndex: 99999,
          color: 'darkred',
          border: '1px solid darkred',
          margin: 8,
        }}
      >
        We are currently experiencing issues. We are working to reslove it
      </div> */}
    </AppBar>
  );
};

export default NavigationBar;
