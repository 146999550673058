import { useCallback, useMemo } from 'react';

import { ClaimDetailLine, UsedOnClaim } from 'Claim/_types/claims';
import { formatAmountToUS } from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/formatAmountToUS';
import { TableColumn } from 'Components/Table/state/_types_/TableColumn';
import { formatDateStringToDDMMYYYY } from 'Helpers/formatters/dateFormatter';

const ClaimDetailLinesTableHelper = () => {
  const renderUsedOnClaim = useCallback((usedOnClaim: UsedOnClaim[]) => {
    return usedOnClaim.map((claim) => (
      <div key={`used-on-claims-${claim.id}-${claim.number}`}>{claim.number}</div>
    ));
  }, []);

  const renderOrderTotal = useCallback((orderTotal: { amount: number; currency: string }) => {
    const formattedOrderTotal = formatAmountToUS(orderTotal.amount);

    return `${formattedOrderTotal} ${orderTotal.currency}`;
  }, []);

  const cols = useMemo(
    (): TableColumn<ClaimDetailLine>[] => [
      {
        property: 'orderNumber',
        label: 'Order Number',
        resolver: (item) => item.orderNumber,
      },
      {
        property: 'style',
        label: 'Style Name',
        resolver: (item) => item.style.name,
      },
      {
        property: 'deliveryDate',
        label: 'Delivery Date',
        resolver: (item) => formatDateStringToDDMMYYYY(item.deliveryDate),
      },
      {
        property: 'usedOnClaim',
        label: 'Used On Claim',
        resolver: (item) => renderUsedOnClaim(item.usedOnClaim),
      },
      {
        property: 'orderTotal',
        label: 'Order Total',
        resolver: (item) => renderOrderTotal(item.orderTotal),
      },
    ],
    [renderOrderTotal, renderUsedOnClaim]
  );

  return cols;
};

export default ClaimDetailLinesTableHelper;
