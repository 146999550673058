import { PropsWithChildren, ReactElement } from 'react';

import { CssBaseline, Theme } from '@mui/material';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';

declare module '@mui/styles/defaultTheme' {
   
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  palette: {
    background: {
      default: `rgb(242, 242, 242)`,
    },
    primary: {
      main: `rgb(63, 62, 62)`,
    },
    secondary: {
      main: `rgb(183, 176, 161)`,
    },
    success: {
      main: `rgb(0, 190, 7)`,
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
    },
  },
  typography: {
    fontSize: 16,
    h6: { fontFamily: `"adelle", "Arial"` },
    body1: {
      fontFamily: `"adelle", "Arial"`,
      fontSize: 16,
      lineHeight: '1.43',
    },
    button: { fontFamily: `"adelle", "Arial"` },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: `"adelle", "Arial"`,
        },
        contained: {
          color: 'rgba(0, 0, 0, 0.87);',
          fontFamily: `"adelle", "Arial"`,
        },
        containedPrimary: {
          color: '#fff',
        },
      },
    },

    MuiInput: {
      defaultProps: {
        size: 'small',
        margin: 'dense',
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
        margin: 'dense',
      },
      styleOverrides: {
        root: { borderRadius: 0, backgroundColor: '#fff' },
      },
    },

    MuiFilledInput: {
      defaultProps: {
        size: 'small',
        margin: 'dense',
      },
      styleOverrides: {
        root: { backgroundColor: 'transparent' },
      },
    },

    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        outlined: {
          backgroundColor: '#fff',
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        clickable: {
          backgroundColor: '#e0e0e0',
          '&:hover': {
            backgroundColor: '#e0e0e0',

            filter: 'brightness(85%)',
          },
        },
        deleteIcon: {
          color: '#000',
          '&:hover': {
            color: '#333',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          root: { display: 'span' },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
      },
      styleOverrides: {},
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          color: undefined,
        },
        body: {
          color: undefined,
        },
      },
    },

    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        endAdornment: {
          bottom: 'auto',
          top: 'auto',
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        colorDisabled: '#9e9e9e',
      },
    },
  },
});

export const ThemeWrapper = ({
  children,
}: PropsWithChildren<{
  children: ReactElement | ReactElement[];
}>): ReactElement => {
  return (
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};
