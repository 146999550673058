import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import {
  PACKING_NOTE_TO,
  PACKING_NOTE_DETAIL_TO,
  CREATE_PACKING_NOTE_TO,
} from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import NewsList from 'Views/News/NewsList';

import { RouteConfig } from '.';

const PackingNotesDetails = lazyImport(() => import('PackingNotes/Areas/PackingNotesDetails'));

const CreatePackingNote = lazyImport(
  () => import('PackingNotes/Actions/CreatePackingNote/CreatePackingNotes/index')
);

const PackingNotesOverview = lazyImport(() => import('PackingNotes'));

export const usePACKING_NOTE_ROUTES = (): RouteConfig => {
  return {
    exact: true,
    label: 'Packing Notes',
    to: PACKING_NOTE_TO,
    icon: getIconByIconName('packingNote'),
    color: '#2b4f62',
    component: PackingNotesOverview,
    permission: PERMISSION.VP_PACKING_NOTE_VIEW,
    children: {
      Detail: {
        label: 'Detail',
        display: false,
        children: {
          PackingNoteDetail: {
            display: false,
            label: ':ids',
            to: `${PACKING_NOTE_DETAIL_TO}/:ids`,
            permission: PERMISSION.VP_PACKING_NOTE_VIEW,
            component: PackingNotesDetails,
          },
        },
      },
      CreatePackingNote: {
        label: 'Create',
        display: false,
        children: {
          PackingNoteDetail: {
            label: ':orderNumber',
            to: `${CREATE_PACKING_NOTE_TO}/:orderNumber`,
            permission: PERMISSION.VP_PACKING_NOTE_CREATE,
            component: CreatePackingNote,
          },
        },
      },
      PackingNote: {
        label: ':orderNumber',
        display: false,
        to: `${PACKING_NOTE_TO}/:orderNumber`,
        component: PackingNotesOverview,
        permission: PERMISSION.VP_PACKING_NOTE_VIEW,
        fallbackComponent: NewsList,
      },
    },
  };
};
