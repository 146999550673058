import { PropsWithChildren, ReactElement, ReactNode, useMemo, useRef } from 'react';

import { properties } from 'Helpers';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { UPDATE_ACCESS_TOKEN } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useRefreshToken } from 'Hooks/useAuthHandler/helpers/useRefreshToken';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { packingNoteBaseUrl } from 'PackingNotes/_services_';
const cache = new InMemoryCache();

export const useGqlClient = (): ApolloClient<NormalizedCacheObject> => {
  const branchNumber = sessionStorage.getItem('gqlStylesBranch')
    ? sessionStorage.getItem('gqlStylesBranch')
    : '';

  const { authState, authDispatch } = useAuthState();

  const { refreshToken, logoutUser } = useRefreshToken();

  const isFetchingToken = useRef(false);

  const errorLink = useMemo(
    () =>
      onError(({ networkError, operation, forward }) => {
        // User access token has expired
        if (networkError !== undefined && (networkError as ServerError).statusCode === 401) {
          if (isFetchingToken.current) {
            return;
          }
          isFetchingToken.current = true;
          //Let's refresh token through async request
          return new Observable((observer) => {
            refreshToken()
              .then((refreshResponse) => {
                // reset the headers
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    // Re-add old headers
                    ...headers,
                    // Switch out old access token for new one
                    authorization: `Bearer ${refreshResponse.data.access_token}` || null,
                  },
                }));
                authDispatch({
                  type: UPDATE_ACCESS_TOKEN,
                  accessToken: refreshResponse.data.access_token,
                  expires: new Date().getTime() + 1000 * refreshResponse.data.expires_in,
                });
              })
              .catch(() => {
                isFetchingToken.current = false;

                logoutUser();
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                isFetchingToken.current = false;
                forward(operation).subscribe(subscriber);
              })
              .catch(() => {
                // No refresh or client token available, we force user to login
                isFetchingToken.current = false;

                logoutUser();
              });
          });
        }
      }),
    [authDispatch, logoutUser, refreshToken]
  );
  const client = useMemo(() => {
    return new ApolloClient({
      link: ApolloLink.from([
        new MultiAPILink({
          getContext: (endpoint) => {
            if (endpoint !== 'releaseTool') {
              return {
                headers: {
                  Authorization: 'Bearer ' + authState.tokens?.accessToken,
                },
              };
            }
            return {};
          },

          endpoints: {
            papskilt: properties.qglHosts.papskiltGQLHost,
            releaseTool: properties.qglHosts.releaseToolGQLHost,
            packingNotes: packingNoteBaseUrl('packing-notes'),
            styles: properties.qglHosts.stylesGQLHost({
              stylesBranch: branchNumber ?? undefined,
            }),
          },
          wsSuffix: '/graphql',

          createHttpLink: () => errorLink.concat(createHttpLink()),

          createWsLink: (uri) =>
            new WebSocketLink({
              uri,
              options: {
                reconnect: true,
              },
            }),
        }),
      ]),
      cache: cache,
    });
  }, [authState.tokens?.accessToken, branchNumber, errorLink]);
  return client;
};

export const GraphQLProvider = ({ children }: PropsWithChildren<ReactNode>): ReactElement => {
  const client = useGqlClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
