import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUnconfirmClaim } from 'Claim/_services/hooks/claims/useUnconfirmClaim';
import UnconfirmModal from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/UnconfirmModal';
import { CLAIM_UPDATE_STATUS } from 'Claim/Claims/context/ClaimGroupsActions';
import { useClaimGroupsState } from 'Claim/Claims/context/ClaimGroupsReducer';
import IconButton from 'Components/Shared/BestButton/IconButton';
import { PERMISSION } from 'Constants/permissions.constants';
import { toastActions } from 'Store/actions/toast.actions';

type Props = {
  claimId: number;
};

const UnconfirmButton = (props: Props) => {
  const { dispatch } = useClaimGroupsState();
  const [openModal, setOpenModal] = useState(false);
  const reduxDispatch = useDispatch();

  const [doUnconfirm, { loading, error, data }] = useUnconfirmClaim(props.claimId);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  useEffect(() => {
    if (error) {
      reduxDispatch(toastActions.setErrorMessage(error.message));
    }
  }, [error, reduxDispatch]);

  const onUnconfirm = useCallback(() => {
    doUnconfirm();
  }, [doUnconfirm]);

  useEffect(() => {
    if (data === undefined) {
      return;
    }
    dispatch({
      type: CLAIM_UPDATE_STATUS,
      id: props.claimId,
      action: 'UNCONFIRM',
      supplierComment: '',
    });
    handleCloseModal();
  }, [data, dispatch, props.claimId, handleCloseModal]);

  return (
    <>
      <IconButton
        loading={loading}
        iconName="view"
        onClick={handleOpenModal}
        variant="contained"
        colorSchema={{ background: 'orange', foreground: 'white' }}
        disableElevation
        noMargin
        permission={PERMISSION.VP_CLAIM_REVERSE}
      >
        Unconfirm
      </IconButton>
      <UnconfirmModal onClose={handleCloseModal} onUnconfirm={onUnconfirm} open={openModal} />
    </>
  );
};

export default UnconfirmButton;
