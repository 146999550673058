import { claimBaseUrl, GetClaimConfig } from 'Claim/_services/claimService';
import { UseLazyBestApiTuple, useLazyBestApi } from 'Components/Hooks/useLazyBestApi';
import { BestoneResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';

export const fetchClaimPrint = (claimId: number): string => claimBaseUrl(`claims/${claimId}/pdf`);

export const useFetchClaimPrint = (
  claimId: number
): UseLazyBestApiTuple<BestoneResponse<string>, GetClaimConfig> => {
  return useLazyBestApi<BestoneResponse<string>, GetClaimConfig>(fetchClaimPrint(claimId), {
    headers: {
      'CLAIMS-API-VERSION': '1.0',
    },
    method: 'GET',
  });
};
