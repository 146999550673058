import { PutStateClaimConfig, claimBaseUrl } from 'Claim/_services/claimService';
import { UseLazyBestApiTuple, useLazyBestApi } from 'Components/Hooks/useLazyBestApi';

const DECLINE_CLAIM = (claimId: number | undefined) => claimBaseUrl(`claims/${claimId}/decline`);

export const useDeclineClaim = (
  claimId: number | undefined,
  reason: string | undefined
): UseLazyBestApiTuple<number, PutStateClaimConfig> => {
  return useLazyBestApi<number, PutStateClaimConfig>(DECLINE_CLAIM(claimId), {
    headers: {
      'CLAIMS-API-VERSION': '1.0',
    },
    data: {
      value: reason,
    },
    method: 'PUT',
  });
};
