import { UseBestApiRequestConfig } from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { properties } from 'Helpers/properties';

const branchNumber = sessionStorage.getItem('userServiceBranch')
  ? sessionStorage.getItem('userServiceBranch') + '/'
  : '';

export const userAdServiceApiBaseUrl = (path: string): string =>
  `${properties.serviceApiURL}${branchNumber}user/${path}`;

export type GetLinkedAccount = UseBestApiRequestConfig & {
  method: 'GET' | 'PUT' | 'POST';
};

export type PostLinkedAccount = UseBestApiRequestConfig & {
  method: 'POST';
};

export type DeleteLinkedAccount = UseBestApiRequestConfig & {
  method: 'DELETE';
};
