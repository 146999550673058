import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AxiosError } from 'axios';

import { datadogRum } from '@datadog/browser-rum';
import { ProductLine, ServerBrandWithProductLines } from '_types_/MasterData/MasterDataType';
import getErrorMessage from 'Components/Hooks/useBestApi/getErrorMessage';
import { TableFilterChildItem, TableFilterItems } from 'Components/Shared/TableFilter/TableFilter';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { OrderTypeFilter } from 'Orders/_types_/OrderTypeFilter';
import { masterDataService } from 'Services/masterdata.service';
import { useGetOrderFilterType } from 'Services/orders.service/hooks';
import { BaseAction } from 'Store/actions/_types_/BaseAction';
import { toastActions } from 'Store/actions/toast.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { Vendor } from 'Store/reducers/dataCache.reducer';

export const SET_LOADING_VENDORS = 'SET_LOADING_VENDORS';
export const SET_VENDORS = 'SET_VENDORS';
export const SET_VENDORS_ERROR = 'SET_VENDORS_ERROR';

export const SET_PRODUCT_LINES = 'SET_PRODUCT_LINES';
export const SET_PRODUCT_LINES_ERROR = 'SET_PRODUCT_LINES_ERROR';
export const SET_LOADING_PRODUCT_LINES = 'SET_LOADING_PRODUCT_LINES';

export const SET_ORDER_TYPES = 'SET_ORDER_TYPES';
export const SET_ORDER_TYPES_ERROR = 'SET_ORDER_TYPES_ERROR';
export const SET_ORDER_TYPES_LOADING = 'SET_ORDER_TYPES_LOADING';

export interface SetDataChacheLoading extends BaseAction {
  loading: boolean;
}
export interface SetVendors extends BaseAction {
  vendors: Vendor[];
}

export interface SetOrderTypeFilters extends BaseAction {
  orderTypeFilters: OrderTypeFilter[];
}
export interface SetProductLines extends BaseAction {
  productLines: TableFilterItems[];
}

export interface SetError extends BaseAction {
  message: string;
}

type loadingType =
  | typeof SET_ORDER_TYPES_LOADING
  | typeof SET_LOADING_VENDORS
  | typeof SET_LOADING_PRODUCT_LINES;

const setLoading = (type: loadingType, loading: boolean): SetDataChacheLoading => {
  return {
    type,
    loading,
  };
};

export const useFetchVendors = (): (() => void) => {
  const dispatch = useDispatch();
  const userState = useSelector((root: RootReducerState) => root.userReducer);
  const cacheState = useSelector((root: RootReducerState) => root.dataCacheState);
  const { authState } = useAuthState();
  const [localLoading, setLocalLoading] = useState(false);
  const isBestsellerUser = useSelector(
    (root: RootReducerState): boolean => root.userReducer.user?.isBestsellerUser ?? false
  );
  const fetch = useCallback((): void => {
    if (
      !isBestsellerUser ||
      localLoading ||
      userState.user === null ||
      authState.tokens?.accessToken === undefined ||
      cacheState.vendors.error ||
      cacheState.vendors.loading ||
      cacheState.vendors.vendors.length > 0 ||
      (cacheState.vendors.vendors.length === 0 && cacheState.vendors.error)
    ) {
      return;
    }
    dispatch(setLoading(SET_LOADING_VENDORS, true));
    masterDataService
      .getAllVendors()
      .then((res: Vendor[]): void => {
        dispatch({
          type: SET_VENDORS,
          vendors: res,
        });
        dispatch(setLoading(SET_LOADING_VENDORS, false));
        setLocalLoading(false);
      })
      .catch((error: AxiosError): void => {
        datadogRum.addError(`Unable to get vendors (${error.code} : ${error.message})`, { error });
        dispatch(toastActions.setErrorMessage('Unable to get vendors. Please try again later'));
        dispatch(setLoading(SET_LOADING_VENDORS, false));

        dispatch({
          type: SET_VENDORS_ERROR,
          message:
            error.response?.status === 501
              ? 'Unable to get data from server. Please try again later'
              : getErrorMessage(error),
        });
        setLocalLoading(false);
      });
  }, [
    authState.tokens?.accessToken,
    cacheState.vendors.error,
    cacheState.vendors.loading,
    cacheState.vendors.vendors.length,
    dispatch,
    isBestsellerUser,
    localLoading,
    userState.user,
  ]);
  return fetch;
};

const adaptProductLines = (brands: ServerBrandWithProductLines[]): TableFilterItems[] => {
  return brands.map((brand: ServerBrandWithProductLines): TableFilterItems => {
    return {
      id: brand.brandNumber,
      name: brand.brandName,
      children: brand.productLines.map((pl: ProductLine): TableFilterChildItem => {
        return {
          id: pl.productLineNumber,
          name: pl.productLineName,
        };
      }),
    };
  });
};

export const useFetchProductLines = (): (() => void) => {
  const dispatch = useDispatch();
  const userState = useSelector((root: RootReducerState) => root.userReducer);
  const cacheState = useSelector((root: RootReducerState) => root.dataCacheState);
  const { authState } = useAuthState();

  const canFetch = useMemo(() => {
    return (
      userState.user !== null &&
      authState.tokens?.accessToken !== undefined &&
      !cacheState.productLines.loading &&
      cacheState.productLines.productLines.length === 0 &&
      !cacheState.productLines.error
    );
  }, [
    authState.tokens?.accessToken,
    cacheState.productLines.error,
    cacheState.productLines.loading,
    cacheState.productLines.productLines.length,
    userState.user,
  ]);

  const fetch = useCallback(() => {
    if (!canFetch) {
      return;
    }
    dispatch(setLoading(SET_LOADING_PRODUCT_LINES, true));

    masterDataService
      .fetchBrandsAndPL()
      .then((brands) => {
        const filters = adaptProductLines(brands);
        dispatch({
          type: SET_PRODUCT_LINES,
          productLines: filters,
        });
        dispatch(setLoading(SET_LOADING_PRODUCT_LINES, false));
      })
      .catch((error: AxiosError): void => {
        dispatch(
          toastActions.setErrorMessage('Unable to get productlines. Please try again later')
        );
        dispatch({
          type: SET_PRODUCT_LINES_ERROR,
          message:
            error.response?.status === 501
              ? 'Unable to get data from server. Please try again later'
              : getErrorMessage(error),
        });
        dispatch(setLoading(SET_LOADING_PRODUCT_LINES, false));
      });
  }, [canFetch, dispatch]);

  return fetch;
};

export const useFetchOrderTypeFilters = (): (() => void) => {
  const dispatch = useDispatch();
  const userState = useSelector((root: RootReducerState) => root.userReducer);
  const cacheState = useSelector((root: RootReducerState) => root.dataCacheState);
  const [fetch, { data, error, loading }] = useGetOrderFilterType();
  const { authState } = useAuthState();
  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_ORDER_TYPES,
        orderTypeFilters: data,
      });
    }
  }, [dispatch, data]);
  useEffect(() => {
    if (loading !== cacheState.orderTypeFilters.loading) {
      dispatch(setLoading(SET_ORDER_TYPES_LOADING, loading));
    }
  }, [cacheState.orderTypeFilters.loading, dispatch, loading]);
  useEffect(() => {
    if (error !== undefined) {
      dispatch({
        type: SET_ORDER_TYPES_ERROR,
        message:
          error.response?.status === 501
            ? 'Unable to get data from server. Please try again later'
            : getErrorMessage(error),
      });
    }
  }, [dispatch, error]);
  const doFetch = useCallback(() => {
    if (
      authState.tokens?.accessToken === undefined ||
      userState.user === null ||
      loading === true ||
      data !== undefined ||
      error !== undefined ||
      cacheState.orderTypeFilters.loading ||
      cacheState.orderTypeFilters.orderTypeFilters.length > 0 ||
      (cacheState.orderTypeFilters.orderTypeFilters.length === 0 &&
        cacheState.orderTypeFilters.error)
    ) {
      return;
    }
    fetch();
  }, [
    authState.tokens?.accessToken,
    cacheState.orderTypeFilters,
    data,
    error,
    fetch,
    loading,
    userState.user,
  ]);

  return doFetch;
};

export type DataCacheActionsTypes =
  | SetOrderTypeFilters
  | SetVendors
  | SetDataChacheLoading
  | SetError;

export const dataCacheActions = {
  setLoading,
};
