import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useMsal, useAccount } from '@azure/msal-react';
import { loginRequest } from 'Config/adAuthConfig';
import { useLogin } from 'Services/authentication.service';
import { errorHandler } from 'Views/Login/AdSinginButton/helpers/errorHandler';

type automaticSignInState = 'COMPLETED' | 'PENDING' | 'NOT_STARTED' | 'FAILED';
interface Props {
  beBranch?: string;
  redirectToHome?: boolean;
}

const useAutoSignIn = (props?: Props) => {
  const history = useHistory();

  const { instance } = useMsal();
  const account = useAccount();

  const logIn = useLogin();
  const [hasError, setHasError] = useState(false);
  const [automaticSignInState, setAutomaticSignInState] =
    useState<automaticSignInState>('NOT_STARTED');

  const tryToAutomaticallySignIn = useCallback(async () => {
    if (hasError) {
      return;
    }

    if (automaticSignInState !== 'NOT_STARTED') {
      return;
    }

    if (account === null) {
      setAutomaticSignInState('COMPLETED');
      return;
    }
    setAutomaticSignInState('PENDING');

    try {
      const adResponse = await instance.acquireTokenSilent({ ...loginRequest, account });
      try {
        await logIn({ adIdToken: adResponse.idToken });
        if (props?.redirectToHome) {
          history.push('/');
        }
        setAutomaticSignInState('COMPLETED');
      } catch (e) {
        setHasError(true);
        setAutomaticSignInState('FAILED');
        errorHandler(e);
      }
    } catch (e) {
      setHasError(true);
      setAutomaticSignInState('FAILED');
      errorHandler(e);
    }
  }, [account, automaticSignInState, history, instance, logIn, props?.redirectToHome]);

  return {
    tryToAutomaticallySignIn,
    automaticSignInState,
  };
};

export default useAutoSignIn;
