import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { featureFlags, useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { useHasPermission } from 'Models/Permission/permissionHelper';
import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

interface PropType extends RouteProps {
  Component: React.ReactType;
  permission?: number | number[];
  FallbackComponent?: React.ReactType;
  featureFlag?: featureFlags;
}

const PrivateRoute = ({
  Component,
  permission,
  FallbackComponent,
  featureFlag,
  ...rest
}: PropType): ReactElement => {
  const flags = useFlags();
  const userTokens = useAuthState().authState?.tokens;
  const user = useSelector((root: RootReducerState): UserReducerType => root.userReducer);
  const hasPermission = useHasPermission();
  const getFeatureFlag = useGetFeatureFlag();

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps): ReactElement => {
        if (!userTokens?.accessToken) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
        if (props.location.pathname !== '/setVendor' && user.user?.hasToSelectVendorMdmIdentifier) {
          return <Redirect to={{ pathname: '/setVendor', state: { from: props.location } }} />;
        }
        if (featureFlag && !flags[getFeatureFlag(featureFlag)]) {
          return FallbackComponent ? <FallbackComponent {...rest} /> : <></>;
        }
        return permission ? (
          hasPermission(permission) ? (
            <Component {...props} />
          ) : FallbackComponent ? (
            <FallbackComponent {...props} />
          ) : (
            <></>
          )
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default PrivateRoute;
