import { ReactElement, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  IconButton,
  Button,
} from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  token: string | undefined;
}

const ShowContactSupportDialog = (props: Props): ReactElement => {
  const mailTitle = 'Unable to login (403)';
  const mailBody = useMemo(
    (): string =>
      props.token !== undefined
        ? `
    %0D%0A
    %0D%0A
    %0D%0A
  -----------------------------------------------------
  %0D%0A
  PLEASE KEEP THIS:
  %0D%0A
  ${props.token}
  %0D%0A
  -----------------------------------------------------`
        : '',
    [props.token]
  );
  const mailParams = useMemo(() => `?subject=${mailTitle}&body=${mailBody}`, [mailTitle, mailBody]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Unable to log you in</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'gray',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent>
        <Typography fontWeight="bold">We are unable to log you in.</Typography>
        <Typography marginTop="8px">
          <span>{`It might be because there's an issue with your account. `}</span>
        </Typography>
        <Typography marginTop="8px">
          <Button
            href={`mailto:techsourcing@bestseller.com${mailParams}`}
            variant="outlined"
            style={{ cursor: 'pointer' }}
          >
            Please click here to contact support.
          </Button>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ShowContactSupportDialog;
