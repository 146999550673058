import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { DASHBOARD_TO } from 'Constants/shared.constants';

import lazyImport from '../../Helpers/lazyImport';

import { RouteConfig } from '.';

const NewDashboard = lazyImport(() => import('Dashboard'));

export const useDASHBOARD_ROUTES = (): RouteConfig => {
  return {
    label: 'Dashboard',
    to: DASHBOARD_TO,
    icon: getIconByIconName('dashboard'),
    color: '#485627',
    component: NewDashboard,
  };
};

export const useDASHBOARD_ALTERNATE_ROUTES = (): RouteConfig => {
  return {
    label: 'Dashboard',
    to: '/',
    display: false,
    exact: true,
    icon: getIconByIconName('dashboard'),
    color: '#485627',
    component: NewDashboard,
  };
};
