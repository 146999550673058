import { useCallback, useEffect, useRef, useState } from 'react';
import { matchPath, match as Match, useLocation } from 'react-router';

import { NestedRouteConfig, useGetRoutePaths, useRoutes } from 'Views/routes';

import { BreadCrumb } from './_types_';

export * from './_types_';

/**
 * Returns an array of breadcrumbs which get updated when the window location changes.
 * It looks in the `routes` object in `UI/Navigation/routes`
 * @returns
 *
 * ```
 * [{
 *    label: string,
 *    to: string | null,
 * }]
 * ```
 *
 *  @example
 *
 * ```
 * const breadCrumbs: BreadCrumb[] = useBreadCrumbs();
 * ```
 *
 */
export const useBreadCrumbs = (): BreadCrumb[] => {
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([]);
  const getRoutePaths = useRef(useGetRoutePaths());
  const routes = useRef(useRoutes());
  const location = useLocation();
  const getBreadCrumbs = useCallback(() => {
    const match: Match<{
      [key: string]: string | undefined;
    }> | null = matchPath(location.pathname, {
      path: getRoutePaths.current(),
      exact: true,
    });
    const returnedBreadCrumbs: BreadCrumb[] = [];

    if (!match) {
      return [];
    }

    // NOTE: Loops through the `routes` object to find which route it's currently on
    const loopThroughRoutesToFindBreadCrumbs = (
      tree: { [key: string]: unknown },
      routeToFind: string
    ) => {
      if (typeof tree !== 'object' || !tree) {
        return false;
      }

      for (let i = 0; i < Object.keys(tree).length; i += 1) {
        const key = Object.keys(tree)[i];
        if (
          typeof tree[key] === 'object' &&
          Object.prototype.hasOwnProperty.call(tree[key], 'to') &&
          (tree[key] as NestedRouteConfig).to === routeToFind
        ) {
          returnedBreadCrumbs.push({
            label: (tree[key] as NestedRouteConfig).label,
            to: (tree[key] as NestedRouteConfig).to || null,
          });

          return true;
        }

        if (typeof tree[key] === 'object' && key !== 'icon') {
          const found = loopThroughRoutesToFindBreadCrumbs(
            tree[key] as { [key: string]: unknown },
            routeToFind
          );
          if (found) {
            if (tree[key] && (tree[key] as NestedRouteConfig).label) {
              returnedBreadCrumbs.push({
                label: (tree[key] as NestedRouteConfig).label,
                to: (tree[key] as NestedRouteConfig).to || null,
              });
            }

            return true;
          }
        }
      }
      return false;
    };
    loopThroughRoutesToFindBreadCrumbs(routes.current, match.path);

    // NOTE: Replaces the params in the URL with the values of the parameters.
    Object.keys(match.params).forEach((param) => {
      const replaceIndex = returnedBreadCrumbs.findIndex((r) => r.label === `:${param}`);
      if (replaceIndex < 0 || replaceIndex > returnedBreadCrumbs.length - 1) {
        return;
      }
      const replacedValue = `${returnedBreadCrumbs[replaceIndex].to?.replace(
        param,
        `${match.params[param]}`
      )}`;
      returnedBreadCrumbs[replaceIndex] = {
        label: `${match.params[param]}`,
        to: replacedValue,
      };
    });

    return returnedBreadCrumbs;
  }, [location.pathname]);

  useEffect(() => {
    setBreadCrumbs([...getBreadCrumbs()].reverse());
  }, [getBreadCrumbs]);

  return breadCrumbs;
};
