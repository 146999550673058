import { ReactElement } from 'react';

import { useHasPermission } from 'Models/Permission/permissionHelper';

export interface WithPermissionProps {
  permission: number;
}

const WithPermission = (
  props: React.PropsWithChildren<unknown> & WithPermissionProps
): ReactElement | null => {
  const hasPermission = useHasPermission();
  return hasPermission(props.permission) ? <>{props.children}</> : null;
};

export default WithPermission;
