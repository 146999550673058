import { ReactElement } from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { DateFilter } from 'Components/Table/state/_types_/TableFilters';

import { TableColumn } from '../../state/_types_/TableColumn';
import { TableRecord } from '../../state/_types_/TableRecord';
import { doChangeFilter } from '../../state/actions/changeFilter';
import { useTableState } from '../../state/useTableState';

const useStyles = makeStyles(() => ({
  typography: {
    margin: 8,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface Props<T extends TableRecord> {
  filter: DateFilter;
  column: TableColumn<T>;
}

const TableDateFilter = <T extends TableRecord>({ filter, column }: Props<T>): ReactElement => {
  const classes = useStyles();
  const { state, dispatch } = useTableState();
  const statefilter = state.filters[column.property];

  if (statefilter && statefilter?.type !== filter.type) {
    return <></>;
  }

  const [dateFrom, dateTo] = statefilter?.value ?? [null, null];

  const onChangeFromDate = (value: Date | null) => {
    dispatch(
      doChangeFilter({
        property: column.property,
        filterType: 'date',
        value: [value, dateTo],
      })
    );
  };
  const onChangeToDate = (value: Date | null) => {
    dispatch(
      doChangeFilter({
        property: column.property,
        filterType: 'date',
        value: [dateFrom, value],
      })
    );
  };

  return (
    <div className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker<Date>
          clearable
          renderInput={(textFieldProps) => <TextField {...textFieldProps} variant="outlined" />}
          value={dateFrom}
          onChange={(date: Date | null): void => {
            onChangeFromDate(date);
          }}
          label={`Start`}
          maxDate={dateTo ?? undefined}
        />
        <Typography className={classes.typography}>To</Typography>
        <DatePicker<Date>
          clearable
          renderInput={(textFieldProps) => <TextField {...textFieldProps} variant="outlined" />}
          value={dateTo}
          onChange={(date: Date | null): void => {
            onChangeToDate(date);
          }}
          label={`End`}
          minDate={dateFrom ?? undefined}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TableDateFilter;
