import { useContext, useReducer } from 'react';

import { ClaimState } from 'Claim/_types/claims';
import {
  CLAIM_ADD_DETAILS_TO_GROUP,
  CLAIM_ADD_GROUP,
  CLAIM_CLEAR_ALL_GROUPS,
  CLAIM_UPDATE_STATUS,
  ClaimGroupsActions,
} from 'Claim/Claims/context/ClaimGroupsActions';
import { ClaimGroupsState, ClaimsStateGroup } from 'Claim/Claims/context/ClaimGroupsState';
import { ClaimGroupsContext } from 'Claim/Claims/context/ClaimsGroupsProvider';
import { formatDateToYYYYMMDD } from 'Helpers/util';

const reducer = (state: ClaimGroupsState, action: ClaimGroupsActions): ClaimGroupsState => {
  switch (action.type) {
    case CLAIM_CLEAR_ALL_GROUPS: {
      return {
        ...state,
        groups: [],
      };
    }
    case CLAIM_ADD_GROUP: {
      return {
        ...state,
        groups: [...state.groups, { group: action.group, details: undefined }],
      };
    }
    case CLAIM_ADD_DETAILS_TO_GROUP: {
      const newGroups = state.groups.map((group) => {
        if (group.group.claim.id === action.id) {
          return { ...group, details: action.details };
        }
        return group;
      });
      return {
        ...state,
        groups: newGroups,
      };
    }
    case CLAIM_UPDATE_STATUS: {
      const updateState = (action: 'CONFIRM' | 'UNCONFIRM' | 'DECLINE'): ClaimState => {
        if (action === 'CONFIRM') {
          return 'CONFIRMED';
        }
        if (action === 'DECLINE') {
          return 'DECLINED';
        }
        return 'NOT_CONFIRMED';
      };
      const updatedState: ClaimState = updateState(action.action);

      const newGroups = state.groups.map((group) => {
        if (group.group.claim.id === action.id) {
          return {
            ...group,
            group: {
              ...group.group,
              claim: {
                ...group.group.claim,
              },
              status: updatedState,
            },
            details: {
              ...group.details,
              supplierComment: action.supplierComment
                ? `${group.details?.supplierComment ?? ''} Comment Added: ${formatDateToYYYYMMDD(
                    new Date()
                  )} ${action.supplierComment}`.trim()
                : group.details?.supplierComment ?? '',
            },
          } as ClaimsStateGroup;
        }
        return group;
      });
      return {
        ...state,
        groups: newGroups,
      };
    }
    default: {
      return state;
    }
  }
};

export const useClaimGroupsReducer = (
  initialState: ClaimGroupsState
): [ClaimGroupsState, (action: ClaimGroupsActions) => void] => {
  return useReducer(reducer, initialState);
};

export const useClaimGroupsState = (): {
  state: ClaimGroupsState;
  dispatch: (action: ClaimGroupsActions) => void;
} => {
  const context = useContext(ClaimGroupsContext);
  if (!context) {
    throw new Error('useClaimGroupsState must be used within a ClaimGroupsProvider');
  }
  return context;
};
