import { ReactElement, useMemo } from 'react';

import { Tooltip } from '@mui/material';

const ReverseArraysOrderInToolTip = (props: { values: string[]; key: string }): ReactElement => {
  const localOrderNumber = useMemo(() => {
    return [...(props.values ?? [])].reverse();
  }, [props.values]);
  if (localOrderNumber.length === 0) {
    return <span>-</span>;
  }

  const firstElement = localOrderNumber.shift();

  return (
    <div>
      <span>{firstElement}</span>
      {localOrderNumber.length > 0 && (
        <Tooltip
          title={
            <div>
              {localOrderNumber.map((x, i) => (
                <div key={`${props.key}-${i}`}>{x}</div>
              ))}
            </div>
          }
        >
          <span style={{ borderBottom: '1px dashed' }}> and {localOrderNumber.length} more</span>
        </Tooltip>
      )}
    </div>
  );
};

export default ReverseArraysOrderInToolTip;
