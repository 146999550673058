// NOTE: THIS FILE IS ONLY RENDERED BY IE

import React from 'react';
import { isWindows } from 'react-device-detect';

import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(function (theme: Theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      height: '100vh',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      justifyContent: 'center',
    },
    header: {
      marginBottom: theme.spacing(4),
    },
    actions: {
      display: 'flex',
      width: '40vw',
      justifyContent: 'space-between',
      padding: theme.spacing(10, 0),
    },
    button: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: +theme.shape.borderRadius * 4,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(4),
      transition: 'box-shadow 0.25s',
      '& > *:first-child': {
        marginBottom: theme.spacing(2),
      },
      '&:hover': {
        cursor: 'pointer',
        boxShadow: theme.shadows[20],
      },
    },
  };
});

export const IEBlocker = function (): React.ReactElement {
  const classes = useStyles();

  const browsers = [
    {
      img: 'https://upload.wikimedia.org/wikipedia/commons/7/7e/Microsoft_Edge_logo_%282019%29.png',
      name: 'Edge',
      url: 'https://www.microsoft.com/en-us/edge',
    },
    {
      img: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Chrome_icon_%28September_2014%29.svg',
      name: 'Chrome',
      url: 'https://www.google.com/chrome/?brand=WHAR&gclid=Cj0KCQjwhr2FBhDbARIsACjwLo2Pbs8BPYKP1WFRRsnNV-Dqj076yEgbqJZLwZznOOAB4HsgPNTUcOIaAorcEALw_wcB&gclsrc=aw.ds',
    },
    {
      img: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Firefox_logo%2C_2019.svg',
      name: 'Firefox',
      url: 'https://www.mozilla.org/en-US/firefox/new/',
    },
  ];

  const redirect = (url: string) => {
    if (isWindows && url === browsers[0].url) {
      window.open(`microsoft-edge:${window.location.href}`);
      return;
    }
    window.open(url);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.header}>
        Unsupported browser
      </Typography>
      <Typography variant="h6" color="textSecondary">
        <span>The new Vendor Portal is not supported on Internet Explorer.</span>
        <br />
        <span>Please use one of the following browsers instead:</span>
      </Typography>
      <div className={classes.actions}>
        {browsers.map((config, index: number) => (
          <div
            key={`${config.name}${index}`}
            className={classes.button}
            onClick={() => redirect(config.url)}
          >
            <img width="60" height="60" src={config.img} />
            <Typography variant="button">{config.name}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
