import { createContext } from 'react';

import { ClaimGroupsDispatch } from 'Claim/Claims/context/ClaimGroupsActions';
import { ClaimGroupsState } from 'Claim/Claims/context/ClaimGroupsState';

export const ClaimGroupsContext =
  createContext<
    | {
        state: ClaimGroupsState;
        dispatch: ClaimGroupsDispatch;
      }
    | undefined
  >(undefined);

export const ClaimGroupsProvider = ClaimGroupsContext.Provider;
