import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router';

import { PERMISSION } from 'Constants/permissions.constants';
import { INBOUND_INVOICE_TO, SAMPLE_PROFORMA_INVOICE_TO } from 'Constants/shared.constants';
import { useHasPermission } from 'Models/Permission/permissionHelper';
import NoAccess from 'Views/Login/NoAccess';

const Invoice = (): ReactElement => {
  const history = useHistory();
  const hasPermission = useHasPermission();

  useEffect((): void => {
    if (hasPermission(PERMISSION.VP_INVOICE_INBOUND_VIEW)) {
      history.replace(INBOUND_INVOICE_TO);
      return;
    }
    if (hasPermission(PERMISSION.VP_PROFORMA_INVOICE_VIEW)) {
      history.replace(SAMPLE_PROFORMA_INVOICE_TO);
    }
  }, [hasPermission, history]);
  return <NoAccess />;
};

export default Invoice;
