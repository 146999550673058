import { useSelector } from 'react-redux';

import { MenuItemElement } from 'Components/Shared/FilterButton';
import { removeUndefinedFromArray } from 'Helpers/util';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

const useSharedFilterParameters = () => {
  const orderTypeFilterCache = useSelector(
    (root: RootReducerState) => root.dataCacheState
  ).orderTypeFilters;
  const productLinesCache = useSelector(
    (root: RootReducerState) => root.dataCacheState.productLines
  ).productLines;

  const brands = productLinesCache.map(
    (r): MenuItemElement => ({
      title: r.name,
      value: r.name,
    })
  );

  const productLines = productLinesCache
    .map((r) => r.children)
    .flat()
    .filter(removeUndefinedFromArray)
    .map(
      (r): MenuItemElement => ({
        title: r.name,
        value: r.id,
      })
    );

  return {
    brands,
    productLines,
    orderTypeFilterCache,
    productLinesCache,
  };
};
export default useSharedFilterParameters;
