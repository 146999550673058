import React, { ReactElement, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { ClaimAmount, TotalAmount } from 'Claim/_types/claims';
import { formatAmountToUS } from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/formatAmountToUS';

type Props = {
  claimAmount: ClaimAmount | undefined;
  totalAmount: TotalAmount | undefined;
};

const ClaimDetailsFooter = (props: Props): ReactElement => {
  const claimTotalAmount = useMemo(
    () =>
      props.claimAmount
        ? `${props.claimAmount?.currencyCode ?? ''} ${props.claimAmount?.amount ?? ''}`
        : '-',
    [props.claimAmount]
  );

  const totalAmount = useMemo(
    () =>
      props.totalAmount
        ? `${props.totalAmount?.currency ?? ''} ${formatAmountToUS(props.totalAmount?.amount ?? 0)}`
        : '-',
    [props.totalAmount]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography aria-label="Total Sum">
        <b>Order Total: </b>
        {totalAmount}
      </Typography>
      <Box display="flex" columnGap={0.5}>
        <Typography>
          <b>Claim Amount: </b>
        </Typography>
        <Box display="flex" alignItems="center" columnGap={0.5}>
          <Typography>{claimTotalAmount}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimDetailsFooter;
