import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import { STYLES_TO } from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';

import { RouteConfig } from '.';

const Styles = lazyImport(() => import('Styles'));

export const useSTYLES_ROUTES = (): RouteConfig => {
  return {
    label: 'Styles',
    to: STYLES_TO,
    exact: true,
    permission: PERMISSION.VP_STYLES_VIEW,
    icon: getIconByIconName('style'),
    color: '#bc8940',
    component: Styles,
  };
};
