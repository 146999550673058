import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';

type Props = {
  isClosed: boolean;
  claimNumber: string;
  creditNoteNumber?: string;
};

const ClaimNumberCellResolver = (props: Props) => {
  const flags = useFlags();
  const getFeatureFlag = useGetFeatureFlag();
  return (
    <Box display={'flex'} alignContent={'center'}>
      <Box>
        <Typography>{props.claimNumber}</Typography>
        {props.isClosed && <Typography variant="caption">- closed -</Typography>}
      </Box>
      {flags[getFeatureFlag('credit-note')] && props.creditNoteNumber && (
        <Tooltip title="This Claim already has a credit Note" placement="top">
          <SvgIcon fontSize="small" sx={{ ml: 1 }}>
            {getIconByIconName('creditNote')}
          </SvgIcon>
        </Tooltip>
      )}
    </Box>
  );
};

export default ClaimNumberCellResolver;
