import { useState, ReactElement, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Popover, Checkbox } from '@mui/material';

import { devSettingsActions } from 'Store/actions/devSettings.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { DevSettingsState } from 'Store/reducers/devSettings.reducer';

const FakePrintPopover = (): ReactElement => {
  const dispatch = useDispatch();
  const devSettings = useSelector(
    (root: RootReducerState): DevSettingsState => root.devSettingsState
  );

  const [anchorElFeatureFlags, setAnchorElFeatureFlags] = useState<HTMLButtonElement | null>(null);
  const openFeatureFlag = Boolean(anchorElFeatureFlags);
  return (
    <>
      <Button
        aria-describedby={'FakePrintPopover'}
        variant="text"
        onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
          setAnchorElFeatureFlags(evt.currentTarget);
        }}
      >
        <span>FAKE PRINT ERROR&nbsp;</span>
        <span>{!openFeatureFlag ? '⇧' : '⇩'}</span>
      </Button>
      <Popover
        id={'FakePrintPopover'}
        open={openFeatureFlag}
        anchorEl={anchorElFeatureFlags}
        onClose={(): void => setAnchorElFeatureFlags(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>Full</td>
              <td>
                <Checkbox
                  checked={devSettings.fakeExtError.fullFailure ?? false}
                  onChange={(_, checked): void => {
                    dispatch(
                      devSettingsActions.setFakeExtError(
                        checked,
                        checked ? false : devSettings.fakeExtError.partialFailure
                      )
                    );
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Partial</td>
              <td>
                <Checkbox
                  checked={devSettings.fakeExtError.partialFailure ?? false}
                  onChange={(_, checked): void => {
                    dispatch(
                      devSettingsActions.setFakeExtError(
                        checked ? false : devSettings.fakeExtError.fullFailure,
                        checked
                      )
                    );
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Popover>
    </>
  );
};

export default FakePrintPopover;
