import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  List,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
  ListItemButton,
} from '@mui/material';

import { useFlags } from 'launchdarkly-react-client-sdk';

import classes from 'Components/UI/Navigation/Navigation.module.scss';
import { useHasPermission } from 'Models/Permission/permissionHelper';
import { NestedRouteConfig } from 'Views/routes';


type Level = 0 | 1 | 2 | 3;

interface ListItemWithCollapsedListProps {
  route: NestedRouteConfig;
  level: Level;
}
interface CollapsedListProps {
  childrenRoutes?: { [ChildRouteKey: string]: NestedRouteConfig };
  open: boolean;
  level?: Level;
}

const ListItemWithCollapsedList = ({ route, level }: ListItemWithCollapsedListProps) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleNavigate = () => {
    history.push(route.to as string);
  };

  if (route.display === false) {
    return null;
  }
  const className = classes[`listLevel${level}` as keyof typeof classes];

  return [
    <ListItemButton
      key="list-item"
      className={[open ? classes.hasShadow : '', className].join(' ')}
      {...(route.to && {
        onClick: handleNavigate,
      })}
    >
      <ListItemText primary={route.label} />
      <ListItemSecondaryAction className={classes.listItemButton}>
        <IconButton onClick={handleClick} size="large">
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>,
    <CollapsedList
      key="list-item-collapse"
      open={open}
      childrenRoutes={route.children}
      level={level}
    />,
  ] as unknown as ReactElement;
};

const CollapsedList = ({ open, childrenRoutes, level = 0 }: CollapsedListProps): ReactElement => {
  const hasPermission = useHasPermission();
  const history = useHistory();
  const className = classes[`listLevel${level}` as keyof typeof classes];
  const flags = useFlags();
  if (Object.keys(childrenRoutes ?? {}).some((r) => childrenRoutes?.[r].display !== false)) {
    return (
      <Collapse key="list-item-collapse" in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={className}>
          {childrenRoutes &&
            Object.keys(childrenRoutes).map((routeKey, index) => {
              const route = childrenRoutes[routeKey];

              const handleNavigate = () => {
                history.push(route.to as string);
              };

              if (
                route.display === false ||
                (route.permission && !hasPermission(route.permission)) ||
                (route.featureFlag && !flags[route.featureFlag])
              ) {
                return null;
              }

              if (
                route.children &&
                Object.keys(route.children).some(
                  (r) =>
                    route.children && route.children[r].display !== false && !!route.children[r].to
                )
              ) {
                return (
                  <ListItemWithCollapsedList
                    route={route}
                    level={level < 3 ? ((level + 1) as Level) : 3}
                  />
                );
              }
              return (
                <ListItemButton
                  {...(route.to && {
                    onClick: handleNavigate,
                  })}
                  key={`collapsed-list-${routeKey}-item-${index}`}
                  className={[
                    classes[
                      `listLevel${level < 3 ? ((level + 1) as Level) : 3}` as keyof typeof classes
                    ],
                    history.location.pathname === route.to ? classes.activeChild : null,
                  ].join(' ')}
                >
                  <ListItemText
                    primary={route.label}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: 14,
                      },
                    }}
                  />
                </ListItemButton>
              );
            })}
        </List>
      </Collapse>
    );
  }
  return null as unknown as ReactElement;
};

export default CollapsedList;
