import { useCallback, useMemo } from 'react';

import { Box, Checkbox, Tooltip, Typography } from '@mui/material';

import Table from 'Components/Table';
import { TableColumn } from 'Components/Table/state/_types_/TableColumn';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';

type AccountColumnn = {
  bi2Username: string;
  displayableName: string;
  companyName: string;
  isSelected?: boolean;
};

type Props = {
  selectedAccount: string | undefined;
  setSelectedAccount: (account: string | undefined) => void;
};

const isSelectedResolver = (
  item: AccountColumnn,
  selectedAccount: string | undefined,
  onCheck: (bi2Id: string) => void
) => {
  return (
    <Tooltip title="Select as default account">
      <Checkbox
        checked={item.bi2Username === selectedAccount}
        onChange={() => onCheck(item.bi2Username)}
      />
    </Tooltip>
  );
};

const AvailableAccounts = (props: Props) => {
  const { selectedAccount, setSelectedAccount } = props;
  const { authState } = useAuthState();

  const onCheck = useCallback(
    (bi2Id: string) => {
      if (selectedAccount === bi2Id) {
        setSelectedAccount(undefined);
        return;
      }
      setSelectedAccount(bi2Id);
    },
    [setSelectedAccount, selectedAccount]
  );

  const displayableRows = useMemo((): AccountColumnn[] => {
    return (authState.adAccounts ?? []).map((account) => ({
      bi2Username: account.bi2NetworkId,
      displayableName: account.displayName,
      companyName: account.companyName,
    }));
  }, [authState.adAccounts]);

  const columns = useMemo((): TableColumn<AccountColumnn>[] => {
    return [
      {
        property: 'bi2Username',
        label: 'User Name',
        resolver: (item: AccountColumnn) => item.bi2Username,
      },
      {
        property: 'displayableName',
        label: 'Name',
        resolver: (item: AccountColumnn) => item.displayableName,
      },
      {
        property: 'companyName',
        label: 'Company Name',
        resolver: (item: AccountColumnn) => item.companyName,
      },
      {
        property: 'isSelected',
        label: '',
        resolver: (item: AccountColumnn) => isSelectedResolver(item, selectedAccount, onCheck),
      },
    ];
  }, [onCheck, selectedAccount]);

  return (
    <Box>
      <Typography mb={2}>
        We have detected that this Microsoft account does not have a default user. Please, select
        which of these linked users should be the default user for this account.
      </Typography>
      <Table<AccountColumnn>
        tableName="available-bi2-accounts"
        primaryKey="bi2Username"
        rows={displayableRows}
        columns={columns}
        options={{
          toolbar: false,
          showTableName: false,
        }}
      />
    </Box>
  );
};

export default AvailableAccounts;
