import { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Link,
  Paper,
  Typography,
} from '@mui/material';

import { theme } from 'Theme';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import getErrorMessage from 'Components/Hooks/useBestApi/getErrorMessage';
import { LOGOUT } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { userService } from 'Services/authentication.service';
import { toastActions } from 'Store/actions/toast.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

const AdSigninInvitation = (): ReactElement => {
  const reduxDispatch = useDispatch();
  const { authState, authDispatch } = useAuthState();
  const [email, setEmail] = useState('');
  const [isValidated, setIsValidated] = useState(false);
  const [error, setError] = useState(' ');
  const [isLoading, setIsLoading] = useState(false);
  const [invitationNotSent, setInvitationNotSent] = useState(true);
  const history = useHistory();
  const userReducer = useSelector((state: RootReducerState) => state.userReducer);
  const token = useMemo(() => authState.tokens, [authState.tokens]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    const pattern =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (e.target.value && pattern.test(e.target.value)) {
      if (e.target.value !== userReducer.user?.adEmail) {
        setEmail(e.target.value);
        setIsValidated(true);
        setError(' ');
      } else {
        setError('This account has already been invited');
        setIsValidated(false);
      }
    } else {
      setIsValidated(false);
    }
  };

  const handleClick = () => {
    if (isValidated && token?.accessToken) {
      setIsLoading(true);
      userService.sendAdInvite(email, token.accessToken).catch((e) => {
        reduxDispatch(toastActions.setErrorMessage(getErrorMessage(e)));
      });
    }
  };

  useEffect(() => {
    if (error.length === 0) {
      setInvitationNotSent(false);
    }
  }, [error.length]);

  return (
    <Grid container height="100%" justifyContent="center" direction="column" rowGap={1}>
      <Grid item display="flex" alignItems="center">
        <Paper
          elevation={0}
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('lg')]: {
              width: 600,
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          }}
        >
          {invitationNotSent ? (
            <>
              <FormControl variant="standard" fullWidth sx={{ mb: 3 }} required>
                <InputLabel>E-mail</InputLabel>
                <Input type="email" onChange={handleChangeEmail} />
                {error.length <= 1 ? (
                  <FormHelperText>
                    {!isValidated ? 'Please enter a valid e-mail' : ' '}
                  </FormHelperText>
                ) : (
                  <FormHelperText error>{error}</FormHelperText>
                )}
              </FormControl>
              <Typography mb={1}>
                It appears that your user is not yet linked to a Microsoft account.
              </Typography>
              <Typography mb={1}>
                To proceed, please enter your email address above. We will then send you an
                invitation link to create a new Microsoft account, which you can use to access
                Vendor Portal.
              </Typography>
              <Typography mb={3}>
                {' '}
                <span>
                  If you do not wish to link your user to a Microsoft account, you can still&nbsp;
                </span>
                <Link
                  mt={5}
                  color="info.main"
                  sx={{ cursor: 'pointer', paddingX: 0.5 }}
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  access the Vendor Portal without invitation
                </Link>
                <span> for now.</span>
              </Typography>
              {!isLoading ? (
                <Button
                  variant="contained"
                  disableElevation
                  sx={{ mt: 1 }}
                  disabled={!isValidated}
                  color="primary"
                  onClick={handleClick}
                >
                  Send invitation
                </Button>
              ) : (
                <Box display="flex" justifyContent="center">
                  <Loading circularProgressColor="inherit" />
                </Box>
              )}
            </>
          ) : (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box display="flex" alignItems="center" mb={4} columnGap={1}>
                <CheckCircleIcon color="success" />
                <Typography mb={2} color="primary.main" fontSize={24} fontWeight="bold" m={0}>
                  You have been invited
                </Typography>{' '}
              </Box>
              <Typography mb={1}>An invitation has been sent to {email}.</Typography>{' '}
              <Typography mb={1}>
                Please check your email inbox to finish the process and continue to sign in with
                Microsoft.
              </Typography>
              <Typography mb={3}>
                After accepting the invitation, you will need to go back to the login screen and log
                in with your new Microsoft Account.
              </Typography>
              <Link
                color="info.main"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (token?.accessToken) {
                    userService.revokeAccess(token.accessToken);
                  }
                  authDispatch({ type: LOGOUT });
                  history.push('/login');
                }}
              >
                Go back to login screen
              </Link>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AdSigninInvitation;
