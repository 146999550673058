import React, { useCallback, useEffect, useState } from 'react';

import { useConfirmClaim } from 'Claim/_services/hooks/claims/useConfirmClaim';
import { CanConfirmOrDeclineType } from 'Claim/_types/claims';
import ReasonModal from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/ReasonModal';
import { CLAIM_UPDATE_STATUS } from 'Claim/Claims/context/ClaimGroupsActions';
import { useClaimGroupsState } from 'Claim/Claims/context/ClaimGroupsReducer';
import IconButton from 'Components/Shared/BestButton/IconButton';
import { PERMISSION } from 'Constants/permissions.constants';
import ActionButtonWithReason from 'PackingNotes/Areas/PackingNotesDetails/Components/Actions/ActionButtonWithReason';

type Props = {
  claimId: number;
  canConfirmOrDecline: CanConfirmOrDeclineType | undefined;
};

const ConfirmButton = (props: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [canCloseModal, setCanCloseModal] = useState(true);

  const [doConfirm, { loading, error, data }] = useConfirmClaim(props.claimId, reason);
  const { dispatch } = useClaimGroupsState();

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    if (!canCloseModal) {
      return;
    }
    setOpenModal(false);
  }, [canCloseModal]);

  const handleChangeConfirmReason = useCallback((value: string) => {
    if (value.trim() === '') {
      setReason(undefined);
      return;
    }
    setReason(value);
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      setCanCloseModal(true);
    }
  }, [error]);

  const onConfirm = useCallback(() => {
    setCanCloseModal(false);
    doConfirm();
  }, [doConfirm]);

  useEffect(() => {
    if (data === undefined) {
      setCanCloseModal(true);
      return;
    }
    dispatch({
      type: CLAIM_UPDATE_STATUS,
      id: props.claimId,
      action: 'CONFIRM',
      supplierComment: reason ?? '',
    });
    setCanCloseModal(true);
    handleCloseModal();
  }, [reason, dispatch, props.claimId, handleCloseModal, data]);

  useEffect(() => {
    if (!openModal) {
      setReason(undefined);
      setErrorMessage(undefined);
    }
  }, [openModal]);

  return (
    <>
      <ActionButtonWithReason
        isDoable={props.canConfirmOrDecline ?? { value: true, reasons: [] }}
        action="confirm"
        permission={PERMISSION.NONE}
      >
        <IconButton
          disabled={!props?.canConfirmOrDecline?.value}
          loading={loading}
          iconName="view"
          onClick={handleOpenModal}
          variant="contained"
          colorSchema={{ background: 'success-green', foreground: 'white' }}
          disableElevation
          noMargin
          permission={PERMISSION.NONE}
        >
          Confirm
        </IconButton>
      </ActionButtonWithReason>
      <ReasonModal
        open={openModal}
        onClose={() => !loading && handleCloseModal()}
        onUpdate={onConfirm}
        onChange={handleChangeConfirmReason}
        message={errorMessage}
      />
    </>
  );
};

export default ConfirmButton;
