import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import IconButton from 'Components/Shared/BestButton/IconButton';
import LoadingDialog from 'Components/Shared/LoadingDialog';
import { PERMISSION } from 'Constants/permissions.constants';
import { News } from 'Models/News/_types_/News';
import NewsItem from 'Models/News/NewsItem/NewsItem';
import { newsService } from 'Services/news.service';
import { newsActions } from 'Store/actions/news.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

import classes from './NewsList.module.scss';

interface PropType {
  dashboardContext: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NewsList = (props: PropType): ReactElement => {
  const newsReducer = useSelector((root: RootReducerState) => root.newsReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((root: RootReducerState) => root.userReducer.user);
  const [loading, setLoading] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [selectedNewsItem, setSelectedNewsItem] = useState<News>();

  const getNews = useCallback((): void => {
    setLoading(true);
    newsService
      .fetchNews()
      .then((res): void => {
        dispatch(newsActions.fetchNews(res));
        setLoading(false);
      })
      .catch((): void => setLoading(false));
  }, [dispatch]);

  const deleteHandler = (newsItem: News): void => {
    setShowDeletePrompt(true);
    setSelectedNewsItem(newsItem);
  };

  const openEditNewsMenu = (news: News): void => {
    history.push({
      pathname: '/newseditor/' + news.id,
    });
  };

  const openCreateNewsMenu = (): void => {
    history.push({
      pathname: '/newseditor',
    });
  };

  const displayAddNewsButton = (): ReactElement => (
    <IconButton
      permission={PERMISSION.VP_CREATE_NEWS}
      style={{ marginLeft: '1.5em' }}
      buttonShape="circle"
      size="medium"
      colorSchema={{ background: 'grey' }}
      iconName="add"
      onClick={openCreateNewsMenu}
    />
  );

  const showDeletePromptDialog = (): ReactElement | null => {
    if (selectedNewsItem !== undefined) {
      const newsItem = selectedNewsItem;
      return (
        <Dialog
          open={true}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <span>Are you sure you want to delete the following news?</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {newsItem.title}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="buttonContainer">
              <IconButton
                onClick={(): void => setShowDeletePrompt(false)}
                iconName="goBack"
                colorSchema={{ foreground: 'black', background: 'white' }}
              />
              <IconButton
                onClick={(): void => {
                  setShowDeletePrompt(false);
                  newsActions.deleteNews(newsItem.id).then((): void => {
                    getNews();
                  });
                }}
                iconName="delete"
                permission={PERMISSION.VP_DELETE_NEWS}
                securityType="disabled"
                colorSchema={{ foreground: 'black', background: 'white' }}
              />
            </div>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  const displayNewsFromReducer = (): ReactNode => {
    const newsInGrid = newsReducer.news.map(
      (news: News): ReactElement => (
        <Grid item xs={12} sm={props.dashboardContext ? 12 : 6} key={news.id}>
          <NewsItem
            news={news}
            deleteHandler={deleteHandler}
            userPermission={user ? user.permissions : null}
            dashboardContext={props.dashboardContext}
            editHandler={openEditNewsMenu}
          />
        </Grid>
      )
    );
    return newsInGrid;
  };

  const renderNews = (): ReactElement => {
    return (
      <div>
        {displayAddNewsButton()}
        <Grid
          container
          spacing={!props.dashboardContext ? 10 : undefined}
          className={!props.dashboardContext ? classes.newsGrid : ''}
        >
          {displayNewsFromReducer()}
        </Grid>
        {!props.dashboardContext && <LoadingDialog loading={loading} />}
        {showDeletePrompt && showDeletePromptDialog()}
      </div>
    );
  };

  useEffect((): void => {
    getNews();
  }, [getNews]);

  return (
    <div>
      {props.dashboardContext && loading ? (
        <div className={classes.loading}>
          <Loading />
        </div>
      ) : (
        renderNews()
      )}
    </div>
  );
};

export default NewsList;
