import { forwardRef, ReactElement } from 'react';

import { Formik, FormikValues, FormikProps } from 'formik';

interface BestFormProps {
  onSubmit: () => void;
  style?: React.CSSProperties;
}

export type BestFormSubmitButton = HTMLButtonElement;
const BestForm = forwardRef<BestFormSubmitButton, React.PropsWithChildren<BestFormProps>>(
  (props: React.PropsWithChildren<BestFormProps>, ref): ReactElement => {
    const initialValues: FormikValues = {};
    return (
      <Formik initialValues={initialValues} onSubmit={props.onSubmit}>
        {(formikProps: FormikProps<unknown>): ReactElement => {
          return (
            <form
              style={props.style}
              onReset={formikProps.handleReset}
              onSubmit={(): void => {
                formikProps.handleSubmit();
              }}
            >
              {props.children}
              <button
                style={{ display: 'none' }}
                type="button"
                ref={ref}
                onClick={(): void => {
                  formikProps.handleSubmit();
                }}
              />
            </form>
          );
        }}
      </Formik>
    );
  }
);

BestForm.displayName = 'BestForm';
export default BestForm;
