import { useCallback } from 'react';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { datadogRum } from '@datadog/browser-rum';
import { HTTP_ERROR_STRINGS } from 'Components/Shared/strings/httpErrors';
import { UnexpectedError } from 'Errors/UnexpectedError';
import { SET_ERROR_MESSAGE } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { BestOneErrorResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';

const useErrorHandler = (): ((error: AxiosError) => Promise<never>) => {
  const { authState, authDispatch } = useAuthState();

  const removeAccessToken = (config?: AxiosRequestConfig): AxiosRequestConfig => {
    if (config?.headers.Authorization) {
      config.headers.Authorization = 'REVOKED';
    }
    return { ...config };
  };

  return useCallback(
    (error: AxiosError) => {
      const errorResponseCode = error.response ? error.response.status : 0;
      const safeError: AxiosError = {
        ...error,
        message: error.message ?? 'no message?',
        config: removeAccessToken(error.config),
      };

      const ddErrorConfig = {
        config: { ...safeError.config },
        user: { ...authState.user },
        request: error.request ? { ...error.request } : 'Nope',
        response: error.response ? { ...error.response } : 'Nope',
      };

      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      let thrownError: AxiosError = {
        code: error.response ? '' + error.response.status : '0',
        ...error,
      };

      //We still want to handle auth errors.
      if (error.config.localErrorHandler && ![401, 403].includes(errorResponseCode)) {
        return Promise.reject(thrownError);
      }

      switch (errorResponseCode) {
        case 401:
        case 403: {
          return Promise.reject(thrownError);
        }
        case 400:
        case 404: {
          return Promise.reject(thrownError);
        }
        case 408:
        case 504: {
          datadogRum?.addError(`TIMEOUT ERROR: ${safeError.message}`, ddErrorConfig);
          authDispatch({
            type: SET_ERROR_MESSAGE,
            errorMessage: HTTP_ERROR_STRINGS.serverBusy(errorResponseCode),
          });
          break;
        }
        /** In theory, only 500 should be an unexpected error */
        case 500: {
          const bestOneError = error as AxiosError<BestOneErrorResponse>;
          datadogRum?.addError(
            `INTERNAL SERVER ERROR:   ${
              bestOneError.response?.data?.message ?? safeError.message
            } (${error.response?.config.url})`,
            ddErrorConfig
          );
          thrownError = new UnexpectedError(error);

          break;
        }
        default: {
          const bestOneError = error as AxiosError<BestOneErrorResponse>;
          datadogRum?.addError(
            `${error.response?.config.url} ${error.code ?? safeError.code} UNHANDLED ERROR: ${
              bestOneError.response?.data?.message ?? error.message ?? safeError.message
            }`,
            ddErrorConfig
          );
          break;
        }
      }
      return Promise.reject(thrownError);
    },
    [authDispatch, authState.user]
  );
};

export default useErrorHandler;
