import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { useGetClaimDetails } from 'Claim/_services/hooks/claims/getClaimDetails';
import { Claim, ClaimDetail, ClaimState } from 'Claim/_types/claims';
import ActionBar from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/ActionBar';
import ClaimCommentTable from 'Claim/Claims/ClaimGroups/ExpandedContent/Table/ClaimCommentTable';
import ClaimDetailsFooter from 'Claim/Claims/ClaimGroups/ExpandedContent/Table/claimDetailsFooter';
import ExpandedClaimLines from 'Claim/Claims/ClaimGroups/ExpandedContent/Table/ClaimGroupLine';
import { CLAIM_ADD_DETAILS_TO_GROUP } from 'Claim/Claims/context/ClaimGroupsActions';
import { useClaimGroupsState } from 'Claim/Claims/context/ClaimGroupsReducer';

interface Props {
  row: Claim;
}

const ClaimGroupDetail = (props: Props) => {
  const [loading, error, data] = useGetClaimDetails(props.row.claim.id);
  const { state, dispatch } = useClaimGroupsState();
  const [status, setStatus] = useState<ClaimState | undefined>(props.row.status);
  const [groupedDetails, setGroupedDetails] = useState<ClaimDetail | undefined>(data);

  useEffect(() => {
    if (loading || error || !data) {
      return;
    }
    dispatch({
      type: CLAIM_ADD_DETAILS_TO_GROUP,
      id: props.row.claim.id ?? 0,
      details: data,
    });
  }, [data, dispatch, props.row.claim.id, loading, error]);

  useEffect(() => {
    if (!state.groups) {
      return;
    }
    const updatedGroup = state.groups.find((group) => group.group.claim.id === props.row.claim.id);
    if (!updatedGroup) {
      return;
    }
    if (updatedGroup.details !== groupedDetails) {
      setGroupedDetails(updatedGroup.details);
    }
    if (updatedGroup.group.status !== status) {
      setStatus(updatedGroup.group.status);
    }
  }, [state.groups, props.row.claim.id, status, groupedDetails]);

  if (loading) {
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        columnGap={1}
        alignItems="center"
        p={1.5}
      >
        <Loading message="Loading..." />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Typography color="error">Error fetching Claim Details: {error.message}</Typography>
      </Box>
    );
  }

  if (!data?.lines) {
    return (
      <Box>
        <Typography>No claim details found</Typography>
      </Box>
    );
  }

  return (
    <Box p={1}>
      <ExpandedClaimLines claimDetail={data} />
      <Box mt={4} mb={4}>
        <ClaimCommentTable claimDetail={groupedDetails} />
      </Box>
      <Grid item display="flex" justifyContent="end" alignItems="center" columnGap={3} pb={1}>
        <ClaimDetailsFooter claimAmount={props.row.claimAmount} totalAmount={data.orderTotal} />
        <Box>
          <ActionBar
            claimId={props.row.claim.id}
            status={status}
            creditNoteNumber={props?.row?.creditNoteNumber}
            closed={props?.row?.closed}
            canConfirmOrDecline={data?.canConfirmOrDecline}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ClaimGroupDetail;
