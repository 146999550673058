import { claimBaseUrl, GetClaimConfig } from 'Claim/_services/claimService';
import { ClaimDetail } from 'Claim/_types/claims';
import { useBestApi, UseBestApiTuple } from 'Components/Hooks/useBestApi';

const GET_CLAIM = (claimId: number) => claimBaseUrl(`claims/${claimId}/details`);

export const useGetClaimDetails = (
  claimId: number
): UseBestApiTuple<ClaimDetail, GetClaimConfig> => {
  return useBestApi<ClaimDetail, GetClaimConfig>(GET_CLAIM(claimId), {
    headers: {
      'CLAIMS-API-VERSION': '1.0',
    },
    method: 'GET',
  });
};
