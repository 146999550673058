import React, { ReactElement, useMemo } from 'react';

import { ArrowDownward, Check } from '@mui/icons-material';
import {
  Badge,
  TableSortLabel,
  Typography,
  Theme,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItem,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MuiTableCell from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { SortDirection } from 'Components/Shared/Table/_types_/TableHeaderType';
import { DragOver } from 'Components/Table/Components/Helpers/useDraggable';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import { removeUndefinedFromArray } from 'Helpers/util';

import SortIcon from '../../Shared/Icons/SortIcon/index';
import { TableColumn } from '../state/_types_/TableColumn';
import { TableRecord } from '../state/_types_/TableRecord';
import { doSetTableRefetch } from '../state/actions/setShouldRefetch';
import { useTableState } from '../state/useTableState';

const ASCENDING = 'ASC';
const DESCENDING = 'DESC';

enum SortDirectionEnum {
  'ASC' = 'asc',
  'DESC' = 'desc',
}

const useStyles = makeStyles((theme: Theme) => ({
  unsortedIcon: {
    color: `${theme.palette.grey[500]} !important`,
  },
  active: {
    color: `${theme.palette.info.dark} !important`,
  },
  stickyColumn: {
    zIndex: 10,
  },
  badge: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
}));

interface Props<T extends TableRecord> {
  column: TableColumn<T>;
  id: string;
  onDragStart: (e: React.DragEvent<HTMLTableCellElement>, id: string) => void;
  onDragOver: (e: React.DragEvent<HTMLTableCellElement>, id: string) => void;
  onDrop: (e: React.DragEvent<HTMLTableCellElement>, id: string) => void;
  onDragEnter: (id: string) => void;
  onDragEnd: () => void;
  dragOver: DragOver | undefined;
  dragging: boolean;
}

const TableHeaderCell = <T extends TableRecord>({
  column,
  id,
  dragOver,
  dragging,
  onDragEnter,
  onDragOver,
  onDragStart,
  onDrop,
  onDragEnd,
}: Props<T>): ReactElement => {
  const flags = useFlags();
  const getFeatureFlag = useGetFeatureFlag();
  const { state, dispatch } = useTableState<T>();
  const classes = useStyles();
  const filter = state.filters[column.property];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const currentDirection = useMemo(
    () =>
      state.sort?.find((r) =>
        [column.sort?.sortProperty, column.property]
          .filter(removeUndefinedFromArray)
          .includes(r.property)
      )?.direction,
    [column.property, column.sort?.sortProperty, state.sort]
  );

  const selectSortOrder = (direction: SortDirection) => {
    if (currentDirection === direction) {
      column.sort?.onSortChange(undefined);
    } else {
      column.sort?.onSortChange(direction);
    }

    dispatch(doSetTableRefetch(true));
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cursor = useMemo((): string => {
    if (!flags[getFeatureFlag('draggable-columns')]) {
      return 'auto';
    }
    if (!state.tableOptions.draggable) {
      return 'auto';
    }

    if (dragging) {
      return 'grabbing';
    }
    return 'grab';
  }, [dragging, flags, getFeatureFlag, state.tableOptions.draggable]);

  const draggableBorder = useMemo(
    (): React.CSSProperties => ({
      borderRight:
        !column.fixed && dragOver?.id === column.property && dragOver.dropRight
          ? '1px solid red'
          : 'none',
      borderLeft:
        !column.fixed && dragOver?.id === column.property && !dragOver.dropRight
          ? '1px solid red'
          : 'none',
    }),
    [column.fixed, column.property, dragOver?.dropRight, dragOver?.id]
  );

  if (column.hidden) {
    return <></>;
  }

  return (
    <MuiTableCell
      className={column.sticky ? classes.stickyColumn : ''}
      align={column.number ? 'right' : 'inherit'}
      variant="head"
      draggable={flags[getFeatureFlag('draggable-columns')] && state.tableOptions.draggable}
      onDragStart={(e) => onDragStart(e, column.property)}
      onDragOver={(e) => onDragOver(e, column.property)}
      onDrop={(e) => !column.fixed && onDrop(e, column.property)}
      onDragEnter={() => onDragEnter(column.property)}
      onDragEnd={() => onDragEnd()}
      id={id}
      style={{
        cursor: cursor,
        ...draggableBorder,
      }}
    >
      <Badge
        badgeContent={[filter?.value].flat().filter(removeUndefinedFromArray).length}
        invisible={!filter || [filter?.value].flat().filter(removeUndefinedFromArray).length === 0}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        classes={{ badge: classes.badge }}
      >
        <TableSortLabel
          classes={{
            icon: !currentDirection ? classes.unsortedIcon : classes.active,
          }}
          disabled={!column.sort?.enable}
          active={column.sort?.enable === true}
          hideSortIcon
          direction={SortDirectionEnum[currentDirection ?? DESCENDING]}
          onClick={openMenu}
          color="info"
          IconComponent={currentDirection !== undefined ? ArrowDownward : SortIcon}
        >
          <Typography noWrap>{column.label}</Typography>
        </TableSortLabel>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem disabled>
          <ListItemIcon />
          <ListItemText>Order</ListItemText>
          <ListItemIcon />
        </ListItem>
        <MenuItem
          selected={currentDirection === ASCENDING}
          onClick={() => selectSortOrder(ASCENDING)}
        >
          <ListItemIcon>
            {currentDirection === ASCENDING && (
              <Check fontSize="small" className={classes.active} />
            )}
          </ListItemIcon>
          <ListItemText>Ascending</ListItemText>
          <ListItemIcon />
        </MenuItem>
        <MenuItem
          selected={currentDirection === DESCENDING}
          onClick={() => selectSortOrder(DESCENDING)}
        >
          <ListItemIcon>
            {currentDirection === DESCENDING && (
              <Check fontSize="small" className={classes.active} />
            )}
          </ListItemIcon>
          <ListItemText>Descending</ListItemText>
          <ListItemIcon />
        </MenuItem>
      </Menu>
    </MuiTableCell>
  );
};

export default TableHeaderCell;
