import React from 'react';
import { isIE } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import App from 'App';
import { properties, store } from 'Helpers';
import { LDEvaluationDetail } from 'launchdarkly-js-client-sdk';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as serviceWorker from 'ServiceWorkers';
import { ThemeWrapper } from 'Theme';

import { datadogRum } from '@datadog/browser-rum';
import { IEBlocker } from 'App/IeBlcoker';
import { msalConfig } from 'Config/adAuthConfig';
import AuthenticationWrapper from 'Contexts/adAuthProvider';
import { DataDogRumProvider } from 'Contexts/DataDogRumContext';
import { ldFlagsMapper } from 'FeatureFlags/ldFlagsMapper';

import { GraphQLProvider } from './Contexts/GraphQLProvider';
import { AuthHandlerProvider } from './Hooks/useAuthHandler/AuthHandlerProvider';

import './index.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: properties.ldclientSideID,
    context: {
      kind: 'user',
      anonymous: true,
      key: 'VP-anom',
    },
    options: {
      diagnosticOptOut: true,
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key: string, detail: LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    flags: ldFlagsMapper(),
  });

  //cleanup for old data
  window.localStorage.removeItem('bsauth');
  window.sessionStorage.removeItem('bsauth');

  ReactDOM.render(
    <ThemeWrapper>
      {isIE ? (
        <IEBlocker />
      ) : (
        <AuthHandlerProvider>
          <Router basename={properties.PUBLIC_URL}>
            <Provider store={store}>
              <AuthenticationWrapper config={msalConfig}>
                <DataDogRumProvider>
                  <GraphQLProvider>
                    <LDProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <App />
                      </LocalizationProvider>
                    </LDProvider>
                  </GraphQLProvider>
                </DataDogRumProvider>
              </AuthenticationWrapper>
            </Provider>
          </Router>
        </AuthHandlerProvider>
      )}
    </ThemeWrapper>,

    document.getElementById('root')
  );
})();

serviceWorker.unregister();
