import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';

import qs from 'qs';

import { useQueryParams } from '.';

interface Params {
  [key: string]: number | string | string[] | number[] | null | undefined | boolean;
}

type QueryParams<T extends Params> = Record<keyof T, ReturnedValue<T, keyof T>>;

export type ReturnedQueryParameters<T extends Params> = {
  [Property in keyof T]: {
    value: ReturnedValue<T, Property> | undefined;
    setValue: (val: T[Property] | undefined) => void;
  };
};

type ReturnedValue<T extends Params, S extends keyof T> = T[S] extends unknown[]
  ? string[]
  : string;

export const useMultipleUrlParams = <T extends Params>(obj: T): ReturnedQueryParameters<T> => {
  const queryParams = useQueryParams() as QueryParams<T>;
  const history = useHistory();

  const [value, setValue] = useState<QueryParams<T>>((): QueryParams<T> => {
    const returned: Partial<QueryParams<T>> = {};

    Object.keys(obj).forEach((key: keyof T) => {
      const defaultValue = obj[key];

      const stickyValue = queryParams[key as string];

      returned[key] = (
        Array.isArray(defaultValue)
          ? ([] as string[]).concat(stickyValue ?? defaultValue)
          : stickyValue ?? defaultValue ?? undefined
      ) as ReturnedValue<T, typeof key>;
    });

    return returned as QueryParams<T>;
  });

  const returnedQueryParams: ReturnedQueryParameters<T> = useMemo(() => {
    const def: Partial<ReturnedQueryParameters<T>> = {};

    Object.keys(value).forEach(
      (key: keyof T) =>
        (def[key] = {
          value: value[key] as ReturnedValue<T, typeof key>,
          setValue: (val?: T[keyof T]) =>
            setValue((prev) => {
              return {
                ...prev,
                [key]: val,
              };
            }),
        })
    );
    return def as ReturnedQueryParameters<T>;
  }, [value]);
  useEffect(() => {
    const newLocation = qs.stringify(
      { ...queryParams, ...value },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
      }
    );
    history.push({
      search: newLocation,
    });
  }, [history, queryParams, value]);

  return returnedQueryParams;
};
