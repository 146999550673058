import React, { ReactElement } from 'react';

import { SettingsBackupRestore } from '@mui/icons-material';
import { Paper, Typography, Tooltip, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import TableSearchableSelectFilter from 'Components/Table/Components/TableFilters/TableSearchableSelectFilter';
import {
  SelectOneFilter,
  SelectFilter,
  SearchableSelectFilter,
} from 'Components/Table/state/_types_/TableFilters';

import { TableColumn } from '../../state/_types_/TableColumn';
import { TableRecord } from '../../state/_types_/TableRecord';
import { doClearFilter } from '../../state/actions/clearFilter';
import { useTableState } from '../../state/useTableState';

import TableDateFilter from './TableDateFilter';
import TableSelectFilter from './TableSelectFilter';

const useStyles = (props: { disabled: boolean | undefined }) =>
  makeStyles((theme) => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      margin: 8,
      padding: 8,
      backgroundColor: props.disabled ? theme.palette.grey[200] : undefined,
      cursor: props.disabled ? 'not-allowed' : undefined,
    },
    paperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }));

interface Props<T extends TableRecord> {
  column: TableColumn<T>;
}

const TableFilter = <T extends TableRecord>({ column }: Props<T>): ReactElement => {
  const { dispatch } = useTableState();
  const classes = useStyles({ disabled: column.filter?.disabled?.value })();
  if (!column.filter) {
    return <></>;
  }

  const filterType = () => {
    if (!column.filter) {
      return <></>;
    }
    if (column.filter.type === 'date') {
      return <TableDateFilter column={column} filter={column.filter} />;
    }
    if (['select', 'selectOne'].includes(column.filter.type)) {
      return (
        <TableSelectFilter
          column={column}
          filter={column.filter as SelectOneFilter | SelectFilter}
        />
      );
    }
    if (['searchableSelect'].includes(column.filter.type)) {
      const searchableSelectFilter = column.filter as SearchableSelectFilter;
      return <TableSearchableSelectFilter column={column} filter={searchableSelectFilter} />;
    }
  };

  return (
    <Tooltip title={column.filter.disabled?.value ? column.filter.disabled?.reason ?? '' : ''}>
      <Paper className={classes.paper} elevation={2}>
        <div className={classes.paperHeader}>
          <Typography>{column.label}:</Typography>
          {column.filter?.disabled?.value ? (
            <></>
          ) : (
            <Tooltip title="Reset">
              <IconButton
                onClick={() => {
                  dispatch(doClearFilter(column.property));
                }}
                size="large"
              >
                <SettingsBackupRestore />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {filterType()}
      </Paper>
    </Tooltip>
  );
};

export default TableFilter;
