import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reduxPromise from 'redux-promise';
import thunkMiddleware from 'redux-thunk';

import rootReducer from 'Store/reducers/rootReducer';

import { isDevEnv } from './properties';

export const middlewares = [thunkMiddleware, reduxPromise];

if (isDevEnv) {
  middlewares.push(createLogger());
}

let composeEnhancers = compose;

if (isDevEnv) {
  composeEnhancers = composeWithDevTools({}) || compose;
}
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, enhancer);
