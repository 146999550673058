import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Alert, Grid, Link, Paper, Snackbar, Typography } from '@mui/material';

import { theme } from 'Theme';

import { AD_INVITE_TO, LOGIN_GUIDE_URL } from 'Constants/shared.constants';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import AdSinginButton from 'Views/Login/AdSinginButton/AdSinginButton';

const AdAlreadyLinked = (): ReactElement => {
  const [error, setError] = useState('');
  const history = useHistory();
  const userReducer = useSelector((state: RootReducerState) => state.userReducer);

  const handleOpenLoginGuide = useCallback(() => {
    const newWindow = window.open(LOGIN_GUIDE_URL, '_blank');
    newWindow?.focus();
  }, []);

  const isInvitedStatus = useMemo(() => {
    return (
      userReducer.user?.inviteStatus === 'Invited' ||
      userReducer.user?.inviteStatus === 'Invite Error : '
    );
  }, [userReducer.user?.inviteStatus]);

  return (
    <Grid height="100%" display="flex" justifyContent="center" alignItems="center">
      <Paper
        elevation={0}
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'justify',
          [theme.breakpoints.up('lg')]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        }}
      >
        <Typography fontWeight={700} fontSize={24} color="warning" mb={3}>
          {isInvitedStatus
            ? 'Complete the creation of Microsoft account'
            : 'Sign in with Microsoft already available'}
        </Typography>
        <Typography mb={1}>
          {isInvitedStatus
            ? `We have detected that an invitation has been already sent to ${userReducer.user?.adEmail}.`
            : 'We have detected that your user is already able to access to Vendor Portal using our new Microsoft login system.'}
        </Typography>
        <span>
          {isInvitedStatus ? (
            <>
              <Typography mb={1}>
                <span>
                  Please, check your email and follow the steps to create a new Microsoft login
                  account. For any questions please check the&nbsp;
                </span>
                <Link color="info.main" sx={{ cursor: 'pointer' }} onClick={handleOpenLoginGuide}>
                  login guide
                </Link>
                <span>.</span>
              </Typography>
              <Typography mb={1}>
                <span>Problems accesing your email?&nbsp;</span>
                <Link
                  color="info.main"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(AD_INVITE_TO);
                  }}
                >
                  Use a different e-mail
                </Link>
              </Typography>
            </>
          ) : (
            <>
              <Typography mb={1}>
                Please, click the button below and access to Vendor Portal directly with your
                Microsoft account.
              </Typography>
            </>
          )}
        </span>
        <Typography mb={3}>
          <span>You can also</span>
          <Link
            color="info.main"
            sx={{ cursor: 'pointer', paddingX: 0.5 }}
            onClick={() => {
              history.push('/');
            }}
          >
            click here
          </Link>
          <span>to continue to Vendor Portal without Microsoft account.</span>
        </Typography>
        <AdSinginButton setError={setError} color="primary" goToCredentialsForm={undefined} />
        {error.length > 0 && (
          <Snackbar
            open
            autoHideDuration={6000}
            onClose={() => setError('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={() => setError('')}
              severity="error"
              sx={{ width: '100%' }}
              variant="filled"
            >
              {error}
            </Alert>
          </Snackbar>
        )}
      </Paper>
    </Grid>
  );
};

export default AdAlreadyLinked;
