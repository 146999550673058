import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, CircularProgress, TextField, Typography } from '@mui/material';

import { DialogButtonProps, Dialog } from '@bestseller-bit/frontend-community.components.dialog';
import useCreateCreditNote from 'Claim/_services/hooks/credit-notes/useCreateCreditNote';
import { useClaimNumberValidation } from 'Claim/_services/hooks/credit-notes/useGetCreditNoteCandidate';
import getErrorMessage from 'Components/Hooks/useBestApi/getErrorMessage';
import { validateEmail } from 'Components/Shared/EmailValidator/emailValidator';
import { CREDIT_NOTE_TO } from 'Constants/shared.constants';
import encodeUtf8 from 'Helpers/encodeUtf8';
import { toastActions } from 'Store/actions/toast.actions';

interface Props {
  open: boolean;
  onClose: () => void;
  setShouldFetch?: (value: boolean) => void;
  claimId?: number;
}

interface LocalError {
  claimNumber?: string;
  creditNoteNumber?: string;
}

const CreditNoteCreationModal = ({ open, onClose, setShouldFetch, claimId }: Props) => {
  const [step, setStep] = useState(claimId ? 2 : 1);
  const [claimNumber, setClaimNumber] = useState('');
  const [email, setEmail] = useState('');
  const [validClaimId, setValidClaimId] = useState(claimId);
  const [creditNoteNumber, setCreditNoteNumber] = useState('');
  const [localError, setLocalError] = useState<LocalError | undefined>(undefined);

  const dispatch = useDispatch();
  const history = useHistory();

  const [fetch, { loading: validationLoading, error: validationError, data: validationData }] =
    useClaimNumberValidation({
      claimNumber,
    });

  const [submitCreditNote, { error, loading, data }] = useCreateCreditNote();

  const handleClose = useCallback(() => {
    setStep(claimId ? 2 : 1);
    setClaimNumber('');
    setEmail('');
    setCreditNoteNumber('');
    setLocalError({
      claimNumber: '',
      creditNoteNumber: '',
    });
    onClose();
  }, [onClose, claimId]);

  const handleNext = () => {
    if (claimNumber) {
      fetch();
    }
  };

  const validateCreditNoteNumberLength = (creditNoteNumber: string): boolean => {
    return creditNoteNumber.length <= 13;
  };

  const validateCreditNoteNumberCharacters = (creditNoteNumber: string): boolean => {
    return creditNoteNumber === encodeUtf8(creditNoteNumber);
  };

  useEffect(() => {
    if (validationError) {
      setLocalError((prevError) => ({
        ...prevError,
        claimNumber: getErrorMessage(validationError) || '',
      }));
    }
  }, [validationError]);

  useEffect(() => {
    if (validationData) {
      setValidClaimId(validationData);
      setStep(2);
    }
  }, [validationData]);

  useEffect(() => {
    if (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage && errorMessage.includes('Credit Note N')) {
        setLocalError((prevError) => ({
          ...prevError,
          creditNoteNumber: errorMessage,
        }));
      } else {
        dispatch(toastActions.setErrorMessage(errorMessage || 'Something went wrong'));
        handleClose();
      }
    }
  }, [error, handleClose, dispatch]);

  useEffect(() => {
    if (data) {
      setShouldFetch && setShouldFetch(true);

      if (claimId) {
        history.push(`${CREDIT_NOTE_TO}?creditNoteNumber=${creditNoteNumber}`);
      } else {
        handleClose();
      }
    }
  }, [data, handleClose, setShouldFetch, claimId, history, creditNoteNumber]);

  const handleCreate = async () => {
    setLocalError((prevError) => ({
      ...prevError,
      email: '',
      creditNoteNumber: '',
    }));

    if (
      !validateEmail(email) ||
      !validateCreditNoteNumberLength(creditNoteNumber) ||
      !validateCreditNoteNumberCharacters(creditNoteNumber)
    ) {
      return;
    }

    await submitCreditNote({
      data: {
        claimId: validClaimId,
        email,
        creditNoteNumber,
      },
    });
  };

  const buttonsForDialog: DialogButtonProps[] = [
    {
      label: 'Close',
      onClick: () => handleClose(),
    },
    {
      label: 'Next',
      onClick: handleNext,
      disabled: validationLoading || !claimNumber,
    },
    {
      label: 'Create',
      onClick: handleCreate,
      disabled:
        !email ||
        !creditNoteNumber ||
        loading ||
        !validateEmail(email) ||
        !validateCreditNoteNumberLength(creditNoteNumber) ||
        !validateCreditNoteNumberCharacters(creditNoteNumber) ||
        (localError?.creditNoteNumber?.length ?? 0) > 0,
    },
  ];

  return (
    <Dialog
      title={claimId || step === 2 ? 'Enter Email and Credit Note Number' : 'Enter Claim Number'}
      open={open}
      onClose={handleClose}
      buttons={
        claimId || step === 2
          ? [buttonsForDialog[0], buttonsForDialog[2]]
          : [buttonsForDialog[0], buttonsForDialog[1]]
      }
      width="sm"
      dividers
      sx={{
        '& .MuiDialogTitle-root': {
          fontSize: '16px',
          fontWeight: 'bold',
        },
      }}
    >
      <Box>
        {step === 1 && !claimId && (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Claim Number"
              type="text"
              fullWidth
              placeholder="Fill in Claim Number"
              value={claimNumber}
              onChange={(e) => setClaimNumber(e.target.value)}
              disabled={validationLoading}
            />
            {validationLoading && <CircularProgress size={24} />}
            {localError?.claimNumber && (
              <Typography color="error">{localError?.claimNumber}</Typography>
            )}
          </>
        )}
        {!!(step === 2 || claimId) && (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
            />
            {email && !validateEmail(email) && (
              <Typography color="error">Invalid email format</Typography>
            )}
            <TextField
              margin="dense"
              label="Credit Note No."
              type="text"
              fullWidth
              value={creditNoteNumber}
              onChange={(e) => {
                setCreditNoteNumber(e.target.value);
                if (!validateCreditNoteNumberLength(e.target.value)) {
                  setLocalError((prevError) => ({
                    ...prevError,
                    creditNoteNumber: `Invalid Credit Note Number "${e.target.value}".It must be 13 characters or less.`,
                  }));
                } else {
                  if (!validateCreditNoteNumberCharacters(e.target.value)) {
                    setLocalError((prevError) => ({
                      ...prevError,
                      creditNoteNumber: `Your input contains characters that aren't recognized. Please use only characters from a standard English keyboard and avoid using special versions of dashes or quotation marks.`,
                    }));
                    return;
                  }
                  setLocalError((prevError) => ({
                    ...prevError,
                    creditNoteNumber: '',
                  }));
                }
              }}
              disabled={loading}
            />
            {loading && <CircularProgress size={24} />}
            {localError?.creditNoteNumber && (
              <Typography color="error">{localError.creditNoteNumber}</Typography>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default CreditNoteCreationModal;
