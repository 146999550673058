import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import { FAST_CONFIRM_TO } from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';

import { RouteConfig } from '.';

const FastConfirm = lazyImport(() => import('Views/FastConfirm'));

export const FAST_CONFIRM_ROUTES: RouteConfig = {
  label: 'Fast Confirm',
  to: FAST_CONFIRM_TO,
  icon: getIconByIconName('fastConfirm'),
  color: '#8d2926',
  component: FastConfirm,
  permission: PERMISSION.VP_ORDER_CONFIRMATION,
};
