import { AxiosError } from 'axios';

const getErrorMessage = (error: Error | AxiosError | undefined): string => {
  const axiosError = error as AxiosError;
  return (
    error !== undefined &&
    (axiosError?.response?.data.message ?? axiosError.message ?? error?.message ?? 'Unknown error')
  );
};

export default getErrorMessage;
