
import { v4 as uuidv4 } from 'uuid';

import InvoiceNumberEntity from 'Invoice/SampleProforma/_models/Entities/InvoiceNumberEntity';
import MissingParentValueError from 'Invoice/SampleProforma/_models/Errors/MissingParentValueError';
import BrandValueObject from 'Invoice/SampleProforma/_models/ValueObjects/BrandValueObject';
import BuyerContactValueObject from 'Invoice/SampleProforma/_models/ValueObjects/BuyerValueObject';
import CarrierValueObject from 'Invoice/SampleProforma/_models/ValueObjects/CarrierValueObject';
import CurrencyValueObject from 'Invoice/SampleProforma/_models/ValueObjects/CurrencyValueObject';
import FactoryValueObject from 'Invoice/SampleProforma/_models/ValueObjects/FactoryValueObject';
import IncotermsValueObject from 'Invoice/SampleProforma/_models/ValueObjects/IncotermsValueObject';
import OriginCriterionValueObject from 'Invoice/SampleProforma/_models/ValueObjects/OriginCriterionValueObject';
import PlaceOfDeliveryValueObject from 'Invoice/SampleProforma/_models/ValueObjects/PlaceOfDeliveryValueObject';
import SupplierValueObject from 'Invoice/SampleProforma/_models/ValueObjects/SupplierValueObject';

class HeaderEntity {
  private readonly id: string;
  private errors: string[];
  private brand: BrandValueObject | null = null;
  private factory: FactoryValueObject | null = null;
  private buyerContact: BuyerContactValueObject | null = null;
  private placeOfDelivery: PlaceOfDeliveryValueObject | null = null;
  private supplier: SupplierValueObject | null = null;
  private currency: CurrencyValueObject | null = null;
  private incoterms: IncotermsValueObject | null = null;
  private invoiceNumber: InvoiceNumberEntity;
  private originCriterion: OriginCriterionValueObject | null = null;
  private carrier: CarrierValueObject | null = null;

  constructor() {
    this.id = uuidv4();
    this.errors = [];
    this.invoiceNumber = new InvoiceNumberEntity(this.supplier === null);
  }

  setInvoiceNumber(invoiceNumberValueObject: string | undefined): this {
    this.invoiceNumber.setValue(invoiceNumberValueObject);
    return this;
  }

  getInvoiceNumber(): InvoiceNumberEntity {
    if (this.supplier === null) {
      throw new MissingParentValueError('Please select a supplier');
    }
    return this.invoiceNumber;
  }

  setBrand(brand: BrandValueObject | undefined): this {
    this.brand = brand ?? null;
    if (this.buyerContact === null) {
      this.errors.push('Set Buyer');
    }
    if (this.placeOfDelivery === null) {
      this.errors.push('Set Place Of Delivery');
    }
    if (this.carrier === null) {
      this.errors.push('Set Carrier');
    }
    return this;
  }
  getBrand(): BrandValueObject | undefined {
    return this.brand ?? undefined;
  }
  getMissingFieldsDependantOnBrand(): string[] {
    if (this.brand === null) {
      return [];
    }
    const errorsBlocking: string[] = [];
    if (this.buyerContact === null) {
      errorsBlocking.push('buyer');
    }
    if (this.placeOfDelivery === null) {
      errorsBlocking.push('place of delivery');
    }
    if (this.carrier === null) {
      errorsBlocking.push('carrier');
    }
    if (this.incoterms === null) {
      errorsBlocking.push('incoterms');
    }
    return errorsBlocking;
  }

  setBuyerContact(
    id: number | undefined,
    name: string | undefined,
    email: string | undefined
  ): this {
    try {
      this.buyerContact = new BuyerContactValueObject(id, name, email);
    } catch {
      this.buyerContact = null;
    }
    return this;
  }

  updateBuyerContact(buyer: BuyerContactValueObject | undefined): this {
    this.buyerContact = buyer ?? null;
    return this;
  }

  getBuyerContact(): BuyerContactValueObject {
    if (this.buyerContact === null) {
      throw new Error('Buyer Contact is missing');
    }
    return this.buyerContact;
  }

  setPlaceOfDelivery(placeOfDelivery: PlaceOfDeliveryValueObject | undefined): this {
    this.placeOfDelivery = placeOfDelivery ?? null;
    return this;
  }
  getPlaceOfDelivery(): PlaceOfDeliveryValueObject {
    if (this.placeOfDelivery === null) {
      throw new Error('Place of Delivery is missing');
    }
    return this.placeOfDelivery;
  }

  setSupplier(supplier: SupplierValueObject | undefined): this {
    this.supplier = supplier ?? null;
    if (this.brand === null) {
      this.errors.push('Set Brand');
    }
    if (this.factory === null) {
      this.errors.push('Set Factory');
    }
    if (this.originCriterion === null) {
      this.errors.push('Set Origin Criterion');
    }
    return this;
  }
  getSupplier(): SupplierValueObject | undefined {
    return this.supplier ?? undefined;
  }
  getMissingFieldsDependantOnSupplier(): string[] {
    if (this.supplier === null) {
      return [];
    }
    const errorsBlocking: string[] = [];
    if (this.brand === null) {
      errorsBlocking.push('brand');
    }
    if (this.factory === null) {
      errorsBlocking.push('factory');
    }
    if (this.originCriterion === null) {
      errorsBlocking.push('origin criterion');
    }
    return errorsBlocking;
  }

  setFactory(factory: FactoryValueObject | undefined): this {
    this.factory = factory ?? null;
    return this;
  }
  getFactory(): FactoryValueObject {
    if (this.factory === null) {
      throw new Error('Factory is missing');
    }
    return this.factory;
  }

  setOriginCriterion(originCriterion: OriginCriterionValueObject | undefined): this {
    this.originCriterion = originCriterion ?? null;
    return this;
  }
  getOriginCriterion(): OriginCriterionValueObject {
    if (this.originCriterion === null) {
      throw new Error('Origin Criterion is missing');
    }
    return this.originCriterion;
  }

  setCurrency(currency: CurrencyValueObject | undefined): this {
    this.currency = currency ?? null;
    return this;
  }
  getCurrency(): CurrencyValueObject {
    if (this.currency === null) {
      throw new Error('Currency is missing');
    }
    return this.currency;
  }

  setIncoterms(incoterms: IncotermsValueObject | undefined): this {
    this.incoterms = incoterms ?? null;
    return this;
  }
  getIncoterms(): IncotermsValueObject {
    if (this.incoterms === null) {
      throw new Error('Incoterms is missing');
    }
    return this.incoterms;
  }

  setCarrier(carrier: CarrierValueObject | undefined): this {
    this.carrier = carrier ?? null;
    return this;
  }
  getCarrier(): CarrierValueObject {
    if (this.carrier === null) {
      throw new Error('Carrier is missing');
    }
    return this.carrier;
  }

  setDefaultValuesBasedOnStyle(
    brand: BrandValueObject | undefined,
    supplier: SupplierValueObject | undefined
  ): this {
    this.brand = brand ?? null;
    this.supplier = supplier ?? null;
    return this;
  }

  setDefaultValuesBasedOnOrder(
    brand: BrandValueObject | undefined,
    supplier: SupplierValueObject | undefined,
    factory: FactoryValueObject | undefined,
    buyer:
      | { id: number | undefined; name: string | undefined; email: string | undefined }
      | undefined,
    placeOfDelivery: PlaceOfDeliveryValueObject | undefined,
    currency: CurrencyValueObject | undefined
  ): this {
    this.brand = brand ?? null;
    this.supplier = supplier ?? null;
    this.factory = factory ?? null;
    this.setBuyerContact(buyer?.id, buyer?.name, buyer?.email);
    this.placeOfDelivery = placeOfDelivery ?? null;
    this.currency = currency ?? null;
    return this;
  }

  setErrors(errors: string[]): void {
    this.errors = errors;
  }
  getErrors(): string[] {
    return this.errors;
  }

  getErrorsToAddNewLine(): string[] {
    const errorsBlocking = [];
    if (this.brand === null) {
      errorsBlocking.push('Brand is missing');
    }
    if (this.supplier === null) {
      errorsBlocking.push('Supplier is missing');
    }
    if (this.factory === null) {
      errorsBlocking.push('Factory is missing');
    }
    if (this.placeOfDelivery === null) {
      errorsBlocking.push('Place of delivery is missing');
    }

    return errorsBlocking;
  }

  getErrorsToCreateInvoice(): string[] {
    const errorsBlocking = this.getErrorsToAddNewLine();
    try {
      this.invoiceNumber.validate();
    } catch (e) {
      errorsBlocking.push((e as Error)?.message ?? 'Unknown error');
    }
    if (this.buyerContact === null) {
      errorsBlocking.push('Buyer Contact is missing');
    }
    if (this.currency === null) {
      errorsBlocking.push('Currency is missing');
    }
    if (this.incoterms === null) {
      errorsBlocking.push('Incoterms is missing');
    }
    if (this.originCriterion === null) {
      errorsBlocking.push('Origin criterion is missing');
    }
    if (this.carrier === null) {
      errorsBlocking.push('Carrier is missing');
    }
    return errorsBlocking;
  }

  update(newHeader: HeaderEntity | undefined): HeaderEntity {
    if (newHeader === undefined) {
      return new HeaderEntity();
    }

    this.updateProperty(
      () => newHeader.getBrand(),
      (value) => this.setBrand(value)
    );
    this.updateProperty(
      () => newHeader.getSupplier(),
      (value) => this.setSupplier(value)
    );
    this.updateProperty(
      () => newHeader.getFactory(),
      (value) => this.setFactory(value)
    );
    this.updateProperty(
      () => newHeader.getBuyerContact(),
      (value) => this.setBuyerContact(value?.getId(), value?.getName(), value?.getEmail())
    );
    this.updateProperty(
      () => newHeader.getPlaceOfDelivery(),
      (value) => this.setPlaceOfDelivery(value)
    );
    this.updateProperty(
      () => newHeader.getCurrency(),
      (value) => this.setCurrency(value)
    );

    return this;
  }

  private updateProperty<T>(
    getter: () => T | undefined,
    setter: (value: T | undefined) => void
  ): void {
    let newValue;
    try {
      newValue = getter();
    } catch {
      newValue = undefined;
    }
    if (newValue !== undefined) {
      setter(newValue);
    }
  }

  isSomethingMissingInHeader(): boolean {
    return this.getErrorsToCreateInvoice().length > 0;
  }
}

export default HeaderEntity;
