import { AxiosError } from 'axios';

import { CustomError } from 'Errors/CustomError';

export class UnexpectedError extends CustomError {
  public static CODE = 500;

  constructor(error: AxiosError, message?: string) {
    super(message ?? 'Something unexpected happened. Try again in a minute.');
    this.name = 'UnexpectedError';
    /** If this is not provided, the error will not be correctly thrown */
    this.config = error.config;
    this.request = error.request;
    this.response = error.response;
  }
}
