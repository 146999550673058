import { Key, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import axios, { AxiosResponse, AxiosError } from 'axios';
import FileSaver from 'file-saver';
import qs from 'qs';

import { datadogRum } from '@datadog/browser-rum';
import DateFnsUtils from '@date-io/date-fns';
import { ServerBrandWithProductLines } from '_types_/MasterData/MasterDataType';
import { SortDirection } from 'Components/Shared/Table/_types_/TableHeaderType';
import { rowsWithId, NO_COLOUR, NO_STYLE_VARIANT } from 'Constants/packingNote.constants';
import { orderTypeMapper } from 'Helpers/OrderTypeMapper';
import { properties } from 'Helpers/properties';
import { getEnumKeyByEnumValue, removeUndefinedFromArray } from 'Helpers/util';
import { OrderGroupCompaniesType } from 'Models/Orders/_types_/OrderGroupCompaniesType';
import { OrderGroupeType } from 'Models/Orders/_types_/OrderGroupeType';
import { OrderSearchPayload } from 'Models/Orders/_types_/OrderSearchType';
import { OrdersEndpoint } from 'Models/Orders/_types_/OrdersEndpoint';
import { OrderStateEnum } from 'Models/Orders/_types_/OrderStateType';
import {
  ServerResponseOrder,
  OrderType,
  ServerOrderType,
  OrderMetaType,
  OrderData,
  ServerOrderHeaderType,
  OrderHeaderType,
  OrderDetails,
  ServeOrderDetails,
  orderPrintType,
} from 'Models/Orders/_types_/OrderType';
import {
  ServerStickerType,
  StickerType,
  PrinterTypeValue,
  printerTypeEnum,
} from 'Models/Orders/_types_/StickerType';
import {
  ServerOrderGroupStylesType,
  OrderGroupStylesType,
} from 'Models/Styles/_types_/OrderGroupStylesType';
import { Order } from 'Orders/_types_/Order';
import { TotalContentType } from 'PackingNotes/_types_/TotalContentType';
import fileResMapper, { useMapError } from 'Services/ext.service/helpers/fileResMapper';
import {
  BestoneResponse,
  LocalBestoneFileResponse,
} from 'Services/ext.service/interfaces/BestoneFileResponse';
import { ordersErrorResponse } from 'Services/orders.service/errorResponse';
import { toastActions } from 'Store/actions/toast.actions';
import {
  ServerOrderGroupOrder,
  ServerGroupOrderOrder,
  GroupOrderOrder,
  MultiConfirmRequest,
  MultiConfirmResponse,
  ServerMultiConfirmResponse,
  ServerMultiConfirmFailedOrder,
  MultiConfirmFailedOrder,
} from 'Views/FastConfirm/_types_';
import { ShadowElements } from 'Views/FastConfirm/_types_/ShadowElements';
import { FastConfirmData } from 'Views/FastConfirm/Orders/FastConfirmTable/FastConfirmTypes';

export interface PrintErrorDetails {
  message: string;
  errorValues: string[];
}

export interface PrintPayload {
  data: Blob | undefined;
  errorDetails: PrintErrorDetails[];
}

const printerTypeValueMapper = (value: printerTypeEnum): string => {
  switch (value) {
    case 'LABEL': {
      return 'Label printer';
    }

    case 'PAPER': {
      return 'Paper printer';
    }
  }
};

const branchNumber = sessionStorage.getItem('ordersBranch')
  ? sessionStorage.getItem('ordersBranch') + '/'
  : '';

axios.defaults.paramsSerializer = (params: any): string => {
  return qs.stringify(params, { indices: false, skipNulls: true }); // param=value1&param=value2
};

export const FETCH_ORDER_PRINT = (): string =>
  properties.serviceApiURL + branchNumber + `orders/orders/print`;
export const FETCH_EXCEL_ORDER_PRINT = (): string =>
  properties.serviceApiURL + branchNumber + `orders/print/excel`;
export const FETCH_ORDERS = (endpoint: OrdersEndpoint): string =>
  properties.serviceApiURL + branchNumber + `orders/${endpoint}`;
export const FETCH_ORDERS_BY_ID = (orderId: number): string =>
  properties.serviceApiURL + branchNumber + `orders/orders/${orderId}`;

export const FETCH_ORDER_PRINT_EXCEL = (): string =>
  properties.serviceApiURL + branchNumber + `orders/docs-orderprint`;

export const FETCH_ORDER_DETAILS_BY_ID = (orderId: number): string =>
  properties.serviceApiURL + branchNumber + `orders/orderdetails/${orderId}`;

export const PUT_ORDER = (orderId: number): string =>
  properties.serviceApiURL + branchNumber + `orders/orders/${orderId}`;

export const FETCH_ORDER_GROUP_STYLES =
  properties.serviceApiURL + branchNumber + 'orders/ordergroups-styles';

export const FETCH_ORDER_GROUPS = (): string =>
  properties.serviceApiURL + branchNumber + `orders/ordergroups`;

export const FETCH_ORDER_GROUPS_DETAILS = (orderGroupId: string): string =>
  properties.serviceApiURL + branchNumber + `orders/ordergroupdetails?orderGroupId=${orderGroupId}`;

export const FETCH_ORDER_GROUPS_FILTERS: string =
  properties.serviceApiURL + branchNumber + `orders/ordergroup-productlines`;

export const MULTI_CONFIRM_ORDERS = (): string =>
  properties.serviceApiURL + branchNumber + `orders/orders-multiconfirm`;

export const SAVE_ORDER_GROUP_SUPPLIERS = `
  ${properties.serviceApiURL}${branchNumber}orders/ordergroup-suppliers`;

export const SAMPLE_LABELS = properties.serviceApiURL + branchNumber + `orders/sample-labels`;

export const GET_ORDER_ASSORTMENTS_TOTAL = (orderNumber: string): string =>
  properties.serviceApiURL + branchNumber + `orders/orders-assortments-total/${orderNumber}`;

export const mapServerData = (serverdata: ServerResponseOrder): OrderData => {
  const orders = serverdata.content.map(
    (elem: ServerOrderType): OrderType => orderTypeMapper.ServerOrderTypeToOrderType(elem)
  );

  const meta: OrderMetaType = {
    firstPage: serverdata.first,
    lastPage: serverdata.last,
    currentPage: serverdata.number,
    empty: serverdata.empty,
    pageable: serverdata.pageable,
    sort: serverdata.sort,
  };
  return {
    meta,
    orders,
  };
};

const mapServerDataHeader = (serverdata: ServerOrderHeaderType): OrderHeaderType => {
  return {
    ...serverdata,
    stylePicturesUrl: serverdata.stylePicturesUrl ?? [],
    orderState:
      OrderStateEnum[
        getEnumKeyByEnumValue(OrderStateEnum, serverdata.orderStateName as OrderStateEnum) || 'none'
      ],
    cutOffDate: serverdata.cutOffDate ? new Date(serverdata.cutOffDate) : undefined,
  };
};

export interface SortType {
  field: string;
  direction: SortDirection;
}

export interface DateFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
}

export const useFetchOrderPrint = (): ((
  orderIds: string | string[],
  type: orderPrintType
) => Promise<LocalBestoneFileResponse>) => {
  const mapError = useMapError();

  return (orderIds: string | string[], type: orderPrintType) => {
    const orderIdArray = Array.isArray(orderIds) ? orderIds : [orderIds];

    return axios
      .post(
        FETCH_ORDER_PRINT(),
        { ordersIds: orderIdArray, documentType: 'xlsx' },
        {
          headers: {
            'ORDERS-API-VERSION': '2.0',
          },
        }
      )
      .then((res: AxiosResponse<BestoneResponse<string>>): LocalBestoneFileResponse => {
        return {
          ...fileResMapper.mapResToBlob(orderIdArray, type, res.data),
        };
      })
      .catch((e: AxiosError<BestoneResponse<string>>): LocalBestoneFileResponse => {
        return mapError(e);
      });
  };
};

export const useFetchGenericOrderExcelPrint = (): ((
  orderIds: string | string[],
  type: orderPrintType
) => Promise<LocalBestoneFileResponse>) => {
  const mapError = useMapError();

  return (orderIds: string | string[], type: orderPrintType) => {
    const orderIdArray = Array.isArray(orderIds) ? orderIds : [orderIds];

    return axios
      .post(
        FETCH_EXCEL_ORDER_PRINT(),
        { ordersIds: orderIdArray },
        {
          headers: {
            'ORDERS-API-VERSION': '2.0',
          },
        }
      )
      .then((res: AxiosResponse<BestoneResponse<string>>): LocalBestoneFileResponse => {
        return {
          ...fileResMapper.mapResToBlob(orderIdArray, type, res.data),
        };
      })
      .catch((e: AxiosError<BestoneResponse<string>>): LocalBestoneFileResponse => {
        return mapError(e);
      });
  };
};

export const useFetchSpecificOverviewOrderExcelPrint = (): ((
  orderIds: string | string[],
  type: orderPrintType
) => Promise<LocalBestoneFileResponse>) => {
  const mapError = useMapError();

  return (orderIds: string | string[], type: orderPrintType) => {
    const orderIdArray = Array.isArray(orderIds) ? orderIds : [orderIds];

    return axios
      .post(
        FETCH_ORDER_PRINT_EXCEL(),
        {
          ordersIds: orderIdArray,
        },
        {
          headers: {
            Accept: 'application/json',
            'ORDERS-API-VERSION': '2.1',
          },
        }
      )
      .then((res: AxiosResponse<BestoneResponse<string>>): LocalBestoneFileResponse => {
        return {
          ...fileResMapper.mapResToBlob(orderIdArray, type, res.data),
        };
      })
      .catch((e: AxiosError<BestoneResponse<string>>): LocalBestoneFileResponse => {
        return mapError(e);
      });
  };
};

export const useFetchOrders = (): ((params: {
  endpoint: OrdersEndpoint;
  orderStates: OrderStateEnum[] | OrderStateEnum;
  productLineIds: string[] | null;
  orderType: string[] | string | null;
  page: number | null;
  size: number | null;
  cCDate: DateFilter | null;
  searchParam: OrderSearchPayload | null;
  sort: SortType | null;
  view?: string | null;
  packingNoteFilter: boolean | null;
  pnSupport: 'Y' | null;
}) => Promise<OrderData>) => {
  const reduxDispatch = useDispatch();

  return useCallback(
    (params: {
      endpoint: OrdersEndpoint;
      orderStates: OrderStateEnum[] | OrderStateEnum;
      productLineIds: string[] | null;
      orderType: string[] | string | null;
      page: number | null;
      size: number | null;
      cCDate: DateFilter | null;
      searchParam: OrderSearchPayload | null;
      sort: SortType | null;
      view?: string | null;
      packingNoteFilter: boolean | null;
      pnSupport: 'Y' | null;
    }) => {
      const {
        endpoint,
        orderStates,

        productLineIds,
        orderType,
        page,
        size,
        cCDate,

        sort,
        view,
        packingNoteFilter,
        pnSupport,
      } = params;

      const orderStatesAsServerWantsIt = [orderStates].flat().filter((x: OrderStateEnum):
        | string
        | null => {
        return x !== OrderStateEnum.none ? x : null;
      });

      return axios
        .get(FETCH_ORDERS(endpoint), {
          params: {
            withPackingNotes: packingNoteFilter,
            orderState: orderStatesAsServerWantsIt,
            orderTypeName: [orderType].flat(),
            productLineNumber: productLineIds,
            size: size,
            ccDateFrom:
              cCDate && cCDate.dateFrom
                ? new DateFnsUtils().format(cCDate.dateFrom, 'yyyy-MM-dd')
                : null,
            ccDateTo:
              cCDate && cCDate.dateTo
                ? new DateFnsUtils().format(cCDate.dateTo, 'yyyy-MM-dd')
                : null,
            sort: sort ? `${sort.field}.${sort.direction.toUpperCase()}` : null,
            view: view ?? null,
            page: page,
            pnSupport,
          },
          headers: {
            'ORDERS-API-VERSION': '2.0',
          },
        })
        .then((response: AxiosResponse<ServerResponseOrder>): OrderData => {
          if (response.data) {
            return mapServerData(response.data);
          }
          reduxDispatch(toastActions.setErrorMessage('Error fetching orders'));
          return ordersErrorResponse;
        })
        .catch((e: AxiosError) => {
          datadogRum.addError(`useFetchOrders error : ${e.response?.data.message ?? e.message}`);
          reduxDispatch(toastActions.setErrorMessage('Error fetching orders'));
          return ordersErrorResponse;
        });
    },
    [reduxDispatch]
  );
};

function fetchOrderHeader(ordersId: number): Promise<OrderHeaderType> {
  return axios
    .get(FETCH_ORDERS_BY_ID(ordersId), {
      headers: {
        'ORDERS-API-VERSION': '1.1',
      },
    })
    .then(
      (response: AxiosResponse<ServerOrderHeaderType>): OrderHeaderType =>
        mapServerDataHeader(response.data)
    );
}

const getStyleAttachmentsById = (
  styleAttachmentsId: number,
  fileName: string,
  format: string
): Promise<number> => {
  return axios({
    url: `${properties.serviceApiURL}orders/styleattachments/${styleAttachmentsId}`,
    method: 'get',
    responseType: 'blob',
    headers: {
      Accept: 'application/octet-stream',
      'STYLES-API-VERSION': '1.0',
    },
  }).then((response: AxiosResponse): number => {
    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    FileSaver.saveAs(blob, `${fileName}.${format}`);
    return response.status;
  });
};

const fetchOrderGroups = (): Promise<OrderGroupeType[]> => {
  return axios
    .get(FETCH_ORDER_GROUPS(), {
      headers: {
        'MULTI-CONFIRM-API-VERSION': '2.0',
      },
    })
    .then((response: AxiosResponse<OrderGroupeType[]>): OrderGroupeType[] => {
      return response.data;
    });
};

const saveOrderGroupSuppliers = (
  selectedOrders: ShadowElements,
  apiVersion = '1.0'
): Promise<void> => {
  return axios.put(
    SAVE_ORDER_GROUP_SUPPLIERS,
    Object.keys(selectedOrders).map((key: string): OrderGroupCompaniesType | null => {
      const elem = selectedOrders[key];
      if (
        (!elem.isSampleOrder && !elem.barcodeId) ||
        (!elem.isSampleOrder && !elem.carelabelId) ||
        (!elem.isSampleOrder && !elem.merchandiserOfficeNumber && apiVersion !== '1.0') ||
        !elem.factoryId
      ) {
        return null;
      }
      return {
        barcodeSupplierNumber:
          !elem.isSampleOrder && elem.barcodeId ? parseInt(elem.barcodeId) : null,
        careLabelSupplierNumber:
          !elem.isSampleOrder && elem.carelabelId ? parseInt(elem.carelabelId) : null,
        factoryNumber: parseInt(elem.factoryId),
        merchandiserOfficeNumber:
          !elem.isSampleOrder && elem.merchandiserOfficeNumber
            ? parseInt(elem.merchandiserOfficeNumber)
            : null,
        ordersId: elem.orderId,
      };
    }),
    {
      headers: {
        'MULTI-CONFIRM-API-VERSION': apiVersion,
      },
    }
  );
};

const saveOrderGroupSuppliersWithMerchOffcSupport = (
  selectedOrders: ShadowElements
): Promise<void> => {
  return saveOrderGroupSuppliers(selectedOrders, '2.0');
};

const fetchGroupOrders = (
  orderGroupId: string,
  activeBrandsFilters?: Key[]
): Promise<FastConfirmData> => {
  return axios
    .get(FETCH_ORDER_GROUPS_DETAILS(orderGroupId), {
      params: {
        productLineNumber: activeBrandsFilters,
      },
      headers: {
        'MULTI-CONFIRM-API-VERSION': '2.0',
      },
    })
    .then((response: AxiosResponse<ServerOrderGroupOrder>): FastConfirmData => {
      const mapper = (sgoo: ServerGroupOrderOrder): GroupOrderOrder => ({
        ...sgoo,
        readyForLabelProduction: sgoo.readyForLabelProduction === 'Y',
        cargoClosing: sgoo.cargoClosing,
        barcodeSupplierNumber: sgoo.barcodeSupplierNumber ?? undefined,
        careLabelSupplierNumber: sgoo.careLabelSupplierNumber ?? undefined,
        factoryNumber: sgoo.factoryNumber ?? undefined,
      });
      const orderGroupDetailsList = response.data.orderGroupDetailsViewList?.map(mapper);
      return {
        barcodeLabelSuppliers: response.data.barCodeLabelSupplierCompanies,
        careLabelSupplier: response.data.careLabelSupplierCompanies,
        factories: response.data.subSupplierCompanies,
        orderGroupDetailsViewList: [...orderGroupDetailsList],
      };
    });
};

const fetchOrdeGroupFilters = (orderGroupId: string): Promise<ServerBrandWithProductLines[]> => {
  return axios
    .get(FETCH_ORDER_GROUPS_FILTERS, {
      headers: {
        'MULTI-CONFIRM-API-VERSION': '1.0',
      },
      params: { orderGroupId: orderGroupId },
    })
    .then((res: AxiosResponse<ServerBrandWithProductLines[]>): ServerBrandWithProductLines[] => {
      return res.data;
    });
};

const fetchOrderGroupStyles = (ordersIds: number[]): Promise<OrderGroupStylesType[]> => {
  return axios
    .get(FETCH_ORDER_GROUP_STYLES, {
      params: { ordersId: ordersIds },
      headers: {
        'MULTI-CONFIRM-API-VERSION': '1.0',
      },
    })
    .then((response: AxiosResponse<ServerOrderGroupStylesType[]>): OrderGroupStylesType[] => {
      return response.data.map((e: ServerOrderGroupStylesType): OrderGroupStylesType => {
        return {
          ...e,
          readyForLabelProduction: e.readyForLabelProduction === 'Y',
          flammabilityMandatory: e.flammabilityMandatory === 'Y',
        };
      });
    });
};

function multiConfirmOrders(
  multiConfirmRequest: MultiConfirmRequest[]
): Promise<MultiConfirmResponse> {
  const requestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'MULTI-CONFIRM-API-VERSION': '1.1',
    },
  };

  return axios({
    url: MULTI_CONFIRM_ORDERS(),
    method: 'put',
    data: JSON.stringify(multiConfirmRequest),
    headers: requestOptions.headers,
  }).then((res: AxiosResponse<ServerMultiConfirmResponse>): MultiConfirmResponse => {
    const mappedFailed = res.data.failedOrders?.map((x: ServerMultiConfirmFailedOrder):
      | MultiConfirmFailedOrder
      | undefined => {
      try {
        const mappedOrder = x.order as unknown as Order;
        return {
          ...x,
          order: mappedOrder,
        };
      } catch (e) {
        return undefined;
      }
    });
    return {
      ...res.data,
      failedOrders: mappedFailed?.filter(removeUndefinedFromArray),
      confirmedOrders: res.data.confirmedOrders
        ?.map((x: ServerOrderType): Order | undefined => {
          try {
            return x as unknown as Order;
          } catch (e) {
            return undefined;
          }
        })
        .filter(removeUndefinedFromArray),
    };
  });
}

const fetchSampleLabelsDropdownValues = (brandId: number): Promise<StickerType> => {
  return axios
    .get(SAMPLE_LABELS, {
      params: { brandsId: brandId },
      headers: {
        'ORDERS-API-VERSION': '1.0',
      },
    })
    .then(
      (res: AxiosResponse<ServerStickerType>): StickerType => ({
        printerTypeEnums: res.data.printerTypeEnums.map((x): PrinterTypeValue => {
          return { printerTypeEnums: x, name: printerTypeValueMapper(x) };
        }),
        sampleLabels: [...res.data.sampleLabels].sort((a, b): number =>
          a.labelCode > b.labelCode ? 0 : -1
        ),
      })
    );
};

const getOrderAssortmentsTotal = (orderNumber: string): Promise<TotalContentType> => {
  return axios
    .get(GET_ORDER_ASSORTMENTS_TOTAL(orderNumber), {
      headers: { 'ORDERS-API-VERSION': '1.0' },
    })
    .then((res: AxiosResponse<BestoneResponse<TotalContentType>>): TotalContentType => {
      if (res.data.data) {
        return {
          ...res.data.data,
          rows: rowsWithId(
            res.data.data.rows.map((x) => ({
              ...x,
              colour: x.colour ?? NO_COLOUR,
              noColour: x.colour === null,
              styleVariant: x.styleVariant ?? NO_STYLE_VARIANT,
              noStyleVariant: x.styleVariant === null,
            }))
          ),
        };
      }
      throw res.data.errors;
    });
};

const getOrderDetails = (orderId: number, sort?: SortType): Promise<OrderDetails[]> => {
  return axios
    .get(FETCH_ORDER_DETAILS_BY_ID(orderId), {
      params: {
        sort: sort ? `${sort.field}.${sort.direction.toUpperCase()}` : null,
      },
      headers: {
        'ORDERS-API-VERSION': '1.0',
      },
    })
    .then((response: AxiosResponse<ServeOrderDetails[]>): OrderDetails[] => {
      return response.data.map((e: ServeOrderDetails): OrderDetails => {
        return {
          ...e,
          cargoClosingDate: e.cargoClosingDate ? new Date(e.cargoClosingDate) : undefined,
          warehouseEta: e.warehouseEta ? new Date(e.warehouseEta) : undefined,
        };
      });
    });
};

export const ordersService = {
  getOrderDetails,
  fetchOrderHeader,
  fetchOrderGroups,
  saveOrderGroupSuppliers,
  saveOrderGroupSuppliersWithMerchOffcSupport,
  getStyleAttachmentsById,
  fetchOrderGroupStyles,
  fetchGroupOrders,
  multiConfirmOrders,
  fetchOrdeGroupFilters,
  fetchSampleLabelsDropdownValues,
  getOrderAssortmentsTotal,
};
