import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 240;
export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  drawerToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // NOTE: necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  editToolbar: {
    alignContent: 'flex-end',
  },
  spacer: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: '#c7c7c7',
    border: 'none',
  },
}));
