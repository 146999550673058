import { useMemo } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ClaimDetail } from 'Claim/_types/claims';
import { formatSupplierComment } from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/formatSupplierComment';

interface Props {
  claimDetail: ClaimDetail | undefined;
}
const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  tableDivider: {
    borderRight: '1px solid rgba(211, 211, 211, 1)',
    width: '33.33%',
  },
}));
const CommentTable = (props: Props) => {
  const classes = useStyles();

  const supplierComments = formatSupplierComment(props.claimDetail?.supplierComment ?? '');

  const reasons = useMemo(
    () =>
      props.claimDetail?.claimReasons
        ? props.claimDetail?.claimReasons.map((reason) => {
            return <p key={`claim-comment-table-reasons-${reason}`}>{reason}</p>;
          })
        : '',
    [props.claimDetail?.claimReasons]
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="claim comments table">
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell className={classes.tableDivider}>Claim Reasons</TableCell>
            <TableCell className={classes.tableDivider}>General Comment</TableCell>
            <TableCell>Supplier Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableDivider}>{reasons}</TableCell>
            <TableCell className={classes.tableDivider}>
              {props.claimDetail?.generalComment ?? ''}
            </TableCell>
            <TableCell>
              {supplierComments.map((comment, index) => (
                <div key={index}>{comment}</div>
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ClaimCommentTable = (props: Props) => {
  return <Box>{<CommentTable {...props} />}</Box>;
};

export default ClaimCommentTable;
