import { ReactElement, useCallback } from 'react';

import { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import BaseButton, { BaseButtonProps } from 'Components/Shared/BestButton/BaseButton';
import { getIconByIconName } from 'Components/Shared/Icons/Icons';

import { IconButtonColor, IconButtonProps } from './_types_';
import classes from './IconButton.module.scss';

const propsClassTranslator = (color: IconButtonColor): string => {
  const colorSchema: string = color.foreground
    ? color.foreground + '-' + color.background
    : 'black-' + color.background;

  switch (colorSchema) {
    case 'black-grey':
      return classes.blackGrey;
    case 'black-white':
      return classes.blackWhite;
    case 'white-grey':
      return classes.whiteGrey;
    case 'white-black':
      return classes.whiteBlack;
    case 'white-none':
      return classes.whiteNone;
    case 'black-light-grey':
      return classes.blackLightGrey;
    case 'grey-black':
      return classes.greyBlack;
    case 'grey-grey':
      return classes.greyGrey;
    case 'grey-white':
      return classes.greyWhite;
    case 'grey-none':
      return classes.greyNone;
    case 'white-info-blue':
      return classes.lightBlueWhite;
    case 'white-error-red':
      return classes.errorRedWhite;
    case 'white-success-green':
      return classes.successGreenWhite;
    case 'black-orange':
      return classes.orangeBlack;
    case 'white-orange':
      return classes.orangeBlack;
    case 'black-none':
    default:
      return classes.blackNone;
  }
};
const IconButton = (
  props: BaseButtonProps & Omit<ButtonProps, 'component'> & IconButtonProps
): ReactElement => {
  const {
    loading,
    iconName,
    colorSchema,
    textPosition,
    fontSize,
    loadingIconColor,
    loadingIconSize,
    iconPosition,
    component,
    ...rest
  } = props;
  const inlineStyling: React.CSSProperties = {
    fontSize: fontSize ?? null,
  };

  const modifiedDisabled = props.loading ? true : props.disabled;

  const iconComponent = useCallback((): ReactElement => {
    if (loading) {
      return (
        <CircularProgress
          size={loadingIconSize ?? 'small'}
          style={{
            color: loadingIconColor ?? colorSchema?.foreground,
          }}
        />
      );
    }
    return getIconByIconName(iconName);
  }, [colorSchema?.foreground, iconName, loading, loadingIconColor, loadingIconSize]);

  return (
    <BaseButton
      component={component}
      {...rest}
      disableButton={modifiedDisabled}
      className={[
        props.className,
        propsClassTranslator(colorSchema ?? { background: 'none' }),
      ].join(' ')}
    >
      {textPosition === 'bottom' ? (
        <div>
          <div>{iconComponent()}</div> <div style={inlineStyling}>{props.children}</div>
        </div>
      ) : (
        <>
          {iconPosition === 'start' && iconComponent()}
          <span style={inlineStyling}>{props.children}</span>
          {iconPosition === 'end' && iconComponent()}
        </>
      )}
    </BaseButton>
  );
};

export default IconButton;
