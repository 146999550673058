import axios from 'axios';

import { BestoneResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';
import { userAdServiceApiBaseUrl } from 'Views/Login/ChooseAccount/_adLoginServices/adLoginService';
import { ADUserAccount } from 'Views/Login/ChooseAccount/_types/UserAdResponse';

const GET_LINK_ACCOUNTS = userAdServiceApiBaseUrl(`profiles`);

export const getAdUserAccounts = (token: string): Promise<BestoneResponse<ADUserAccount[]>> => {
  const cleanAxios = axios.create();
  return cleanAxios.get(GET_LINK_ACCOUNTS, {
    headers: {
      'USERS-API-VERSION': '2.0',
      authorization: `Bearer ${token}`,
    },
  });
};
