import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { User } from 'Models/User/_types_/User';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

const useDDAuth = (): void => {
  const { authState } = useAuthState();
  const user = useSelector((root: RootReducerState): User | null => root.userReducer.user);
  const tokens = authState.tokens;

  useEffect((): void => {
    if (user && datadogRum.getUser().id !== user.usersId) {
      datadogRum.setUser({
        email: user.email,
        name: user.fullName,
        id: '' + user.usersId,
        loginMethod: tokens?.provider,
        buildTime: __BUILD_DATE__,
        permissions: user.permissions,
      });
      datadogRum.startSessionReplayRecording();
    }
    if (datadogRum.getUser().loginMethod !== tokens?.provider) {
      datadogRum.addAction(`Update user's login method to ` + tokens?.provider);
      datadogRum.setUserProperty('loginMethod', tokens?.provider);
    }
  }, [tokens?.provider, user]);
};

export default useDDAuth;
