import { ReactElement, useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItem, Tooltip, ListItemIcon, ListItemText, IconButton } from '@mui/material';

import navClasses from 'Components/UI/Navigation/Navigation.module.scss';
import { NavigationMenu } from 'Components/UI/Navigation/NavigationMenu';
import CollapsedListItem from 'Components/UI/Navigation/NavigationMenu/CollapsedMenu';
import { useRoutes } from 'Views/routes';


interface ButtonWithNavigationMenuProps {
  routeId: string;
  index: React.Key;
  icon: ReactElement;
  to: string;
  open: boolean;
  color?: string;
  selected: boolean;
}

export const ButtonWithNavigationMenu = ({
  routeId,
  index,
  icon: Icon,
  to,
  open,
  color,
  selected,
}: ButtonWithNavigationMenuProps): ReactElement => {
  const routes = useRoutes();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [closingTimeout, setClosingTimeout] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    setAnchorEl(null);
  }, [open]);

  const handleCloseWithTimeout = () => {
    const timeout = setTimeout(() => {
      handleClose();
    }, 50);

    setClosingTimeout(timeout);
  };

  const handleCancelTimeout = () => {
    if (closingTimeout) {
      clearTimeout(closingTimeout);
    }
  };
  const handleClose = (
    event?:
      | React.FocusEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setAnchorEl(null);
    // NOTE: to stop propagation of the clickEvent to the ListItem component
    event?.stopPropagation();
  };

  const handleClick = (
    event:
      | React.FocusEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    // NOTE: to stop propagation of the clickEvent to the ListItem component
    event.stopPropagation();
  };

  const handleKeyboardEvent = (
    event: React.KeyboardEvent<HTMLDivElement> | React.KeyboardEvent<HTMLButtonElement>
  ): void => setAnchorEl(event.currentTarget);

  const history = useHistory();
  return (
    <div
      {...(!open && {
        onMouseLeave: handleCloseWithTimeout,
        onMouseEnter: handleCancelTimeout,
      })}
    >
      <ListItem
        button
        key={routes[routeId].label}
        {...(to && {
          onClick: () => history.push(to),
        })}
        selected={selected}
        className={[
          open && Boolean(anchorEl) ? navClasses.hasShadow : '',
          navClasses.listItem,
        ].join(' ')}
      >
        <Tooltip title={routes[routeId].label}>
          <ListItemIcon className={navClasses.listItemIcon}>
            <div style={selected ? { color } : {}}>{Icon}</div>
            {!open && (
              <div
                onFocus={handleClick}
                onMouseOver={handleClick}
                onKeyPress={handleKeyboardEvent}
                role="button"
                tabIndex={0}
                className={navClasses.arrowButton}
              >
                <ChevronRight />
              </div>
            )}
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={routes[routeId].label} />
        <ListItemIcon
          tabIndex={0}
          className={[navClasses.listItemIcon, navClasses.listItemArrow].join(' ')}
        >
          <IconButton
            onClick={anchorEl ? handleClose : handleClick}
            onKeyPress={handleKeyboardEvent}
            size="large"
          >
            {anchorEl ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemIcon>
      </ListItem>
      {open ? (
        <CollapsedListItem open={Boolean(anchorEl)} childrenRoutes={routes[routeId].children} />
      ) : (
        <NavigationMenu
          key={`navigation-menu-${routeId}-${index}`}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          handleClose={handleClose}
          childrenRoutes={routes[routeId].children}
        />
      )}
    </div>
  );
};
