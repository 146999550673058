import { ReactElement, PropsWithChildren, useMemo } from 'react';
import { Link as NavLink } from 'react-router-dom';

import { MenuOpen } from '@mui/icons-material';
import {
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';

import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import { useBreadCrumbs } from 'Components/Hooks/useBreadCrumbs';
import navClasses from 'Components/UI/Navigation/Navigation.module.scss';
import { ButtonWithNavigationMenu } from 'Components/UI/Navigation/NavigationMenu/ButtonWithNavigationMenu';
import { useRoutes } from 'Views/routes';

import { useHasPermission } from '../../../../Models/Permission/permissionHelper';

import { useStyles } from './styles';

const NavigationDrawer = ({
  handleDrawerClose,
  open,
}: PropsWithChildren<{
  handleDrawerClose: () => void;
  open: boolean;
}>): ReactElement => {
  const routes = useRoutes();
  const hasPermission = useHasPermission();
  const classes = useStyles();
  const breadCrumbs = useBreadCrumbs();
  const flags = useFlags();
  const isMobile = useIsMobile();

  const openInNewTab = (to: string): void => {
    const newWindow = window.open(to, '_blank');

    newWindow?.focus();
  };
  const permanent = useMemo(() => !isMobile, [isMobile]);

  return (
    <Drawer
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      variant={permanent ? 'permanent' : 'temporary'}
    >
      <div className={classes.drawerToolbar}>
        <IconButton onClick={handleDrawerClose} size="large">
          <MenuOpen />
        </IconButton>
      </div>
      <Divider />
      <List>
        {/* Loops through the routes and checks if it has children that can be displayed. */}
        {Object.keys(routes).map((routeId, index) => {
          const isSelected = breadCrumbs.findIndex((r) => r.label === routes[routeId].label) > -1;

          if (
            !hasPermission(routes[routeId].permission) ||
            routes[routeId].display === false ||
            (!!routes[routeId].featureFlag && !flags[routes[routeId].featureFlag as string])
          ) {
            return null;
          }
          if (
            routes[routeId].children &&
            Object.keys(routes[routeId].children ?? {}).some(
              (r) =>
                routes[routeId].children?.[r].display !== false &&
                !!routes[routeId].children?.[r].to &&
                (routes[routeId].children?.[r].featureFlag
                  ? !!flags[routes[routeId].children?.[r].featureFlag as string]
                  : true)
            )
          ) {
            return (
              <ButtonWithNavigationMenu
                key={`listButtonWithNav-${routeId}-${index}`}
                routeId={routeId}
                index={index}
                icon={routes[routeId].icon}
                to={routes[routeId].to}
                open={open}
                color={routes[routeId].color}
                selected={isSelected}
              />
            );
          }

          const Icon = routes[routeId].icon;
          return (
            <ListItem
              button
              key={routes[routeId].label}
              selected={isSelected}
              className={navClasses.listItem}
              {...(routes[routeId].openInNewTab
                ? {
                    onClick: () => openInNewTab(routes[routeId].to),
                  }
                : {
                    component: NavLink,
                    to: routes[routeId].to,
                  })}
            >
              {routes[routeId].icon && (
                <Tooltip title={routes[routeId].label}>
                  <ListItemIcon
                    style={isSelected ? { color: routes[routeId].color } : {}}
                    className={navClasses.listItemIcon}
                  >
                    {Icon}
                  </ListItemIcon>
                </Tooltip>
              )}
              <ListItemText primary={routes[routeId].label} />
            </ListItem>
          );
        })}
      </List>
      <div className={classes.spacer} />
    </Drawer>
  );
};

export default NavigationDrawer;
