import React, { ReactElement, useState } from 'react';

import {
  Paper,
  TextField,
  Autocomplete,
  Alert,
  Button,
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
  Theme,
  List,
  ListSubheader,
  ListItem,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import axios, { AxiosError } from 'axios';

import DevbarButton from 'Components/UI/Devbar/widgets/DevbarButton';
import { packingNoteBaseUrl } from 'PackingNotes/_services_';
import { TRANSMIT_PACKING_NOTE } from 'PackingNotes/_services_/hooks/useTransmitPackingNote';
const useStyles = makeStyles((theme: Theme) => ({
  buffer: {
    backgroundColor: theme.palette.error.dark,
  },
}));
function LinearProgressWithLabel(
  props: LinearProgressProps & {
    total: number;
    failed: number;
    value: number;
  }
) {
  const classes = useStyles();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="buffer"
          {...props}
          value={(100 / props.total) * props.value}
          valueBuffer={(100 / props.total) * (props.value + props.failed)}
          color="info"
          classes={{
            bar2Buffer: classes.buffer,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.value} / ${props.total}`}</Typography>
      </Box>
    </Box>
  );
}

const PnTransmitter = (): ReactElement => {
  const [value, setValue] = useState<string[]>([]);

  const [selectedPol, setSelectedPol] = useState<string | null>(null);
  const [selectedPns, setSelectedPns] = useState<number[]>([]);
  const [incorrectPns, setIncorrectPns] = useState<string[]>([]);

  const [updatedPns, setUpdatedPns] = useState<number | null>(null);
  const [transmittedPns, setTransmittedPns] = useState<number | null>(null);
  const [updateFailedPns, setUpdateFailedPns] = useState<Record<number, AxiosError>>({});
  const [transmitFailedPns, setTransmitFailedPns] = useState<Record<number, AxiosError>>({});

  const [showErrors, setShowErrors] = useState(false);

  const updatePolForPn = (packingNotesId: number): void => {
    const wat = packingNoteBaseUrl(
      `packing-notes/packing-notes/${packingNotesId}/port-of-loading-without-dt`
    );
    axios(wat, {
      method: 'PATCH',
      data: {
        portOfLoading: selectedPol,
      },
      headers: { 'PACKING-NOTES-API-VERSION': '1.0' },
    })
      .then(() => {
        setUpdatedPns((prev) => (prev === null ? 1 : prev + 1));
        transmitPn(packingNotesId);
      })
      .catch((err) => {
        setUpdateFailedPns((prev) => ({
          ...prev,
          [packingNotesId]: err,
        }));
      });
  };

  const transmitPn = (packingNotesId: number): void => {
    axios(TRANSMIT_PACKING_NOTE(packingNotesId), {
      method: 'PATCH',
      headers: { 'PACKING-NOTES-API-VERSION': '1.0' },
    })
      .then(() => setTransmittedPns((prev) => (prev === null ? 1 : prev + 1)))
      .catch((err) => {
        setTransmitFailedPns((prev) => ({
          ...prev,
          [packingNotesId]: err,
        }));
      });
  };

  const submit = () => {
    setUpdatedPns(0);
    setTransmittedPns(0);
    setUpdateFailedPns({});
    setTransmitFailedPns({});
    selectedPns.forEach((pn) => {
      updatePolForPn(pn);
    });
  };

  return (
    <DevbarButton label="PN tool">
      <Paper
        sx={{
          padding: 4,
        }}
      >
        {incorrectPns.map((r, i) => (
          <Alert key={`incorrect-pnid-${r}-${i}`} severity="error">
            {r}
          </Alert>
        ))}
        <TextField
          rows={6}
          variant="outlined"
          fullWidth
          maxRows={6}
          value={value.join(', \n')}
          onChange={(event) => {
            const pns = event.target.value.split(',');
            setIncorrectPns(pns.filter((r) => isNaN(Number(r))));
            setSelectedPns(
              pns
                .filter((r) => !isNaN(Number(r)))
                .filter((r) => r.trim() !== '')
                .map((r) => Number(r))
            );
            setUpdatedPns(null);
            setTransmittedPns(null);
            setValue(pns);
          }}
        />

        <Autocomplete
          options={[
            'Shanghai',
            'Hongkong',
            'Ningbo',
            'Qingdao',
            'Sihanoukville',
            'Yangon',
            'Yantian',
            'Chittagong',
          ]}
          value={selectedPol}
          onChange={(_event, val) => {
            setSelectedPol(val);
          }}
          renderInput={(params) => <TextField {...params} label="Port Of Loading" />}
        />

        {selectedPns.length > 0 && (
          <Button onClick={submit} variant="contained">
            <span>Update and Retransmit {selectedPns.length} packing note(s)</span>
          </Button>
        )}
        {updatedPns !== null && (
          <LinearProgressWithLabel
            total={selectedPns.length}
            value={updatedPns}
            failed={Object.keys(updateFailedPns).length}
          />
        )}
        {transmittedPns !== null && (
          <LinearProgressWithLabel
            total={selectedPns.length}
            value={transmittedPns}
            failed={Object.keys(transmitFailedPns).length + Object.keys(updateFailedPns).length}
          />
        )}

        {Object.keys({ ...updateFailedPns, ...transmitFailedPns }).length > 0 && (
          <FormControlLabel
            control={<Switch value={showErrors} onClick={() => setShowErrors(!showErrors)} />}
            label="Show Errors"
          />
        )}
        {showErrors && (
          <Alert severity="error">
            <List>
              <ListSubheader> POL Update </ListSubheader>
              {Object.keys(updateFailedPns).map((pnId) => (
                <ListItem key={`updatefailed-${pnId}`}>
                  {pnId} : {updateFailedPns[+pnId].response?.data.message}
                </ListItem>
              ))}
              <ListSubheader> PN Transmit </ListSubheader>
              {Object.keys(transmitFailedPns).map((pnId) => (
                <ListItem key={`transmitFailed-${pnId}`}>
                  {pnId} : {transmitFailedPns[+pnId].response?.data.message}
                </ListItem>
              ))}
            </List>
          </Alert>
        )}
      </Paper>
    </DevbarButton>
  );
};

export default PnTransmitter;
