import { ReactElement, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Check } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import { toastActions } from 'Store/actions/toast.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { ToastState } from 'Store/reducers/toast.reducer';

import classes from './DisplayErrorHandler.module.scss';

const icon = (type?: 'Info' | 'Warning' | 'Error'): ReactElement => {
  switch (type) {
    case 'Info':
      return <Check />;
    case 'Warning':
      return <WarningIcon />;
    case 'Error':
    default:
      return <ErrorIcon />;
  }
};

const style = (type?: 'Info' | 'Warning' | 'Error'): string => {
  switch (type) {
    case 'Info':
      return classes.info;
    case 'Warning':
      return classes.warning;
    case 'Error':
    default:
      return classes.error;
  }
};

const DisplayErrorHandler = (): ReactElement => {
  const dispatch = useDispatch();
  const error = useSelector((root: RootReducerState): ToastState => root.errorReducer);

  const close = useCallback((): void => {
    dispatch(toastActions.clearErrorMessage());
  }, [dispatch]);

  return (
    <>
      <Snackbar
        open={error.message ? true : false}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={error.sticky ? undefined : 5000}
        onClose={(): void => {
          if (!error.sticky) {
            close();
          }
        }}
      >
        <SnackbarContent
          message={
            <div className={classes.container}>
              <div className={classes.iconAndText}>
                <div className={classes.iconContainer}>{icon(error.type)}</div>
                <div className={classes.textContainer}>{error.message}</div>
              </div>
              <div className={classes.closeIcon} onClick={close}>
                <CloseIcon />
              </div>
            </div>
          }
          classes={{ message: classes.snackBar, root: style(error.type) }}
        />
      </Snackbar>
    </>
  );
};
export default DisplayErrorHandler;
