import React, { ReactElement } from 'react';

import { Checkbox, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';

import { TableRecord } from 'Components/Table/state/_types_/TableRecord';

import { TableColumn } from '../../state/_types_/TableColumn';
import { SelectOneFilter, SelectFilter } from '../../state/_types_/TableFilters';
import { doChangeFilter } from '../../state/actions/changeFilter';
import { useTableState } from '../../state/useTableState';

interface Props<T extends TableRecord> {
  filter: SelectFilter | SelectOneFilter;
  column: TableColumn<T>;
}

const TableSelectFilter = <T extends TableRecord>({
  filter: {
    loading,
    elements = [
      {
        title: 'No items available',
        value: -1,
      },
    ],
    ...filter
  },
  column,
}: Props<T>): ReactElement => {
  const { state, dispatch } = useTableState<T>();

  const handleClick = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (filter.type === 'select') {
      dispatch(
        doChangeFilter({
          property: column.property,
          filterType: filter.type,
          value: [...event.target.value],
        })
      );
    } else {
      dispatch(
        doChangeFilter({
          property: column.property,
          filterType: filter.type,
          value: event.target.value,
        })
      );
    }
  };

  const currentFilter = state.filters[column.property];
  return (
    <TextField
      disabled={column.filter?.disabled?.value}
      variant="outlined"
      select
      margin="dense"
      InputProps={{
        endAdornment: column.filter?.loading ? <CircularProgress size={20} /> : undefined,
      }}
      SelectProps={{
        ...(filter.type === 'select' && {
          multiple: filter.type === 'select',
        }),
        renderValue: (selected: unknown) =>
          (
            [selected].flat().map((r) => elements.find((s) => s.value === r)?.title) as string[]
          ).join(', '),
        value: currentFilter?.value ?? (filter.type === 'select' ? [] : -1),
      }}
      onChange={handleClick}
      helperText={column.helperText}
    >
      {elements.map((menuItem, index) => {
        return (
          <MenuItem key={`-${menuItem.title}-${index}`} selected={false} value={menuItem.value}>
            <Checkbox checked={[currentFilter?.value].flat().includes(menuItem.value)} />
            <Typography variant="inherit" noWrap>
              {menuItem.title}
            </Typography>
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default TableSelectFilter;
