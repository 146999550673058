import { Grid } from '@mui/material';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { CanConfirmOrDeclineType } from 'Claim/_types/claims';
import ConfirmButton from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/ConfirmButton';
import CreateCreditNoteFromClaim from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/CreateCreditNoteButton';
import DeclineButton from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/DeclineButton';
import PrintClaim from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/PrintClaim';
import UnconfirmButton from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/UnconfirmButton';
import ViewCreditNoteButton from 'Claim/Claims/ClaimGroups/ExpandedContent/Actions/ViewCreditNoteButton';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';

export const RenderWhenStatusIsConfirmed: React.FC<{
  claimId: number;
  creditNoteNumber?: string;
}> = ({ claimId, creditNoteNumber }) => {
  const flags = useFlags();
  const getFeatureFlag = useGetFeatureFlag();
  return (
    <Grid container columnGap={2} justifyContent="end" p={2}>
      <Grid item>
        <PrintClaim claimId={claimId} />
      </Grid>
      <Grid item>
        <UnconfirmButton claimId={claimId} />
      </Grid>
      {flags[getFeatureFlag('credit-note')] &&
        (creditNoteNumber ? (
          <ViewCreditNoteButton creditNoteNumber={creditNoteNumber} />
        ) : (
          <CreateCreditNoteFromClaim claimId={claimId} />
        ))}
    </Grid>
  );
};
export const RenderWhenStatusIsNotConfirmed: React.FC<{
  claimId: number;
  canConfirmOrDecline: CanConfirmOrDeclineType | undefined;
}> = ({ claimId, canConfirmOrDecline }) => {
  return (
    <Grid container columnGap={2} justifyContent="end" p={2}>
      <Grid item>
        <PrintClaim claimId={claimId} />
      </Grid>
      <Grid item>
        <DeclineButton claimId={claimId} canConfirmOrDecline={canConfirmOrDecline} />
      </Grid>
      <Grid item>
        <ConfirmButton claimId={claimId} canConfirmOrDecline={canConfirmOrDecline} />
      </Grid>
    </Grid>
  );
};
export const RenderWhenStatusIsOthers: React.FC<{ claimId: number }> = ({ claimId }) => {
  return (
    <Grid container columnGap={2} justifyContent="end" p={2}>
      <Grid item>
        <PrintClaim claimId={claimId} />
      </Grid>
    </Grid>
  );
};
