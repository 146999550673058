import { useDispatch } from 'react-redux';

import axios, { AxiosError, AxiosResponse } from 'axios';
import { properties, basicAuthToken, clientSecret } from 'Helpers';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

import { datadogRum } from '@datadog/browser-rum';
import { WebToken } from '_types_/Token';
import { LOGOUT, SET_TOKENS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { Token } from 'Hooks/useAuthHandler/state/_types/Token';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { OnboardUser, User } from 'Models/User/_types_/User';
import { BestoneResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';
import { userActions } from 'Store/actions/user.actions';

const branchNumber = sessionStorage.getItem('userServiceBranch')
  ? sessionStorage.getItem('userServiceBranch') + '/'
  : '';

const baseUserEndpoint = `${properties.serviceApiURL}${branchNumber}user`;
const getUserUrl = `${baseUserEndpoint}/info`;
const setVendorUrl = `${baseUserEndpoint}/set-vendor`;
const sendInviteUrl = `${baseUserEndpoint}/onboard`;
const changeEmailUrl = `${baseUserEndpoint}/change-email`;
const mapTokenUrl = `${properties.loginApiURL}accesstokenprovider/user/mapToken`;
const revokeAccessUrl = `${properties.loginApiURL}accesstokenprovider/user/revokeUserAccess`;

export const getBi2User = (token: Token): Promise<User | undefined> => {
  const cleanAxios = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      Authorization: `Bearer ${token.accessToken}`,
      provider: 'basic',
      'USERS-API-VERSION': '2.0',
    },
  });

  return cleanAxios
    .get<User>(getUserUrl)
    .then((user: AxiosResponse<User> | AxiosResponse<BestoneResponse<User>>): User | undefined => {
      try {
        const newUserRequest = (user as AxiosResponse<BestoneResponse<User>>).data.data;
        const oldUserRequest = user as AxiosResponse<User>;

        if (newUserRequest) {
          if (!newUserRequest.usersId) {
            throw new Error('ERROR');
          }
          newUserRequest.hasADLogin = token.hasADLogin; // setting this field until BE userLoggedIn call is updated and matches token response
          newUserRequest.adEmail = token.email;
          newUserRequest.adEmail = token.inviteStatus;
          newUserRequest.isBestsellerUser = newUserRequest.userType === 'BESTSELLER';
          return newUserRequest;
        }

        if (oldUserRequest) {
          if (!oldUserRequest.data || !oldUserRequest.data.usersId) {
            throw new Error('ERROR');
          }
          oldUserRequest.data.hasADLogin = token.hasADLogin;
          oldUserRequest.data.adEmail = token.email;
          oldUserRequest.data.inviteStatus = token.inviteStatus;
          oldUserRequest.data.isBestsellerUser = oldUserRequest.data.userType === 'BESTSELLER';
          return oldUserRequest.data;
        }
      } catch (e) {
        datadogRum.addError('Login error. User could not be casted.', e ?? 'Unknown error');
        throw new Error('ERROR');
      }
    });
};

export const useLogout = (): (() => void) => {
  const dispatch = useDispatch();
  return () => {
    dispatch(userActions.deleteUser());
  };
};

function putVendor(vendorMdm: string | null): Promise<User> {
  return axios
    .put<User>(
      setVendorUrl,
      {
        vendorMdmIdentifier: vendorMdm,
      },
      {
        headers: {
          'USERS-API-VERSION': '2.0',
        },
      }
    )
    .then((res: AxiosResponse<User>): User => {
      return res.data;
    });
}

const revokeAccess = (token: string): Promise<void> => {
  return axios.delete(revokeAccessUrl + '/' + token, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

const sendAdInvite = (
  email: string,
  authToken: string,
  isChangeEmail?: boolean
): Promise<OnboardUser | undefined> => {
  if (isChangeEmail) {
    return axios.put(
      changeEmailUrl,
      { email: email },
      {
        headers: {
          Authorization: 'Bearer ' + authToken,
          'USERS-API-VERSION': '2.0',
        },
      }
    );
  }
  return axios.post(
    sendInviteUrl,
    {
      email: email,
    },
    {
      headers: {
        Authorization: 'Bearer ' + authToken,
        'USERS-API-VERSION': '2.0',
      },
    }
  );
};

export interface MapTokenError extends Error {
  code: string | undefined;
  token: string;
}

const mapAdTokenToBi2Token = (token: string): Promise<Token | undefined> => {
  const data = new URLSearchParams();
  data.append('grant_type', 'password');
  data.append('client_id', 'bff-clientid');
  const parsedToken = jwtDecode<{ email: string; name: string }>(token);
  datadogRum.setUserProperty('email', parsedToken?.email ?? '');
  datadogRum.setUserProperty('id', 'AD-' + (parsedToken?.email ?? ''));
  datadogRum.setUserProperty('name', parsedToken?.name ?? '');
  datadogRum.addAction('Mapping token!');

  const cleanAxios = axios.create();
  return cleanAxios
    .post(mapTokenUrl, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + token,
        Provider: 'AD',
        'USERS-API-VERSION': '2.0',
      },
    })
    .then((res: AxiosResponse<WebToken>) => {
      const newToken: Token = {
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        idToken: token,
        expires: new Date().getTime() + 1000 * res.data.expires_in,
        provider: 'AD',
        sid: uuidv4(),
        remember: false,
        hasADLogin: res.data.adInfo.hasADLogin,
        email: res.data.email,
        inviteStatus: undefined,
      };
      return newToken;
    })
    .catch((e: AxiosError) => {
      const error: MapTokenError = {
        message: e.message,
        token: token,
        name: 'MapTokenError',
        code: e.code,
      };
      datadogRum.addError(`Error mapping token ${token} ${JSON.stringify(e, null)}`, e);
      throw error;
    });
};

export const checkCredentials = (
  username: string,
  password: string,
  beBranch: string | undefined
): Promise<
  | {
      data: {
        access_token: string;
        token_type: string;
        refresh_token: string;
        expires_in: number;
        scope: string;
        adInfo: {
          hasADLogin: boolean;
          adlogin: boolean;
        };
        email: string;
        invite_status: string | undefined;
      };
    }
  | undefined
> => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${basicAuthToken()}`,
  };

  const data = new URLSearchParams();
  data.append('username', username);
  data.append('password', password);
  data.append('grant_type', 'password');
  data.append('client_secret', clientSecret);
  data.append('client_id', 'bff-clientid');

  const instance = axios.create();

  return instance
    .post(
      properties.loginApiURL + (beBranch ? `${beBranch}/` : '') + 'accesstokenprovider/oauth/token',
      data,
      {
        headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch(() => {
      return undefined;
    });
};
export const useLogin = (): (({
  adIdToken,
}: {
  adIdToken?: string;
}) => Promise<User | undefined>) => {
  const dispatch = useDispatch();
  const { authDispatch } = useAuthState();

  return async ({ adIdToken: adToken }: { adIdToken?: string }): Promise<User | undefined> => {
    if (adToken === undefined) {
      authDispatch({ type: LOGOUT });
      dispatch(userActions.deleteUser());
      return Promise.reject(new Error('Token is undefined'));
    }

    let bi2Token: Token | undefined;

    try {
      bi2Token = await mapAdTokenToBi2Token(adToken);
      if (bi2Token === undefined) {
        dispatch(userActions.deleteUser());
        authDispatch({ type: LOGOUT });
        throw new Error('bi2Token is undefined');
      }
    } catch (e) {
      dispatch(userActions.deleteUser());
      authDispatch({ type: LOGOUT });
      throw e;
    }

    authDispatch({ type: SET_TOKENS, tokens: bi2Token });

    try {
      const user = await getBi2User(bi2Token);
      if (user !== undefined) {
        dispatch(userActions.setUser(user));
        return user;
      }
    } catch {
      datadogRum.addError('Refresh token failed.');
      authDispatch({ type: LOGOUT });
      return undefined;
    }
  };
};

export const userService = {
  putVendor,
  sendAdInvite,
  revokeAccess,
};
