import React, { useMemo } from 'react';

import { ClaimDetail, ClaimDetailLine } from 'Claim/_types/claims';
import ClaimDetailLinesTableHelper from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/ClaimDetailLinesTableHelper';
import Table from 'Components/Table';

interface Props {
  claimDetail: ClaimDetail | undefined;
}

const ExpandedClaimLines = (props: Props) => {
  const tableData = useMemo(() => props.claimDetail?.lines ?? [], [props.claimDetail?.lines]);

  const cols = ClaimDetailLinesTableHelper();
  return (
    <Table<ClaimDetailLine>
      columns={cols}
      rows={tableData}
      primaryKey="orderNumber"
      tableName="claim-details"
      options={{
        disableRefetchButton: true,
        expandable: false,
        toolbar: false,
        showTableName: false,
        dontAddExpandToUrl: true,
      }}
    />
  );
};

export default ExpandedClaimLines;
