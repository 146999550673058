export const findInObject = <T>(tree: Record<string, unknown>, keyToFind: string): T[] => {
  const returningArray: T[] = [];
  const innerFunction = (_tree: Record<string, unknown>, _keyToFind: string): void => {
    Object.keys(_tree).forEach((key) => {
      if (typeof _tree[key] === 'object' && _tree[key] !== null) {
        if (Object.prototype.hasOwnProperty.call(_tree[key], _keyToFind)) {
          returningArray.push(_tree[key] as T);
        }
        innerFunction(_tree[key] as Record<string, unknown>, _keyToFind);
      }
    });
  };
  innerFunction(tree, keyToFind);

  return returningArray;
};
