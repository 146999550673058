import React, { ReactElement } from 'react';

import DateFnsUtils from '@date-io/date-fns';

export const DateCell = (
  value: number | Date | string | undefined | null,
  format?: string
): ReactElement => {
  if (!value) {
    return <> - </>;
  }
  return <>{new DateFnsUtils().format(new Date(value), format ?? 'dd/MM/yyyy')}</>;
};
