import { useCallback, useEffect, useState } from 'react';

import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';

import { UseBestApiTupleConfigs } from 'Components/Hooks/_types_/UseBestApiTupleConfigs';
import { FakeData, fakeDataHandler } from 'Components/Hooks/useBaseApiQuery/fakeDataHandler';

import { UseBaseApiQueryTuple } from './_types_';

export * from './_types_';

export const useBaseApiQuery = <
  Response,
  Config extends UseBestApiTupleConfigs,
  ErrorType = AxiosError,
  MappedResponse = Response
>(
  latestApiEndpoint: string,
  latestConfig: Config,
  latestFakeData?: { data: MappedResponse | (() => MappedResponse); delay?: number },
  mapper?: (response: Response | undefined) => MappedResponse | undefined
): UseBaseApiQueryTuple<MappedResponse, Config, ErrorType> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();
  const [response, setResponse] = useState<MappedResponse>();
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();

  useEffect(() => {
    // useBaseApiQuery will be re-instantiated when fetch is called again, calling the cleanup function from the previous instance.
    // This will cancel the previous request if still in progress
    return () => {
      if (cancelToken && !latestConfig.dontCancelIfCalledAgain) {
        cancelToken?.cancel('function recalled');
      }
    };
  }, [cancelToken, latestConfig.dontCancelIfCalledAgain]);

  const query = useCallback(
    (
      _config?: Config,
      _fakeData?: FakeData<MappedResponse>,
      _url?: string
    ): Promise<MappedResponse | undefined> =>
      new Promise<MappedResponse | undefined>((resolve, reject) => {
        const fakeData = _fakeData ?? latestFakeData;
        const currentCancelToken = axios.CancelToken.source();
        setCancelToken(currentCancelToken);
        setError(undefined);
        setResponse(undefined);
        setLoading(true);
        if (fakeData) {
          return fakeDataHandler(fakeData).then((fakeResult) => {
            setLoading(false);
            setResponse(fakeData.data);
            resolve(fakeResult);
          });
        }
        axios(_url ?? latestApiEndpoint, {
          ...latestConfig,
          ..._config,
          cancelToken: currentCancelToken.token,
        })
          .then((res?: AxiosResponse<Response>): void => {
            const mappedData = mapper ? mapper(res?.data) : (res?.data as MappedResponse);
            setLoading(false);
            setResponse(mappedData);
            resolve(mappedData);
          })
          .catch((err: ErrorType): void => {
            if (axios.isCancel(err)) {
              return;
            }
            setLoading(false);
            setError(err);
            if (latestConfig.localErrorHandler) {
              resolve(undefined);
              return;
            }
            reject(err);
          });
      }),
    [latestApiEndpoint, latestConfig, latestFakeData, mapper]
  );

  return [loading, error, response, query, cancelToken];
};
