import { CanConfirmOrDeclineType, ClaimState } from 'Claim/_types/claims';
import {
  RenderWhenStatusIsConfirmed,
  RenderWhenStatusIsNotConfirmed,
  RenderWhenStatusIsOthers,
} from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/renderActionBarHelper';

type Props = {
  claimId?: number;
  status: ClaimState | undefined;
  creditNoteNumber?: string;
  closed?: boolean;
  canConfirmOrDecline: CanConfirmOrDeclineType | undefined;
};

const ActionBar = (props: Props) => {
  if (!props.claimId) {
    return null;
  }

  if (props.status === 'CONFIRMED' && !props.closed) {
    return (
      <RenderWhenStatusIsConfirmed
        claimId={props.claimId ?? 0}
        creditNoteNumber={props.creditNoteNumber}
      />
    );
  }
  if (props.status === 'NOT_CONFIRMED' && !props.closed) {
    return (
      <RenderWhenStatusIsNotConfirmed
        claimId={props.claimId ?? 0}
        canConfirmOrDecline={props?.canConfirmOrDecline}
      />
    );
  } else {
    return <RenderWhenStatusIsOthers claimId={props.claimId ?? 0} />;
  }
};

export default ActionBar;
