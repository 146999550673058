import { Key, ReactElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteValue,
  CircularProgress,
  InputProps,
  UseAutocompleteProps,
} from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { debounce } from 'lodash';

export interface SearchableDropdownElement {
  name: string;
  value: Key;
}

export interface SearchableDropdownProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    UseAutocompleteProps<SearchableDropdownElement, Multiple, DisableClearable, FreeSolo>,
    'renderInput' | 'options' | 'onChange'
  > {
  disabled?: boolean;
  elements: SearchableDropdownElement[];
  loading: boolean;
  onChange:
    | ((
        value: AutocompleteValue<SearchableDropdownElement, Multiple, DisableClearable, FreeSolo>
      ) => void)
    | undefined;
  error?: boolean;
  helperText?: string;
  width?: string | number;
  popupWidth?: string | number;
  label?: string;
  InputProps?: Partial<InputProps>;
  id?: string;
  TextFieldProps?: Partial<TextFieldProps>;
  variant?: 'standard' | 'outlined' | 'filled';
  size?: 'small' | 'medium';
  searchCallback?: {
    callback: (val: string | undefined) => void;
    searchTerm: string | undefined;
  };
  doCallOnChangeOnSameValue?: boolean;
  callOnChangeOnClear?: boolean;
  hyperControlledInputValue?: boolean;
}

const SearchableDropdown = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  onChange,
  defaultValue,
  disabled = false,
  doCallOnChangeOnSameValue = false,
  searchCallback,
  callOnChangeOnClear,
  popupWidth,
  hyperControlledInputValue,
  ...props
}: SearchableDropdownProps<Multiple, DisableClearable, FreeSolo>): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      if (
        searchCallback?.callback !== undefined &&
        inputValue !== undefined &&
        inputValue.trim().length > 0 &&
        inputValue !== searchCallback.searchTerm
      ) {
        searchCallback.callback(inputValue);
      }
    }, 300);
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, searchCallback]);

  useEffect((): void => {
    setInputValue(searchCallback?.searchTerm ?? '');
  }, [searchCallback?.searchTerm]);

  useEffect(() => {
    setInputValue(props.inputValue ?? '');
  }, [props.inputValue]);
  const { error, helperText, ...rest } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  const handleChange = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: AutocompleteValue<SearchableDropdownElement, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason
    ) => {
      const parsedElement = newValue as SearchableDropdownElement;
      if (props.freeSolo && reason === 'blur' && parsedElement.value !== undefined) {
        const found = props.elements.findIndex((x) => x.value === parsedElement.value) > -1;
        if (found) {
          return;
        }
      }
      if (doCallOnChangeOnSameValue && onChange) {
        onChange(newValue);
        return;
      }
      if (typeof newValue === 'string') {
        if (newValue === props.value && ref.current !== null) {
          ref.current.blur();
          return;
        }
      }
      if (
        (parsedElement?.value !== undefined ||
          (props.value as SearchableDropdownElement)?.value !== undefined) &&
        parsedElement?.value === (props.value as SearchableDropdownElement)?.value &&
        ref.current !== null &&
        !callOnChangeOnClear
      ) {
        ref.current.blur();
        return;
      }

      if (onChange) {
        onChange(newValue);
      }
    },
    [
      props.freeSolo,
      props.value,
      props.elements,
      doCallOnChangeOnSameValue,
      onChange,
      callOnChangeOnClear,
    ]
  );
  return (
    <Autocomplete
      {...rest}
      forcePopupIcon
      multiple={props.multiple}
      freeSolo={props.freeSolo}
      onChange={handleChange}
      size={props.size}
      id={props.id}
      disabled={disabled || (inputValue.length === 0 && props.loading)}
      inputValue={props.freeSolo || hyperControlledInputValue ? inputValue ?? '' : undefined}
      value={props.value}
      onInputChange={(_event, newValue, reason) => {
        if (searchCallback?.searchTerm !== undefined) {
          if (reason === 'input' || reason === 'clear') {
            setInputValue(newValue);
            return;
          }
        }
        if (reason === 'clear' || reason === 'input') {
          setInputValue(newValue);
        } else {
          setInputValue(inputValue ?? newValue);
        }
      }}
      disableClearable={props.disableClearable}
      getOptionLabel={(element: SearchableDropdownElement | string): string => {
        if (typeof element === 'string') {
          return element;
        }
        return element.name;
      }}
      isOptionEqualToValue={(
        option: SearchableDropdownElement,
        elem: SearchableDropdownElement
      ): boolean => {
        return option.value === elem?.value || option.name === elem?.name;
      }}
      loading={props.loading}
      options={props.elements}
      style={{ width: props.width }}
      popupIcon={props.loading ? <CircularProgress size={20} /> : <ArrowDropDown />}
      renderOption={(renderProps, option) => {
        const key = `${option.name}-${option.value}`;
        return (
          <li {...renderProps} key={key}>
            {option.name}
          </li>
        );
      }}
      componentsProps={{ paper: { sx: { width: popupWidth } } }}
      renderInput={(params): ReactElement => (
        <TextField
          {...params}
          style={{ width: props.width }}
          error={error}
          helperText={helperText}
          label={props.label}
          FormHelperTextProps={{
            style: { color: 'red' },
          }}
          InputLabelProps={{
            variant: props.variant,
          }}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
          }}
          {...props.TextFieldProps}
          variant={props.variant}
          inputRef={ref}
        />
      )}
    />
  );
};
export default SearchableDropdown;
