import { ReactElement, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  Link,
  IconButton,
} from '@mui/material';

import { LOGIN_GUIDE_URL } from 'Constants/shared.constants';

interface Props {
  open: boolean;
  onClose: () => void;
  goToCredentialsForm: (() => void) | undefined;
}

const AdLoginIssuesDialog = (props: Props): ReactElement => {
  const handleOpenLoginGuide = useCallback(() => {
    const newWindow = window.open(LOGIN_GUIDE_URL, '_blank');
    newWindow?.focus();
  }, []);
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Seems like you&apos;ve cancelled the login flow.</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'gray',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent>
        <Typography>If you are getting this error</Typography>
        <Typography fontWeight="bold" marginTop="8px">
          Sorry, but we&apos;re having trouble signing you in...
        </Typography>
        <Typography marginTop="8px">
          <span>
            It might be because your e-mail has not yet been linked to a Bestinfo account. Please
            click here to&nbsp;
          </span>
          <Link
            color="info.main"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (props.goToCredentialsForm !== undefined) {
                props.goToCredentialsForm();
              }
            }}
          >
            Link email to BI2 account
          </Link>
        </Typography>
        <Typography marginTop="8px">
          <span>We reccomend reading the &nbsp;</span>
          <Link
            color="info.main"
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenLoginGuide()}
          >
            login guide
          </Link>
          <span>.</span>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AdLoginIssuesDialog;
