import { claimBaseUrl } from 'Claim/_services/claimService';
import { GetCreditNoteConfig } from 'Claim/_services/creditNoteService';
import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';

interface CNCandidateParams {
  claimNumber: string;
}

export const useClaimNumberValidation = (
  params: CNCandidateParams
): UseLazyBestApiTuple<number, GetCreditNoteConfig> => {
  return useLazyBestApi<number, GetCreditNoteConfig>(claimBaseUrl(`claims/credit-note-candidate`), {
    headers: {
      'CLAIMS-API-VERSION': '1.0',
    },
    method: 'GET',
    params,
  });
};
