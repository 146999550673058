export const colorConvertor = (color: string | undefined) => {
  if (color === 'success') {
    return 'success';
  }
  if (color === 'warning') {
    return 'warning';
  }
  if (color === 'info') {
    return 'info';
  }
  return 'primary';
};
