import React, { Key, ReactElement, useCallback, useMemo } from 'react';

import { Checkbox } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';

import { doSelectTableRow } from 'Components/Table/state/actions/selectRow';

import { TableRecord } from '../../state/_types_/TableRecord';
import { getIsRowSelected } from '../../state/selectors/getIsRowSelected';
import { useTableState } from '../../state/useTableState';

const SelectRow = <T extends TableRecord>({
  row,
  onCheckCallback,
  hyperControlledSelectedRows,
}: {
  row: T;
  onCheckCallback?: (selectedId: Key, checked: boolean) => void;
  hyperControlledSelectedRows?: boolean;
}): ReactElement => {
  const { state, dispatch } = useTableState();
  const primaryKeyOfRow = row[state.primaryKey] as T[keyof T];

  const isSelected = useMemo(() => {
    if (typeof primaryKeyOfRow !== 'string' && typeof primaryKeyOfRow !== 'number') {
      return;
    }
    return getIsRowSelected(state, primaryKeyOfRow);
  }, [primaryKeyOfRow, state]);

  const selectTableRows = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked) => {
      if (typeof primaryKeyOfRow !== 'string' && typeof primaryKeyOfRow !== 'number') {
        return;
      }
      if (!hyperControlledSelectedRows) {
        dispatch(doSelectTableRow(primaryKeyOfRow));
      }
      onCheckCallback?.(primaryKeyOfRow, checked);
    },
    [onCheckCallback, primaryKeyOfRow, dispatch, hyperControlledSelectedRows]
  );

  if (typeof primaryKeyOfRow !== 'string' && typeof primaryKeyOfRow !== 'number') {
    return <></>;
  }
  if (!state.tableOptions.selectable) {
    return <></>;
  }

  return (
    <MuiTableCell padding="checkbox">
      <Checkbox
        checked={isSelected}
        onChange={selectTableRows}
        inputProps={{ style: { zIndex: 0 } }}
      />
    </MuiTableCell>
  );
};

export default SelectRow;
