import { AxiosError } from 'axios';

import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';

const dontLogTheseErrors = [
  'AADSTS70044', //The session has expired or is invalid due to sign-in frequency checks by conditional access
  'hash_empty_error',
];

export const errorHandler = (error: unknown): void => {
  const axiosError = error as AxiosError;
  const adError = error as InteractionRequiredAuthError;
  const allErrors = [
    (error as Error).message,
    axiosError.message,
    axiosError?.response?.data?.error_description,
    adError?.message,
  ];

  if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
    return;
  }

  if (dontLogTheseErrors.some((code) => allErrors.some((error) => error?.includes(code)))) {
    return;
  }

  console.error(error);
};
