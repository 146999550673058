import {
  RumEvent,
  RumEventDomainContext,
  RumResourceEvent,
  RumFetchResourceEventDomainContext,
} from '@datadog/browser-rum';

const getQueryName = (query: string, regex: RegExp): string | undefined => {
  try {
    const matches = regex.exec(query) ?? [];
    if (matches.length === 0) {
      return undefined;
    }
    const fixedMatches = matches[matches.length - 1]; //Sometimes match fixing is okay 8-)
    return fixedMatches;
  } catch (e) {
    console.error({ error: e, query, regex });
    throw e;
  }
};

const getQueryFromDataDogEvent = (
  event: RumEvent,
  context: RumEventDomainContext,
  variablesHandler?: (variables: Record<string, unknown>) => string
): {
  query: string | undefined;
  resourceEvent: RumResourceEvent | undefined;
  variables: string | undefined;
} => {
  try {
    const resourceEvent = event as RumResourceEvent;

    if (!resourceEvent?.resource?.url?.includes('graphql')) {
      return { query: undefined, resourceEvent: undefined, variables: undefined };
    }
    const requestBody = JSON.parse(
      ((context as RumFetchResourceEventDomainContext)?.requestInit?.body as string) ?? ''
    );
    const query = requestBody['query'];
    let queryVariables: string | undefined = undefined;
    if (variablesHandler !== undefined) {
      const variables = requestBody['variables'];
      if (Object.keys(variables).length > 0) {
        const queryParams = variablesHandler(variables);
        queryVariables = queryParams;
      }
    }
    return { query, resourceEvent, variables: queryVariables };
  } catch (e) {
    console.error(e);
    return { query: undefined, resourceEvent: undefined, variables: undefined };
  }
};

const enrichGqlQuery = (
  resourceEvent: RumResourceEvent,
  query: string,
  variables: string | undefined
): boolean => {
  try {
    const queryName = getQueryName(query, /(query)\s(\w+)/gm);
    if (queryName === undefined) {
      return false;
    }
    const fixedVariables = variables ? `?${variables}` : '';
    resourceEvent.resource.url = resourceEvent.resource.url + `/${queryName}${fixedVariables}`;
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const enrichGqlMutation = (resourceEvent: RumResourceEvent, query: string): boolean => {
  try {
    const mutationName = getQueryName(query, /(mutation)\s(\w+)/gm);

    resourceEvent.resource.url = resourceEvent.resource.url + `/${mutationName}`;
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * Interceptor that adds the query name to the url of the resource event
 * if the resource event is a graphql query or mutation
 *
 * This only affects the metric sent do DataDog.
 *
 * @param event RumEvent from DataDog
 * @param context RumEventDomainContext from Datadog
 * @param variablesHandler Function to convert variables to query params string
 */
export const includeGqlQueryNameInUrl = (
  event: RumEvent,
  context: RumEventDomainContext,
  variablesHandler?: (variables: Record<string, unknown>) => string
): (void | boolean) | undefined => {
  const { query, resourceEvent, variables } = getQueryFromDataDogEvent(
    event,
    context,
    variablesHandler
  );

  if (query && resourceEvent) {
    if (enrichGqlQuery(resourceEvent, query, variables)) {
      return;
    }
    if (enrichGqlMutation(resourceEvent, query)) {
      return;
    }
    console.error(`Could not enrich query ${query}`);
  }
};
