import { Key } from 'react';
import { useSelector } from 'react-redux';

import { AxiosError } from 'axios';

import { datadogRum } from '@datadog/browser-rum';
import { printFileInfoGenerator, base64ToBuffer, arraysEqual } from 'Helpers/util';
import { orderPrintType } from 'Models/Orders/_types_/OrderType';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

import { BestoneResponse, LocalBestoneFileResponse } from '../interfaces/BestoneFileResponse';

const mapResToBlob = (
  ids: Key | Key[],
  type: string | orderPrintType,
  res: BestoneResponse<Blob | string>
): LocalBestoneFileResponse => {
  const idsArray = Array.isArray(ids) ? ids : [ids];

  if (res.errors && res.errors.length > 0 && arraysEqual(res.errors[0].errorIds ?? [], idsArray)) {
    return {
      ...res,
      contentType: undefined,
      data: undefined,
      error: res.errors ?? [{ errorMessage: 'General print error', errorIds: ids }],
    };
  }

  let file: Blob | undefined = undefined;
  if (res.data) {
    const fileInfo = printFileInfoGenerator(ids, type);
    if (typeof res.data === 'string') {
      file = new Blob([base64ToBuffer('' + res.data)], {
        type: fileInfo.fileType,
      });
    } else {
      const blobFile = res.data;
      if (!blobFile.size) {
        datadogRum.addError('mapResToBlob: Could not handle BLOB file', {
          id: idsArray,
        });

        return {
          ...res,
          contentType: res.contentType ?? undefined,
          error: [
            {
              errorMessage: 'Could not handle BLOB file',
              errorIds: idsArray,
            },
          ],
          data: undefined,
        };
      }
      file = res.data;
    }
  }
  return {
    ...res,
    contentType: res.contentType ?? undefined,
    error: res.errors ?? [],
    data: file,
  };
};

export const useMapError = (): ((
  e: AxiosError<BestoneResponse<string>>
) => LocalBestoneFileResponse) => {
  const fakedError = useSelector(
    (root: RootReducerState) =>
      root.devSettingsState.fakeExtError.fullFailure ||
      root.devSettingsState.fakeExtError.partialFailure
  );

  return (e: AxiosError<BestoneResponse<string>>) => {
    if (!fakedError) {
      datadogRum.addError('Print error', e);
    }

    return {
      contentType: undefined,
      data: undefined,
      error: e.response?.data.errors ?? [
        {
          errorMessage:
            e.response?.data.message ??
            `${
              e.response?.status ? `(Error ${e.response.status}): ` : ''
            }Error printing. Please try again later.`,
          errorIds: [],
        },
      ],
    };
  };
};

export default {
  mapResToBlob,
};
