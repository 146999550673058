import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material';

import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { BrowserAuthError, EventMessage } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import { loginRequest } from 'Config/adAuthConfig';
import { LOGOUT, SET_TOKENS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { MapTokenError, useLogin } from 'Services/authentication.service';
import { toastActions } from 'Store/actions/toast.actions';
import { userActions } from 'Store/actions/user.actions';
import AdLoginIssuesDialog from 'Views/Login/AdSinginButton/AdIssuesDialog';
import { colorConvertor } from 'Views/Login/AdSinginButton/helpers/colorConvertor';
import useAutoSignIn from 'Views/Login/AdSinginButton/helpers/useAutoSignIn';
import ShowContactSupportDialog from 'Views/Login/AdSinginButton/ShowContactSupportDialog';

const AdSinginButton = (props: {
  beBranch?: string;
  setError: Dispatch<SetStateAction<string>>;
  color?: string;
  height?: string;
  setLoading?: (loading: boolean) => void;
  goToCredentialsForm: (() => void) | undefined;
}): ReactElement => {
  const { instance } = useMsal();
  const logIn = useLogin();
  const history = useHistory();
  const { authDispatch } = useAuthState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [eventCallbackId, setEventCallbackId] = useState<string | undefined>();
  const [userCancelledFlow, setUserCancelledFlow] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const [showContactSupportDialog, setShowContactSupportDialog] = useState(false);
  const [errorToken, setErrorToken] = useState<string | undefined>();

  const { tryToAutomaticallySignIn, automaticSignInState } = useAutoSignIn({
    beBranch: props.beBranch,
    redirectToHome: true,
  });

  useEffect(() => {
    if (automaticSignInState !== 'NOT_STARTED') {
      return;
    }
    setLoading(true);
    tryToAutomaticallySignIn().then(() => setLoading(false));
  }, [automaticSignInState, tryToAutomaticallySignIn]);

  useEffect(() => {
    if (automaticSignInState === 'FAILED') {
      instance.logout();
      setLoading(false);
    }
  }, [automaticSignInState, instance]);

  useEffect(() => {
    if (automaticSignInState === 'COMPLETED') {
      setLoading(false);
    }
  }, [automaticSignInState, instance]);

  useEffect(() => {
    if (eventCallbackId !== undefined) {
      return;
    }

    const callbackId = instance.addEventCallback((message: EventMessage) => {
      console.debug('Event message:', message);
      if (message.eventType === 'msal:popupOpened') {
        setPopupOpened(true);
        return;
      }
      if (message.eventType === 'msal:loginFailure' || message.eventType === 'msal:loginSuccess') {
        setPopupOpened(false);
      }
    });
    setEventCallbackId(callbackId ?? undefined);
    return () => {
      if (eventCallbackId === undefined) {
        return;
      }
      instance.removeEventCallback(eventCallbackId);
    };
  }, [dispatch, eventCallbackId, instance, props]);

  useEffect(() => {
    if (props.setLoading !== undefined) {
      props.setLoading(loading);
    }
  }, [loading, props]);

  const handleLogin = useCallback(() => {
    setLoading(true);
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // If there is an active account, we don't need to do anything
        if (instance.getActiveAccount() !== null) {
          return;
        }
        instance.setActiveAccount(response.account);
        if (response) {
          authDispatch({
            type: SET_TOKENS,
            tokens: {
              accessToken: undefined,
              idToken: response.idToken,
              remember: false,
              provider: 'AD',
              refreshToken: response.idToken,
              expires: new Date().getTime() + 1000 * Number(response.expiresOn),
              sid: uuidv4(),
              hasADLogin: false,
              email: '',
              inviteStatus: undefined,
            },
          });

          logIn({ adIdToken: response.idToken })
            .then(() => history.push('/'))
            .catch((e: AxiosError | MapTokenError) => {
              if (e.code === '403' || e.message.search('403') !== -1) {
                const error = e as MapTokenError;
                setErrorToken(error.token);
                setShowContactSupportDialog(true);
                datadogRum.addAction('Showing contact support dialog');
              }
              authDispatch({ type: LOGOUT });
              dispatch(userActions.deleteUser());
              dispatch(toastActions.setErrorMessage('Something went wrong: ' + e.message));
              setLoading(false);
              datadogRum.addError(`logIn error: ${JSON.stringify(e, null, 2)}`);
            });
        } else {
          authDispatch({ type: LOGOUT });
          dispatch(userActions.deleteUser());
          setLoading(false);
        }
      })
      .catch((e) => {
        const browserAuthError = e as BrowserAuthError;
        if (browserAuthError !== null && browserAuthError.errorCode === 'user_cancelled') {
          setPopupOpened(false);
          setUserCancelledFlow(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        dispatch(toastActions.setErrorMessage('Something went wrong: ' + e.message));
      });
  }, [authDispatch, dispatch, history, instance, logIn]);

  const handleClick = useCallback(() => {
    props.setError('');
    handleLogin();
  }, [handleLogin, props]);

  const buttonColor = useMemo(() => colorConvertor(props.color), [props.color]);

  if (automaticSignInState !== 'COMPLETED' && automaticSignInState !== 'FAILED') {
    return (
      <Box>
        <Typography>
          <span>Trying to sign you in automatically&nbsp;&nbsp;</span>
          <CircularProgress size={16} />
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <ShowContactSupportDialog
        open={showContactSupportDialog}
        onClose={() => {
          setShowContactSupportDialog(false);
        }}
        token={errorToken}
      />
      <AdLoginIssuesDialog
        open={userCancelledFlow}
        onClose={() => setUserCancelledFlow(false)}
        goToCredentialsForm={props.goToCredentialsForm}
      />
      <Grid container flexDirection="column">
        {popupOpened && (
          <Typography variant="body2">Please finish logging in in the popup...</Typography>
        )}
        <Grid item flex={1}>
          <Button
            disabled={loading || popupOpened}
            variant="contained"
            color={buttonColor}
            disableElevation
            sx={{ width: '100%', color: 'white', height: props.height ?? '100%' }}
            onClick={handleClick}
          >
            LOG IN WITH MICROSOFT
          </Button>
        </Grid>
        <Grid item flex={1}>
          {loading ? (
            <LinearProgress />
          ) : (
            <div>
              <span>&nbsp;</span>
            </div>
          )}
        </Grid>
        {instance.getAllAccounts().length > 0 && (
          <Grid item justifyContent="end" display="flex" marginTop="8px">
            <Button
              disabled={loading}
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => {
                authDispatch({ type: LOGOUT });
                instance.logout();
              }}
            >
              SIGN OUT OF MICROSOFT
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AdSinginButton;
