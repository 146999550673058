import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
export abstract class CustomError implements AxiosError {
  code?: string;
  config: AxiosRequestConfig;
  isAxiosError: boolean;
  name: string;
  message: string;
  request?: unknown;
  response?: AxiosResponse<unknown>;
  stack?: string;

  constructor(message?: string) {
    this.message = message ?? 'VendorPortalError';
    this.name = 'CustomError';
    this.isAxiosError = false;
    this.config = {};
  }
  toJSON = (): Record<string, unknown> => {
    return {
      name: this.name,
    };
  };
}
