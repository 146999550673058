import { PutStateClaimConfig, claimBaseUrl } from 'Claim/_services/claimService';
import { UseLazyBestApiTuple, useLazyBestApi } from 'Components/Hooks/useLazyBestApi';

const UNCONFIRM_CLAIM = (claimId: number | undefined) => claimBaseUrl(`claims/${claimId}/revert`);

export const useUnconfirmClaim = (
  claimId: number | undefined
): UseLazyBestApiTuple<number, PutStateClaimConfig> => {
  return useLazyBestApi<number, PutStateClaimConfig>(UNCONFIRM_CLAIM(claimId), {
    headers: {
      'CLAIMS-API-VERSION': '1.0',
    },
    method: 'PUT',
  });
};
