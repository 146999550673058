import { useMemo } from 'react';

import { ClosedClaimParam, GetClaimLandingPageGroupsParams } from 'Claim/_types/claims';
import { useQuery } from 'Components/Hooks/useQuery';
import { typeGuardForStrings } from 'Components/Shared/TypeGuardHelperFunctions/typeGuardForStrings';

const useQueryParams = () => {
  const query = useQuery();

  const defaultParams: GetClaimLandingPageGroupsParams = {
    claimNumber: null,
    orderNumber: null,
    styleNumber: null,
    styleName: null,
    productLineId: [],
    brandId: [],
    purchaserId: [],
    statusId: [],
    page: 0,
    size: 20,
    closed: null,
    hasCreditNote: null,
  };

  const queryParams = useMemo(
    () => ({
      claimNumber: query?.get('claimNumber'),
      orderNumber: query?.get('orderNumber'),
      styleNumber: query?.get('styleNumber'),
      styleName: query?.get('styleName'),
      productLineId: query?.getAll('productLineId'),
      brandId: query?.getAll('brandId'),
      purchaserId: query?.getAll('purchaserId'),
      statusId: query?.getAll('statusId'),
      page: +(query?.get('page') ?? 0),
      size: +(query?.get('size') ?? 20),
      closed: typeGuardForStrings<ClosedClaimParam>(query?.get('closed'), ['Y', 'N']),
      hasCreditNote: typeGuardForStrings<ClosedClaimParam>(query?.get('hasCreditNote'), ['Y', 'N']),
    }),
    [query]
  );

  return { queryParams, defaultParams };
};

export default useQueryParams;
