import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { DialogButtonProps, Dialog } from '@bestseller-bit/frontend-community.components.dialog';

type Props = {
  onUnconfirm: () => void;
  onClose: () => void;
  open: boolean;
  hasCreditNote?: boolean;
};

const UnconfirmModal = (props: Props) => {
  const { onUnconfirm, onClose, open, hasCreditNote } = props;

  const textForDialog = useMemo(() => {
    return hasCreditNote
      ? 'You are about to move a Claim with a generated Credit Note into state NOT CONFIRMED.'
      : 'You are about to move a Claim into state NOT CONFIRMED.';
  }, [hasCreditNote]);

  const buttonsForModal: DialogButtonProps[] = useMemo(() => {
    return [
      {
        label: 'Cancel',
        onClick: onClose,
        variant: 'outlined',
      },
      {
        label: 'Unconfirm',
        onClick: onUnconfirm,
        variant: 'contained',
      },
    ];
  }, [onClose, onUnconfirm]);

  return (
    <Dialog onClose={onClose} open={open} buttons={buttonsForModal} width="sm" dividers>
      <Box textAlign="center" p={1}>
        <Typography fontSize={24} mb={3} fontWeight={700}>
          {textForDialog}
        </Typography>
        {hasCreditNote && (
          <Typography fontSize={18} mb={1}>
            <span>By unconfirming this claim the generated Credit Note will be&nbsp;</span>
            <b>deleted.</b>
          </Typography>
        )}
        <Typography mb={1} fontSize={18}>
          Are you sure you wish to unconfirm the claim?
        </Typography>
      </Box>
    </Dialog>
  );
};

export default UnconfirmModal;
