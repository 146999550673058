import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { Purchaser, ServerBrands } from '_types_/MasterData/MasterDataType';
import { useGetClaimStatus } from 'Claim/_services/hooks/claims/getClaimStatus';
import {
  Claim,
  ClaimStatusResponse,
  ClosedClaimParam,
  GetClaimLandingPageGroupsParams,
} from 'Claim/_types/claims';
import ClaimNumberCellResolver from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/ClaimNumberCellResolver';
import ReverseArraysOrderInToolTip from 'Claim/Claims/ClaimGroups/ExpandedContent/helpers/ReverseArraysOrderInToolTip';
import ClaimStateCell from 'Claim/Claims/ClaimGroups/ExpandedContent/Table/ClaimStateCell';
import { ReturnedQueryParameters } from 'Components/Hooks/useUrlParams/useMultipleUrlParams';
import { MenuItemElement } from 'Components/Shared/FilterButton';
import useSharedFilterParameters from 'Components/Shared/useSharedFilterParameters';
import { TableColumn } from 'Components/Table/state/_types_/TableColumn';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import { capitalizeWords } from 'Helpers/capitalizedAllWordsInAnString';
import { formatDateStringToDDMMYYYY } from 'Helpers/formatters/dateFormatter';
import { masterDataService } from 'Services/masterdata.service';
import { toastActions } from 'Store/actions/toast.actions';

export type ClaimGroups = ReturnedQueryParameters<GetClaimLandingPageGroupsParams> & Claim;

interface Props {
  queryParams: ReturnedQueryParameters<GetClaimLandingPageGroupsParams>;
}

const ClaimGroupsTableHelper = (props: Props) => {
  const dispatch = useDispatch();
  const flags = useFlags();
  const getFeatureFlag = useGetFeatureFlag();

  const [statusLoading, statusError, statusData] = useGetClaimStatus();
  const [claimStatusOptions, setClaimStatusOptions] = useState<MenuItemElement[]>([]);

  const { productLines } = useSharedFilterParameters();
  const [fetchBrands, { loading: brandsLoading, error: brandsError, data: brandsData }] =
    masterDataService.useFetchBrands();

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const brandsOptions = useMemo(() => {
    return brandsData?.map(
      (brand: ServerBrands): MenuItemElement => ({
        title: brand.brandName,
        value: brand.brandsId.toString(),
      })
    );
  }, [brandsData]);

  const [
    fetchPurchasers,
    { loading: purchasersLoading, error: purchasersError, data: purchasersData },
  ] = masterDataService.useFetchPurchasers();

  useEffect(() => {
    fetchPurchasers();
  }, [fetchPurchasers]);

  useEffect((): void => {
    if (purchasersError) {
      dispatch(toastActions.setErrorMessage(purchasersError));
    }
  }, [dispatch, purchasersError]);

  useEffect((): void => {
    if (brandsError) {
      dispatch(toastActions.setErrorMessage(brandsError));
    }
  }, [dispatch, brandsError]);

  useEffect((): void => {
    if (statusError) {
      dispatch(toastActions.setErrorMessage(statusError));
    }
  }, [dispatch, statusError]);

  const purchasersOptions = useMemo(
    () =>
      purchasersData?.map(
        (purchaser: Purchaser): MenuItemElement => ({
          title: purchaser.name,
          value: purchaser.id.toString(),
        })
      ),
    [purchasersData]
  );

  useEffect(() => {
    if (statusData && statusData.length > 0) {
      const options: {
        title: string;
        value: number | string;
      }[] = statusData.map((status: ClaimStatusResponse) => ({
        title: capitalizeWords(status.name),
        value: status.id,
      }));
      setClaimStatusOptions(options);
    }
  }, [statusData]);

  const renderClaimAmount = useCallback((claimAmount: { amount: string; currencyCode: string }) => {
    const result =
      claimAmount.amount === null || claimAmount.currencyCode === null
        ? '-'
        : `${claimAmount.amount} ${claimAmount.currencyCode}`;
    return result;
  }, []);

  const renderPaymentMethod = useCallback((name: string) => {
    return name === 'BANK_TRANSFER' ? 'Bank Transfer' : name;
  }, []);

  return useMemo((): TableColumn<ClaimGroups>[] => {
    const columns: TableColumn<ClaimGroups>[] = [
      {
        hidden: true,
        label: 'Brand',
        property: 'brandId',
        filter: {
          type: 'searchableSelect',
          elements: brandsOptions,
          values: [],
          loading: brandsLoading,
          setValue: (values) => props.queryParams.brandId.setValue(values.map((r) => r.toString())),
        },
      },
      {
        hidden: true,
        label: 'Product Line',
        property: 'productLineId',
        filter: {
          type: 'searchableSelect',
          elements: productLines,
          values: [],
          setValue: (values) =>
            props.queryParams.productLineId.setValue(values?.map((r) => r.toString())),
        },
      },
      {
        hidden: true,
        label: 'Purchaser',
        property: 'purchaserId',
        filter: {
          type: 'searchableSelect',
          elements: purchasersOptions,
          loading: purchasersLoading,
          values: [],
          setValue: (values) =>
            props.queryParams.purchaserId.setValue(values?.map((r) => r.toString())),
        },
      },
      {
        property: 'claim.number',
        label: 'Claim Number',
        resolver: (item) => (
          <ClaimNumberCellResolver
            claimNumber={item.claim.number}
            isClosed={item.closed}
            creditNoteNumber={item.creditNoteNumber}
          />
        ),
        search: {
          value: props.queryParams.claimNumber.value,
          handler: (r) => {
            props.queryParams.page.setValue(0);
            props.queryParams.claimNumber.setValue(r);
          },
        },
      },
      {
        property: 'releasedToSupplier',
        label: 'Released To Supplier',
        resolver: (item) => formatDateStringToDDMMYYYY(item.releasedToSupplier),
      },
      {
        property: 'orderNumbers',
        label: 'Order Numbers',
        resolver: (item) =>
          ReverseArraysOrderInToolTip({
            values: item.orderNumbers,
            key: `${item.claim.id}-order-number`,
          }),
        search: {
          value: props.queryParams.orderNumber.value,
          handler: (r) => {
            props.queryParams.page.setValue(0);
            props.queryParams.orderNumber.setValue(r);
          },
        },
      },
      {
        hidden: true,
        property: 'styleNumber',
        label: 'Style Number',
        search: {
          value: props.queryParams.styleNumber.value,
          handler: (r) => {
            props.queryParams.page.setValue(0);
            props.queryParams.styleNumber.setValue(r);
          },
        },
      },
      {
        property: 'styleNames',
        label: 'Style Name',
        resolver: (item) =>
          ReverseArraysOrderInToolTip({
            values: item.styleNames,
            key: `${item.claim.id}-style-name`,
          }),
        search: {
          value: props.queryParams.styleName.value,
          handler: (r) => {
            props.queryParams.page.setValue(0);
            props.queryParams.styleName.setValue(r);
          },
        },
      },
      {
        property: 'paymentMethod',
        label: 'Payment Method',
        resolver: (item) => renderPaymentMethod(item.paymentMethod?.name ?? '-'),
      },
      {
        property: 'claimAmount',
        label: 'Claim Amount',
        resolver: (item) => renderClaimAmount(item.claimAmount),
      },
      {
        property: 'status',
        label: 'Status',
        resolver: (item) => ClaimStateCell({ status: item.status }),
        filter: {
          type: 'select',
          elements: claimStatusOptions,
          loading: statusLoading,
          values: [],
          setValue: (values) =>
            props.queryParams.statusId.setValue(values.map((r) => r.toString())),
        },
      },
      {
        property: 'closed',
        label: 'Closed',
        hidden: true,
        filter: {
          type: 'selectOne',
          elements: [
            {
              title: 'Yes',
              value: 'Y',
            },
            {
              title: 'No',
              value: 'N',
            },
          ],
          setValue: (value) => props.queryParams.closed.setValue(value as ClosedClaimParam),
        },
      },
      {
        property: 'supplier',
        label: 'Supplier',
        resolver: (item) => item.supplier,
      },
    ];

    if (flags[getFeatureFlag('credit-note')]) {
      columns.push({
        property: 'hasCreditNote',
        label: 'Has Credit Note',
        hidden: true,
        filter: {
          type: 'selectOne',
          elements: [
            {
              title: 'Yes',
              value: 'Y',
            },
            {
              title: 'No',
              value: 'N',
            },
          ],
          setValue: (value) => props.queryParams.hasCreditNote.setValue(value as ClosedClaimParam),
        },
      });
    }

    return columns;
  }, [
    brandsOptions,
    brandsLoading,
    productLines,
    purchasersOptions,
    purchasersLoading,
    props.queryParams.claimNumber,
    props.queryParams.orderNumber,
    props.queryParams.styleNumber,
    props.queryParams.styleName,
    props.queryParams.brandId,
    props.queryParams.productLineId,
    props.queryParams.purchaserId,
    props.queryParams.page,
    props.queryParams.statusId,
    props.queryParams.closed,
    props.queryParams.hasCreditNote,
    claimStatusOptions,
    statusLoading,
    renderPaymentMethod,
    renderClaimAmount,
    flags,
    getFeatureFlag,
  ]);
};

export default ClaimGroupsTableHelper;
