import { claimBaseUrl, GetClaimConfig } from 'Claim/_services/claimService';
import { ClaimsResponse, GetClaimLandingPageGroupsParams } from 'Claim/_types/claims';
import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';

export const useGetClaims = (
  params: GetClaimLandingPageGroupsParams
): UseLazyBestApiTuple<ClaimsResponse, GetClaimConfig> => {
  return useLazyBestApi<ClaimsResponse, GetClaimConfig>(claimBaseUrl('claims'), {
    headers: {
      'CLAIMS-API-VERSION': '1.0',
    },
    method: 'GET',
    params,
  });
};
