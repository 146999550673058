import { claimBaseUrl } from 'Claim/_services/claimService';
import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import { PostSampleProformaConfig } from 'Invoice/_invoiceServices/hooks/samples/proforma/sampleProformaBaseUrl';
import { BestoneResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';

export interface CreateCreditNote {
  claimId: number;
  email: string;
  creditNoteNumber: string;
}

export const useCreateCreditNote = (): UseLazyBestApiTuple<
  BestoneResponse<CreateCreditNote>,
  PostSampleProformaConfig
> => {
  return useLazyBestApi<BestoneResponse<CreateCreditNote>, PostSampleProformaConfig>(
    claimBaseUrl('credit-note/'),
    {
      headers: {
        'CREDIT-NOTE-API-VERSION': '1.0',
      },
      method: 'POST',
    }
  );
};
export default useCreateCreditNote;
