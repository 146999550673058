import { UseLazyBestApiTuple, useLazyBestApi } from 'Components/Hooks/useLazyBestApi';
import {
  GetLinkedAccount,
  userAdServiceApiBaseUrl,
} from 'Views/Login/ChooseAccount/_adLoginServices/adLoginService';

const PUT_DEFAULT_ACCOUNT = (bi2NetworkId: string) =>
  userAdServiceApiBaseUrl(`profile/${bi2NetworkId}/set-default`);

// Generated by https://quicktype.io

export interface PutDefaultAccountResponse {
  bi2NetworkId: string;
  bi2UsersId: number;
  email: string;
  displayName: string;
  isDefault: string;
  companyName: string;
  mdmIdentifier: string;
  currentUser: boolean;
}

export const usePutDefaultAccount = (
  bi2NetworkId: string
): UseLazyBestApiTuple<PutDefaultAccountResponse, GetLinkedAccount> => {
  return useLazyBestApi<PutDefaultAccountResponse, GetLinkedAccount>(
    PUT_DEFAULT_ACCOUNT(bi2NetworkId),
    {
      headers: {
        'USERS-API-VERSION': '2.0',
      },
      method: 'PUT',
    }
  );
};
