import { ReactElement, useCallback, Suspense, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Box } from '@mui/material';

import { theme } from 'Theme';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import Campaign from 'App/Campaign';
import Papskilt from 'App/Papskilt';
import PrivateRoute from 'Components/Shared/PrivateRoute';
import VpFooter from 'Components/UI/Devbar';
import DisplayErrorHandler from 'Components/UI/DisplayErrorHandler';
import ErrorBoundary from 'Components/UI/ErrorBoundary';
import GreetingDialogs from 'Components/UI/GreetingsDialog/GreetingDialogs';
import Navigation from 'Components/UI/Navigation';
import Splash from 'Components/UI/Splash';
import 'globals.css';
import { findInObject } from 'Helpers/findInObject';
import useAxiosInterceptors from 'Hooks/useAuthHandler';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import useDDAuth from 'Hooks/useDDAuth';
import useLDAuth from 'Hooks/useLDAuth';
import { SampleProformaHeaderContext } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderContext';
import { useSampleProformaHeaderReducer } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/useSampleProformaHeaderReducer';
import { User } from 'Models/User/_types_/User';
import {
  useFetchOrderTypeFilters,
  useFetchProductLines,
  useFetchVendors,
} from 'Store/actions/dataCache.actions';
import { toastActions } from 'Store/actions/toast.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import AdAlreadyLinked from 'Views/Login/AdAlreadyLinked';
import AdSigninInvitation from 'Views/Login/AdSigninInvitation';
import useAutoSignIn from 'Views/Login/AdSinginButton/helpers/useAutoSignIn';
import { useFetchAllAdAccounts } from 'Views/Login/AdSinginButton/helpers/useFetchAllAdAccounts';
import { useHandleCrossTabAuth } from 'Views/Login/AdSinginButton/helpers/useHandleCrossTabAuth';
import SelectDefault from 'Views/Login/SelectDefault';
import { useRoutes as usePrivateRoutes, NestedRouteConfig } from 'Views/routes';

import { AD_INVITE_TO, AD_LINKED_TO, LOGIN_TO } from '../Constants/shared.constants';
import lazyImport from '../Helpers/lazyImport';

import classes from './App.module.scss';

const NoAccess = lazyImport(() => import('Views/Login/NoAccess'));

const ErrorPage404 = lazyImport(() => import('Views/ErrorPages/ErrorPage404'));

const SignInIndex = lazyImport(() => import('Views/Login/SignInIndex'));

const App = (): ReactElement => {
  useAxiosInterceptors();

  const [proformaState, proformaDispatch] = useSampleProformaHeaderReducer();
  useFetchAllAdAccounts();
  useHandleCrossTabAuth();

  const memorizedValue = useMemo(
    () => ({ state: proformaState, dispatch: proformaDispatch }),
    [proformaState, proformaDispatch]
  );
  const [authLoaded, setAuthLoaded] = useState(false);
  useDDAuth();
  const fetchVendors = useFetchVendors();
  const fetchProductLines = useFetchProductLines();
  const fetchOrderTypeFilters = useFetchOrderTypeFilters();
  const userAuth = useAuthState().authState;
  const dispatch = useDispatch();

  const flags = useLDAuth();
  const user = useSelector((root: RootReducerState): User | null => root.userReducer.user);
  const privateRoutes = usePrivateRoutes();

  const { automaticSignInState, tryToAutomaticallySignIn } = useAutoSignIn();

  useEffect((): void => {
    const doSilentLogin = async () => {
      try {
        await tryToAutomaticallySignIn();
      } finally {
        setAuthLoaded(true);
      }
    };
    if (automaticSignInState === 'NOT_STARTED' && !authLoaded) {
      doSilentLogin();
    }
  }, [authLoaded, automaticSignInState, tryToAutomaticallySignIn]);

  useEffect(() => {
    if (userAuth.tokens?.accessToken !== undefined) {
      fetchVendors();
      fetchProductLines();
      fetchOrderTypeFilters();
    }
  }, [fetchOrderTypeFilters, fetchProductLines, fetchVendors, userAuth.tokens?.accessToken]);

  useEffect((): void => {
    if (userAuth?.errorMessage) {
      dispatch(toastActions.setErrorMessage(userAuth.errorMessage));
    }
  }, [dispatch, userAuth?.errorMessage]);

  const showSetDefaultAccountModal = useMemo((): boolean | undefined => {
    if (!user) {
      return false;
    }
    return !user.hasDefaultUser;
  }, [user]);

  const [openDefaultAccountModal, setOpenDefaultAccountModal] = useState(false);

  useEffect((): void => {
    if (showSetDefaultAccountModal) {
      setOpenDefaultAccountModal(true);
    }
  }, [showSetDefaultAccountModal]);

  const routes = useCallback((): ReactElement => {
    return (
      <Switch>
        {[...findInObject<NestedRouteConfig>(privateRoutes, 'to')].map(
          (protectedRoute: NestedRouteConfig, i) => {
            if (!protectedRoute.component) {
              return null;
            }
            return (
              <PrivateRoute
                key={`protectedRoute-${i}-${protectedRoute.label}`}
                exact={protectedRoute.exact}
                path={protectedRoute.to}
                Component={protectedRoute.component}
                permission={protectedRoute.permission}
                FallbackComponent={protectedRoute.fallbackComponent}
                featureFlag={protectedRoute.featureFlag}
              />
            );
          }
        )}

        <Route path={LOGIN_TO} component={SignInIndex} />
        <PrivateRoute path={AD_INVITE_TO} Component={AdSigninInvitation} />
        <PrivateRoute path={AD_LINKED_TO} Component={AdAlreadyLinked} />
        <Route path="/noaccess" component={NoAccess} />
        <Route path="*" exact={true} component={ErrorPage404} />
      </Switch>
    );
  }, [privateRoutes]);

  if (!authLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Loading message="Trying to log you in..." />
      </Box>
    );
  }
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div>
            <Navigation />
            <div className={classes.content}>
              <Splash fromSuspense />
            </div>
            <VpFooter />
          </div>
        }
      >
        {showSetDefaultAccountModal && (
          <SelectDefault
            open={openDefaultAccountModal}
            setOpen={(value: boolean) => setOpenDefaultAccountModal(value)}
          />
        )}
        {!flags ? (
          <Splash />
        ) : (
          <div
            className={classes.container}
            style={{
              ...(user !== null ? { flexDirection: 'row' } : { flexDirection: 'column' }),
            }}
          >
            {userAuth?.tokens?.accessToken !== undefined && <GreetingDialogs />}
            <Navigation />

            <main
              className={classes.main}
              style={{
                ...{
                  [theme.breakpoints.up('sm')]: {
                    maxWidth: 'calc(100vw - 72px)',
                  },
                },
                marginBottom: 0,
              }}
            >
              <div
                className={classes.toolbar}
                style={{
                  // NOTE: necessary for content to be below app bar
                  ...{
                    ...theme.mixins.toolbar,
                  },
                }}
              />
              <Campaign />
              <SampleProformaHeaderContext.Provider value={memorizedValue}>
                <div className={classes.content}>
                  <Papskilt />
                  {authLoaded && routes()}
                </div>
              </SampleProformaHeaderContext.Provider>
              <DisplayErrorHandler />
            </main>
            <VpFooter />
          </div>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
