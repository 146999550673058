import { ReactElement } from 'react';

import ReactLogo from 'Assets/images/svg/logoVendor.svg';
import classes from 'Components/UI/Navigation/Navigation.module.scss';

const Logo = (): ReactElement => {
  return <ReactLogo className={classes.logo} />;
};

export default Logo;
