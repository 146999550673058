import React, { useCallback, useMemo, useState } from 'react';

import { Box, TextField } from '@mui/material';

import { DialogButtonProps, Dialog } from '@bestseller-bit/frontend-community.components.dialog';

type Props = {
  open: boolean;
  onClose: () => void;
  fromDecline?: boolean;
  onUpdate: () => void;
  onChange: (value: string) => void;
  message?: string;
};

const ReasonModal = (props: Props) => {
  const { open, onClose, fromDecline, onUpdate, onChange, message } = props;

  const [reason, setReason] = useState<string | undefined>();

  const handleChangeReason = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setReason(event.target.value);
      onChange(event.target.value);
    },
    [onChange]
  );

  const hasError = useMemo(() => message !== undefined, [message]);

  const titleForDialog = useMemo(() => {
    return fromDecline ? 'Reason for decline' : 'Reason for confirm (optional)';
  }, [fromDecline]);

  const submitButton: DialogButtonProps = useMemo(() => {
    return {
      label: fromDecline ? 'Decline' : 'Confirm',
      onClick: onUpdate,
      disabled: fromDecline ? !reason || reason.trim() === '' : false,
      variant: 'contained',
    };
  }, [fromDecline, onUpdate, reason]);

  const buttonsForModal: DialogButtonProps[] = useMemo(() => {
    return [
      {
        label: 'Cancel',
        onClick: onClose,
        variant: 'outlined',
      },
      submitButton,
    ];
  }, [onClose, submitButton]);

  return (
    <Dialog
      title={titleForDialog}
      width="md"
      titleVariant="h4"
      dividers
      open={open}
      onClose={onClose}
      buttons={buttonsForModal}
    >
      <Box py={2}>
        <TextField
          value={reason}
          onChange={handleChangeReason}
          label="Supplier comment"
          variant="outlined"
          multiline
          minRows={3}
          sx={{ width: '100%' }}
          helperText={props.message ?? ' '}
          error={hasError}
        />
      </Box>
    </Dialog>
  );
};

export default ReasonModal;
