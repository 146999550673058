import { PERMISSION } from 'Constants/permissions.constants';
import { CLAIM_TO, CREDIT_NOTE_TO } from 'Constants/shared.constants';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import lazyImport from 'Helpers/lazyImport';

import Claim from '../../Claim/Claims/index';
import { getIconByIconName } from '../../Components/Shared/Icons/Icons';
import NoAccess from '../Login/NoAccess';

import { RouteConfig } from './index';

const CreditNote = lazyImport(() => import('Claim/CreditNote'));

export const useCLAIM_ROUTES = (): RouteConfig => {
  const getFeatureFlag = useGetFeatureFlag();
  return {
    label: 'Claims',
    to: CLAIM_TO,
    exact: true,
    icon: getIconByIconName('contentPaste'),
    color: 'darkgreen',
    featureFlag: getFeatureFlag('claims'),
    component: Claim, //Redirect going on in this!
    fallbackComponent: NoAccess,
    permission: PERMISSION.VP_CLAIM_VIEW,
    children: {
      Claim: {
        display: true,
        label: 'Claims',
        to: CLAIM_TO,
        exact: true,
        component: Claim,
        featureFlag: getFeatureFlag('claims'),
        fallbackComponent: NoAccess,
      },
      CreditNote: {
        display: true,
        label: 'Credit Note',
        to: CREDIT_NOTE_TO,
        exact: true,
        component: CreditNote,
        featureFlag: getFeatureFlag('credit-note'),
        fallbackComponent: NoAccess,
      },
    },
  };
};
