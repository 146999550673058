import React, { ReactElement } from 'react';

import { Tooltip } from '@mui/material';

import { ActionWithReasons } from '_types_/ActionWithReason';
import WithPermission from 'Components/Shared/Security/WithPermission/WithPermission';
import { PERMISSION } from 'Constants/permissions.constants';
import NotAllowedActionReason from 'PackingNotes/Areas/PackingNotesDetails/Components/Actions/NotAllowedActionReason';

interface Props {
  isDoable: ActionWithReasons;
  action: string;
  permission: number;
  children: React.ReactNode;
}
const ActionButtonWithReason = (props: Props): ReactElement => {
  const { isDoable: isSomethingble, action, permission, children } = props;
  const isEnabled = isSomethingble.value === true;

  const wrapper = (
    <Tooltip
      title={
        !isEnabled ? (
          <div>
            <NotAllowedActionReason reasons={isSomethingble.reasons} action={action} />
          </div>
        ) : (
          ''
        )
      }
    >
      <span>{children}</span>
    </Tooltip>
  );

  return permission === PERMISSION.NONE ? (
    wrapper
  ) : (
    <WithPermission permission={permission}>{wrapper}</WithPermission>
  );
};

export default ActionButtonWithReason;
