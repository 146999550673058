import { ClaimDetail, Claim } from 'Claim/_types/claims';

export const CLAIM_CLEAR_ALL_GROUPS = 'CLAIM_CLEAR_ALL_GROUPS';
export const CLAIM_ADD_GROUP = 'CLAIM_ADD_GROUP';
export const CLAIM_ADD_DETAILS_TO_GROUP = 'CLAIM_ADD_DETAILS_TO_GROUP';
export const CLAIM_UPDATE_STATUS = 'CLAIM_UPDATE_STATUS';

export type ClaimClearAllGroups = {
  type: typeof CLAIM_CLEAR_ALL_GROUPS;
};

export type ClaimAddGroup = {
  type: typeof CLAIM_ADD_GROUP;
  group: Claim;
};

export type ClaimAddDetailsToGroup = {
  type: typeof CLAIM_ADD_DETAILS_TO_GROUP;
  id: number;
  details: ClaimDetail;
};

export type ClaimUpdateStatus = {
  type: typeof CLAIM_UPDATE_STATUS;
  id: number;
  supplierComment: string;
  action: 'DECLINE' | 'CONFIRM' | 'UNCONFIRM';
};

export type ClaimGroupsActions =
  | ClaimClearAllGroups
  | ClaimAddGroup
  | ClaimAddDetailsToGroup
  | ClaimUpdateStatus;

export type ClaimGroupsDispatch = <T extends ClaimGroupsActions>(action: T) => void;
