import { PutStateClaimConfig, claimBaseUrl } from 'Claim/_services/claimService';
import { UseLazyBestApiTuple, useLazyBestApi } from 'Components/Hooks/useLazyBestApi';

const CONFIRM_CLAIM = (claimId: number) => claimBaseUrl(`claims/${claimId}/confirm`);

export const useConfirmClaim = (
  claimId: number,
  reason: string | undefined
): UseLazyBestApiTuple<number, PutStateClaimConfig> => {
  return useLazyBestApi<number, PutStateClaimConfig>(CONFIRM_CLAIM(claimId), {
    headers: {
      'CLAIMS-API-VERSION': '1.0',
    },
    data: {
      value: reason,
    },
    method: 'PUT',
  });
};
