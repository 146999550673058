import React, { ReactElement } from 'react';

import { Paper } from '@mui/material';
import MuiTablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import MuiTableRow from '@mui/material/TableRow';

import { doChangePage } from '../state/actions/changePage';
import { doChangePageSize } from '../state/actions/changePageSize';
import { useTableState } from '../state/useTableState';

const TableFooter = (): ReactElement => {
  const { state, dispatch } = useTableState();

  if (!state.pagination) {
    return <></>;
  }

  const paginationProps: TablePaginationProps = {
    // count: -1 enables server side pagination
    // https://mui.com/api/table-pagination/
    count: state.pagination.totalItems ?? -1,
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(doChangePageSize(+event.target.value ?? 0));
    },
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      dispatch(doChangePage(page));
    },
    page: state.pagination?.currentPage,
    rowsPerPage: state.pagination?.pageSize,
  };

  return (
    <MuiTableRow component="div">
      <MuiTablePagination
        labelDisplayedRows={state.pagination?.labelDisplayedRows ?? undefined}
        component={state.tableOptions.paper ? Paper : 'div'}
        {...paginationProps}
        backIconButtonProps={{
          disabled: state.pagination?.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled: state.pagination.last,
        }}
        rowsPerPageOptions={state.tableOptions.rowsPerPageOptions}
      />
    </MuiTableRow>
  );
};

export default TableFooter;
