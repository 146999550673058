import React, { ReactElement } from 'react';

import { Chip } from '@mui/material';

import { ClaimState } from 'Claim/_types/claims';

interface ClaimStateCellProps {
  status: ClaimState;
}

const ClaimStateCell = ({ status }: ClaimStateCellProps): ReactElement => {
  return <ClaimStateValue claimStateName={status} />;
};

export default ClaimStateCell;

interface ClaimStateValueProps {
  claimStateName: ClaimState;
}

const claimStateStyles: {
  [key in ClaimState]: { label: string; sx?: object; color?: 'default' | 'success' | 'error' };
} = {
  NOT_CONFIRMED: { label: 'Not Confirmed', sx: { borderColor: 'grey.100' }, color: 'default' },
  CONFIRMED: {
    label: 'Confirmed',
    sx: { backgroundColor: 'success.dark', color: '#fff' },
    color: 'success',
  },
  DECLINED: {
    label: 'Declined',
    sx: { backgroundColor: 'error.dark', color: '#fff' },
    color: 'error',
  },
};

const ClaimStateValue = ({ claimStateName: claimState }: ClaimStateValueProps): ReactElement => {
  const { label, sx, color } = claimStateStyles[claimState] || {
    label: claimState,
    color: 'default',
  };
  return <Chip sx={sx} color={color} label={label} />;
};

export { ClaimStateValue };
